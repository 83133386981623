<div class="mat-elevation-z8 card-table-container">

  <mat-toolbar fxLayoutAlign="space-between center">
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
      <ng-container *ngIf="dataSource">
        <mat-spinner diameter="20" *ngIf="dataSource.loading$ | async"></mat-spinner>
      </ng-container>
        <span>Vehículos</span>
      <span class="mat-small">{{dataSource?.count}} Total</span>
    </div>
    <div fxLayout="row" fxLayoutAlign="none center" fxLayoutGap="10px" class="mat-small">

      <ng-container *ngIf="!isLoading; else Loading">

        <!-- No hay url -->
        <button *ngIf="url == null" mat-stroked-button color="primary" (click)="report()">
          Descargar Reporte          
        </button>
        <!-- si hay url -->
        <button *ngIf="url != null" mat-icon-button (click)="report()">
          <mat-icon>get_app</mat-icon>
        </button>
      </ng-container>
      <ng-template #Loading>
        <mat-spinner diameter="20"></mat-spinner>
      </ng-template>



    </div>
  </mat-toolbar>
  <table mat-table class="full-width-table" matSort 
    matSortStart="desc" 
    matSortDisableClear="true"
    matSortActive="createdAt._seconds"
    matSortDirection="desc"
    aria-label="Elements">





    <!-- Id Column -->
    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef>Tipo</th>
      <td mat-cell *matCellDef="let vehicle" translate>
        <!-- {{row.type}} -->
        <!-- {{'type' | otherChkr: vehicle}} -->
        {{
          vehicle?.idType == '-1'  
          ? 'Otro: (' + ("VEHICLE_TYPE."+vehicle.typeOther | translate) + ')' 
          : ('type' | otherChkr: vehicle) 
        }}

      </td>
    </ng-container>


    <!-- Brand Column -->
    <ng-container matColumnDef="brand">
      <th mat-header-cell *matHeaderCellDef>Marca</th>
      <td mat-cell *matCellDef="let row">
        <!-- {{row.brand}} -->
        {{'brand' | otherChkr: row}}
      </td>
    </ng-container>

    <!-- Brand Column -->
    <ng-container matColumnDef="model">
      <th mat-header-cell *matHeaderCellDef>Modelo</th>
      <td mat-cell *matCellDef="let row">
        <!-- {{row.model}} -->
        {{'model' | otherChkr: row}}
      </td>
    </ng-container>

    <!-- Year Column -->
    <ng-container matColumnDef="year">
      <th mat-header-cell *matHeaderCellDef>Año</th>
      <td mat-cell *matCellDef="let row">
        <!-- {{row.year}} -->
        {{'year' | otherChkr: row}}
      </td>
    </ng-container>

    <!-- color Column -->
    <ng-container matColumnDef="color">
      <!-- mat-sort-header="color" -->
      <th  mat-header-cell *matHeaderCellDef>Color</th>
      <td mat-cell *matCellDef="let row">
        <!-- {{ "COLOR." + row.color | translate}} -->
        {{'color' | otherChkr: row}}
      </td>
    </ng-container>

    <!-- Plate Column -->
    <ng-container matColumnDef="numberPlate">
      <th mat-header-cell *matHeaderCellDef>Matrícula</th>
      <td mat-cell *matCellDef="let row">{{row.numberPlate}}</td>
    </ng-container>

    <!-- Resident Column -->
    <ng-container matColumnDef="resident">
      <th mat-header-cell *matHeaderCellDef>Residente</th>
      <td mat-cell *matCellDef="let row">
        <ng-container
          *ngTemplateOutlet="residentOptions; context: { property: 'resident', row: row }">
        </ng-container>  
      </td>
    </ng-container>
    <!-- A Resident Column -->
    <ng-container matColumnDef="aRes">
      <th mat-header-cell *matHeaderCellDef>A</th>
      <td mat-cell *matCellDef="let row">  
        <ng-container
          *ngTemplateOutlet="residentOptions; context: { property: 'aRes', row: row }">
        </ng-container>        
      </td>
    </ng-container>
    <!-- B Resident Column -->
    <ng-container matColumnDef="bRes">
      <th mat-header-cell *matHeaderCellDef>B</th>
      <td mat-cell *matCellDef="let row"> 
        <ng-container
          *ngTemplateOutlet="residentOptions; context: { property: 'bRes', row: row }">
        </ng-container>  
      </td>
    </ng-container>

    <!-- C Resident Column -->
    <ng-container matColumnDef="cRes">
      <th mat-header-cell *matHeaderCellDef>C</th>
      <td mat-cell *matCellDef="let row">
        <ng-container
          *ngTemplateOutlet="residentOptions; context: { property: 'cRes', row: row }">
        </ng-container>        
      </td>
    </ng-container>

    <!-- location Column -->
    <ng-container matColumnDef="location">
      <th mat-header-cell *matHeaderCellDef>Ubicación</th>
      <td mat-cell *matCellDef="let row">

        {{row.location
          ? (
            (getAddressComponent(row.location.address_components, 'administrative_area_level_1'))
            + " " + (getAddressComponent(row.location.address_components, 'locality'))
            + " " +(getAddressComponent(row.location.address_components, 'postal_code'))
            )
          : '-'
        }}

      </td>
    </ng-container>

    <!-- CreatedAt Column -->
    <ng-container matColumnDef="createdAt">
      <!-- mat-sort-header="createdAt._seconds" -->
      <th mat-sort-header="createdAt._seconds"  mat-header-cell *matHeaderCellDef>Fecha</th>
      <td mat-cell *matCellDef="let row">
        <div>
          {{row.createdAt.toDate() | date:'d/M/yy, H:mm' }} h
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

  </table>

  <mat-paginator #paginator [length]="dataSource?.count" [pageIndex]="0" [pageSize]="15"
    [pageSizeOptions]="[15, 25, 50, 100, 250]">
  </mat-paginator>
</div>


<ng-template #residentOptions let-property="property" let-row="row">

  <div *ngIf="property=='resident' || row['resident']"  (click)="onChangeResidentOptions(row, property)" style="cursor: pointer;">
    <mat-icon *ngIf="row[property]" >
      {{property == 'resident' ? 'radio_button_checked': 'check_box_outline'}}
    </mat-icon> 
    <mat-icon *ngIf="!row[property]">
      {{property == 'resident' ? 'radio_button_unchecked': 'check_box_outline_blank'}}    
    </mat-icon>
  </div>
  

</ng-template>
