import { Injectable, NgZone } from '@angular/core';
import { Observable, of } from 'rxjs';
import { take, map } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase/app';
import {FirebaseError} from 'firebase/app';
import { EmailValidator } from '@angular/forms';
import { UserModel } from '../../models/user.model';
import { UrlResolver } from '@angular/compiler';
//import { Plugins } from '@capacitor/core';
import { NgxPermissionsService } from 'ngx-permissions';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public authState$: Observable<firebase.User>;
  public authState: firebase.User;
  public platform: any;
  public role = null;
  // private authState$: Observable<firebase.User>;
  constructor(
    public afAuth: AngularFireAuth,
    private ngZone: NgZone,
    private permissionsService: NgxPermissionsService

    // private userService : UserService
  ) {
      // firebase.auth().languageCode = 'fr';
      this.afAuth.auth.useDeviceLanguage();
      this.authState$ = afAuth.authState;
      this.authState$.subscribe(async (user: firebase.User) => {
      this.authState = user;

      if (user){
        this.authState.getIdTokenResult()
        .then(datos=>{
          if(datos && datos.claims.role){
            this.role= datos.claims.role;
            const perm = [this.role];
            //const perm = ['p3'];
            this.permissionsService.loadPermissions(perm);
          }else{
            this.role=null;
            const perm = [this.role];
            this.permissionsService.loadPermissions(perm);
          }

        })
      }


    });
    this.initAuth();
  }

  async initAuth(){

    this.platform = "web";
    //(await this.afAuth.auth.currentUser.getIdTokenResult()).claims
  }
  // Returns true if user is logged in
  get authenticated() {
    return !!this.authState;
  }

  // Returns current user //deberia ser current auth
  get currentUser$(): any {
    console.log(this.authState$);
    return  this.authState$;
  }

  // Returns current user UID
  // get currentUserId(): string {
  //   //eturn this.authenticated ? this.authState$.uid : '';
  // }

  doLogout() {
    this.authState = null;
    this.role = null;
    if (this.platform == "web"){
      return this.afAuth.auth.signOut();
    }else{
      return //cfaSignOut().toPromise();
    }

  }

  doEmailLogin(value) {
    return new Promise<any>((resolve, reject) => {
      this.afAuth.auth.signInWithEmailAndPassword(value.email, value.password)
      .then(res => res.user.getIdTokenResult())
      .then((data:any) =>{
        //resolve(data);
        if(data.claims && data.claims.role){
          this.role= data.claims.role;
          resolve(data)
        }else{
          this.afAuth.auth.signOut();
          reject({code:"auth/unauthorized"})
        }
      })
      .catch(res => {
        reject(res);
      });
    });
  }

  resetPasswordInit(email: string) {
    return this.afAuth.auth.sendPasswordResetEmail(
      email);
      // ,
      // { url: 'http://localhost:4200/auth' });
  }

  doRegister(credential) {
    return this.afAuth.auth.createUserWithEmailAndPassword(credential.email, credential.password)
      .then(uC => {
        const user: UserModel = new UserModel();
        // user.displayName = uC.user.displayName;
        user.email = uC.user.email;
        user.emailVerified = uC.user.emailVerified;
        user.phoneNumber = uC.user.phoneNumber;
        user.phoneNumberVerified = false;
        user.regStatus = UserModel.RegStatus.Location;
        // user.photoURL = uC.user.photoURL;
        user.uid = uC.user.uid;
        user.provider = {
          ['email'] : uC.user.email
        };
        return user;
      });
  }

  doLogin(service) {
    if (this.platform == "web"){
      return this.goPopupLogin(service);
    }else{
      return; //this.goCfaSignin(service);
    }
  }

  goCfaSignin(service){
    // console.log("@@@--- login con el servicio ---@@@");
    // return cfaSignIn(service).toPromise()
    // .then(res=>{
    //   let uC = res.userCredential;
    //   let uCM = this.mapProviderToUser(uC);
    //   return uCM;
    // })
    // .catch(err=>{
    //   console.log("@@@--- Error ---@@@");
    //   console.log(err);
    //   console.log("@@@---  FIN Error ---@@@");

    // })
  }
  mapProviderToUser(uC){
    let isNew = uC.isNew;
    let service = uC.additionalUserInfo.providerId;

    const user: UserModel = new UserModel();
    user.displayName = uC.user.displayName;
    user.email = uC.user.email;
    user.emailVerified = uC.user.emailVerified;
    user.phoneNumber = uC.user.phoneNumber;
    user.phoneNumberVerified = false;
    user.photoURL = uC.user.photoURL;
    user.uid = uC.user.uid;
    user.regStatus = UserModel.RegStatus.Name;
    user.provider = {
      [service] : uC.additionalUserInfo.profile['id'] || uC.additionalUserInfo.profile['sub']
    };
    switch (service) {
      case 'google.com':
          user.firstName = uC.additionalUserInfo.profile['given_name'];
          user.lastName  = uC.additionalUserInfo.profile['family_name'];
        break;
      case 'facebook.com':
          user.firstName = uC.additionalUserInfo.profile['first_name'];
          user.lastName  = uC.additionalUserInfo.profile['last_name'];
        break;
    }
    return user;
  }

  goPopupLogin(service) {
    let provider = null;
    switch (service) {
      case 'google.com':
        provider = new firebase.auth.GoogleAuthProvider();
        break;
      case 'facebook.com':
        provider = new firebase.auth.FacebookAuthProvider();
        break;
      default:
        return;
        break;
    }
    return this.afAuth.auth
      .signInWithPopup(provider)
      .then(uC => {
        const user: UserModel = new UserModel();
        user.displayName = uC.user.displayName;
        user.email = uC.user.email;
        user.emailVerified = uC.user.emailVerified;
        user.phoneNumber = uC.user.phoneNumber;
        user.phoneNumberVerified = false;
        user.photoURL = uC.user.photoURL;
        user.uid = uC.user.uid;
        user.regStatus = UserModel.RegStatus.Name;
        user.provider = {
          [uC.additionalUserInfo.providerId] : uC.additionalUserInfo.profile['id']
        };
        switch (service) {
          case 'google.com':
              user.firstName = uC.additionalUserInfo.profile['given_name'];
              user.lastName  = uC.additionalUserInfo.profile['family_name'];
            break;
          case 'facebook.com':
              user.firstName = uC.additionalUserInfo.profile['first_name'];
              user.lastName  = uC.additionalUserInfo.profile['last_name'];
            break;
        }
        return user;
      })
      .catch();

  }
}
