<h3 mat-dialog-title>Confirmar</h3>
<div mat-dialog-content>
  {{message}}
 </div>
 <div mat-dialog-actions align="end">
   <button mat-stroked-button (click)="onNoClick()">No</button>
   <button mat-stroked-button mat-dialog-close="true" cdkFocusInitial color="warn">Sí</button>
 </div>


