<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer class="sidenav" attr.role='navigation' mode='side' opened disableClose>
    <mat-toolbar>Filtros</mat-toolbar>
    <div fxLayout="column" fxLayoutAlign="start center" class="search-form">
      <!-- Filtro de Incidence -->
      <app-filters [filterForm]="filterForm" [a2filter]="a2filter" [incidence]="'vehicles_idType'"></app-filters>
    </div>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-card>
      <!-- Tabla de Incidence -->
      <app-incidences-table [a2filter]="a2filter"></app-incidences-table>
    </mat-card>
  </mat-sidenav-content>
</mat-sidenav-container>
