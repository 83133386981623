import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';

import { Router } from '@angular/router';
import { AuthService } from '../core/auth/auth.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  forgotForm: FormGroup;
  validationMessages = {
    'email': [
      { type: 'required', message: 'El correo electrónico es requerido' },
      { type: 'pattern', message: 'Introduzca un correo electrónico válido' },
      { type: 'incorrect', message: 'Usuario inválido' }
    ]
  }
  constructor(
    public router: Router,
    private authService: AuthService,
  ) {
    this.forgotForm = new FormGroup({
      'email': new FormControl('', Validators.compose([
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')
      ]))
    });
  }

  ngOnInit(): void {
  }
  submit(){
    if(this.forgotForm.valid){
      const email = this.forgotForm.controls.email.value;
      this.authService.resetPasswordInit(email)
      .then(res=>{
        // console.log("res", res);

        alert("¡Enlace enviado! \n\nHemos enviado un correo con un enlace para reiniciar la contraseña. Puede tardar unos minutos en llegar a su bandeja de entrada.")
        this.router.navigate(['/login']);
      })
      .catch(console.error);
    }
  }
  getErrorMessage(group){
    //Se devuelve el primer Error que se consigue
    const control = this.getControlName(group);
    let errorMessage = "Error";
    if (this.validationMessages[control]){
      this.validationMessages[control].some(function(errorType) {
        errorMessage = errorType.message;
        return group.hasError(errorType.type)
      });
    }

    return errorMessage;
  }
    /**
   * get Control Name
   * @param c Form Control
   */
  getControlName(c: AbstractControl): string | null {
    const formGroup = c.parent.controls;
    return Object.keys(formGroup).find(name => c === formGroup[name]) || null;
  }
}
