import { Component, ViewChild, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import * as moment from 'moment';
import { Moment } from 'moment';
import { DaterangepickerDirective } from 'ngx-daterangepicker-material';
import { DashboardService } from './dashboard.service';
import { UiService } from '../../shared/ui.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from "src/environments/environment";
export interface Totals{
  iconName: string;
  name: string;
  value: number;
}

export interface rangeSelected{
  startDate: Moment;
  endDate: Moment;
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  @ViewChild(DaterangepickerDirective) picker: DaterangepickerDirective;

  chartOptions = {
    // height:'400',
    chartArea: { 
      left: '0',
      top: '10',
      width: "100%",
      height: "70%" 
    },
    legend:{
      width: '100%',
      height: '100%',
      maxLines: '2',
      alignment: 'center',

    }
  }

  loadingData = false;

  //USER_____
  totalUsers = 0;
  usersByDate = [];

  //VEHICLES____
  totalVehicles = 0;
  vehiclesByDate = []
  vehiclesType = []
  vehiclesBrand = []
  
  //INCIDENCES____
  totalIncidences = 0;
  incidencesByDate = [];
  incidenceType = []


  rangeHistogram;


  totalNotices
  totalLogs
  //_______
  totals : Totals[] = [ ];

  //DATE____
  defaultRange: rangeSelected = {
    startDate: moment().subtract(29, 'days').startOf('day'),
    endDate: moment(),
    // startDate: moment().subtract(3, 'month').startOf('month') ,
    // endDate: moment().subtract(1, 'month').endOf('month')
  };

  datePickerOptions = {
    applyLabel: 'Aceptar',
    cancelLabel: 'Cancelar',
    clearLabel: 'Borrar',
    customRangeLabel: 'Personalizado',
    format: 'DD/MM/YYYY',
    direction: 'ltr',
    monthNames: moment.localeData('es').monthsShort(), //moment.monthsShort(),
    daysOfWeek: moment.localeData('es').weekdaysMin(),
  }
  ranges = {
    'Hoy': [moment(), moment()],
    'Ayer': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Últimos 7 días': [moment().subtract(6, 'days'), moment()],
    'Últimos 30 días': [moment().subtract(29, 'days'), moment()],
    'Mes actual': [moment().startOf('month'), moment().endOf('month')],
    'Mes anterior': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    'Últimos 3 meses': [moment().subtract(3, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    // 'Desde el inicio': [null, null],
    // 'Desde el inicio': [null, null],
    'Desde el inicio': [moment(environment.initDay), moment()],
  };

  formDateDashboard = this.formBuilder.group({
    dateRanges: [this.defaultRange],
  });
  
  constructor(
    private formBuilder: FormBuilder,
    private dashboardService: DashboardService ,
    public translate: TranslateService,
    public ui: UiService 
  ) {}

  ngOnInit(){
    this.datesUpdated(this.defaultRange);
    console.log(moment().startOf('year')  )
  }

  openDateRange() {
    this.picker.open();
  }
  rangeClicked(range): void {
    console.log('[rangeClicked] range is : ', range);
    this.formDateDashboard.patchValue({
      dateRanges: {
        startDate: range.dates[0],
        endDate: range.dates[1],
      }
    });
    this.picker.hide();
    this.loadData(range.dates[0].toDate(), range.dates[1].toDate());
  }

  datesUpdated(range: rangeSelected  ): void {
    console.log("____");
    
    if (!range.startDate && !range.endDate) {
      // range = this.defaultRange
      console.log("rangos null / invalid", range, "To default");
      range = this.defaultRange;
    }
    console.log('[datesUpdated] range is : ', range);
    let start = range.startDate.toDate()
    let end = range.endDate.toDate()
    this.loadData(start , end);
  }
  
  private loadData(from: Date, to: Date) {
    
    this.loadingData = true;
    this.dashboardService.getData(from, to)
    .then(result=>{

      

      console.log(result);
      this.loadingData = false;
      //USER
      this.totalUsers = result.totalUsers;
      this.usersByDate = result.usersByDate;

      //VEHICLES
      this.totalVehicles = result.vehiclesTotal
      this.vehiclesByDate = result.vehiclesByDate

      this.vehiclesBrand = result.vehiclesBrand.map( brand => {
        let newBrand = [
          brand[0] == "other" ? "Otro" : brand[0]
          ,brand[1]
        ]
        return newBrand
      })

      this.vehiclesType = result.vehiclesType.map( type => {
        let k = this.translate.instant(type[0]);
        let newType = [
          k,
          type[1]
        ]
        return newType
      })
      //INCIDENCE
      this.totalIncidences = result.incidencesTotal;
      this.incidencesByDate = result.incidencesByDate;
      this.incidenceType = result.incidenceType.map( type => {
        let k = this.translate.instant(type[0]);
        let newType = [
          k,
          type[1]
        ]
        return newType
      })

      this.rangeHistogram = result.rangeHistogram;
      console.log('@@@@@@@@@', this.rangeHistogram);

      this.totalLogs = result.totalLogs
      this.totalNotices = result.totalNotices
      this.setTotals();
      
    });
  }

  private setTotals():void{


    this.totals = [
      {
        name: 'Usuarios registrados',
        iconName: 'people',
        value: this.totalUsers
      },
      {
        name: 'Vehículos registrados',
        iconName: 'drive_eta',
        value: this.totalVehicles
      },
      {
        name: 'Incidencias reportadas',
        iconName: 'report_problem',
        value: this.totalIncidences
      },
      {
        name: 'Avisos enviados',
        iconName: 'campaign',
        value: this.totalNotices
      },
      {
        name: 'Matrículas buscadas',
        iconName: 'search',
        value: this.totalLogs
      }
    ]
    //un for o push? asi ahy mayor manejo
    // this.totals.fill( {
    //   name: '',
    //   iconName: '',
    //   value: 0
    // } )

  }



  get agrupation(): string {
    let intervalTime = this.rangeHistogram ? this.rangeHistogram.calendar_interval: '';
    switch(intervalTime) {
      case '1h':
        intervalTime = 'Por Hora';
        break;
      case '1d':
        intervalTime = 'Diario';
        break;
      case '1w':
        intervalTime = 'Semanal';
        break;
      case '1M':
        intervalTime = 'Mensual';
        break;
      case '1y':
        intervalTime = 'Anual';
        break;
      default:
        break;
    }
    return intervalTime;
  }

}
  