<div class="mat-elevation-z8 card-table-container">

  <mat-toolbar fxLayoutAlign="space-between center">
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
      <ng-container *ngIf="dataSource">
        <mat-spinner diameter="20" *ngIf="dataSource.loading$ | async"></mat-spinner>
      </ng-container>

      <span>Búsquedas de Matrículas</span>
      <span class="mat-small">{{dataSource?.count}} Total</span>
    </div>
    <div fxLayout="row" fxLayoutAlign="none center" fxLayoutGap="10px" class="mat-small">
      <ng-container *ngIf="!isLoading; else Loading">
        <!-- No hay url -->
        <button *ngIf="url == null" mat-stroked-button color="primary" (click)="report()">
          Descargar Reporte          
        </button>
        <!-- si hay url -->
        <button *ngIf="url != null" mat-icon-button (click)="report()">
          <mat-icon>get_app</mat-icon>
        </button>
      </ng-container>
      <ng-template #Loading>
        <mat-spinner diameter="20"></mat-spinner>
      </ng-template>
    </div>
  </mat-toolbar>
  <!--_______________________________________________________________________________________-->
  <table mat-table class="full-width-table" matSort
    matSortStart="desc" 
    matSortDisableClear="true"
    matSortActive="createdAt._seconds"
    matSortDirection="desc"
  aria-label="Elements" >

   
    <!-- Correo -->
    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef>Correo</th>
      <td mat-cell *matCellDef="let row">{{row.email}}</td>
    </ng-container>

    <!-- DisplayNames -->
    <ng-container matColumnDef="displayName">
      <th mat-header-cell *matHeaderCellDef>Nombre a Mostrar</th>
      <td mat-cell *matCellDef="let row">{{row.displayName}}</td>
    </ng-container>

    <!-- Names -->
    <ng-container matColumnDef="names">
      <th mat-header-cell *matHeaderCellDef>Nombre de usuario</th>
      <td mat-cell *matCellDef="let row">
        {{row.firstName }} {{ row.lastName}}
      </td>
    </ng-container>

    <!-- Matrícula -->
    <ng-container matColumnDef="numberPlate">
      <th mat-header-cell *matHeaderCellDef>Matrícula</th>
      <td mat-cell *matCellDef="let row">{{row.numberPlate}}</td>
    </ng-container>

    <!-- Found -->
    <ng-container matColumnDef="found">
      <th mat-header-cell *matHeaderCellDef>Encontró</th>
      <td mat-cell *matCellDef="let row">{{row.found === true ? 'Sí' : 'No'}}</td>
    </ng-container>

    <!-- CreatedAt -->
    <ng-container matColumnDef="createdAt">
      <th mat-sort-header="createdAt._seconds" mat-header-cell *matHeaderCellDef>Fecha</th>
      <td mat-cell *matCellDef="let row">
        <div>
          {{row.createdAt.toDate() | date:'d/M/yy, H:mm' }} h
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

  </table>

  <mat-paginator #paginator [length]="dataSource?.count" [pageIndex]="0" [pageSize]="15"
    [pageSizeOptions]="[15, 25, 50, 100, 250]">
  </mat-paginator>
</div>
