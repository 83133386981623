import { Component, OnInit, Input, Output, EventEmitter  } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-chip-filter',
  templateUrl: './chip-filter.component.html',
  styleUrls: ['./chip-filter.component.scss']
})
export class ChipFilterComponent implements OnInit {
  @Input() filterName;
  @Input() filters;
  @Output() chipRemoved: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    public translate: TranslateService,

  ) { }

  ngOnInit(): void {
  }
  // add(value) {
  //   if (value.length > 0) {
  //     this.filters.push(value);
  //     this.filterChange.emit({filterName: this.filterName, filters: this.filters});
  //   }
  // }
  remove(item) {
    this.chipRemoved.emit({key: this.filterName, item});
  }

  isObject(val){return typeof val === 'object'};

}
