<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer class="sidenav" 
      attr.role='navigation'
      mode='side'
      >
    <mat-toolbar>Panel</mat-toolbar>
    <mat-nav-list>

    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <app-dashboard *ngIf="userService.userG && !['p3', 'p4'].includes(userService.userG.role) "></app-dashboard>
    <app-plates *ngIf="userService.userG && ['p3', 'p4'].includes(userService.userG.role)"></app-plates>
  </mat-sidenav-content>
</mat-sidenav-container>
