<div class="mat-elevation-z8 card-table-container">
  <table mat-table class="full-width-table" matSort aria-label="Elements">

    <!-- Column -->
    <ng-container matColumnDef="id">

      <th mat-header-cell *matHeaderCellDef>
        <mat-toolbar fxLayoutAlign="space-between center">
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
            <ng-container *ngIf="dataSource">
              <mat-spinner diameter="20" *ngIf="dataSource.loading$ | async"></mat-spinner>
            </ng-container>

            <span>Avisos</span>
            <span class="mat-small">{{dataSource?.count}} Total</span>
          </div>

          <div>
            <button mat-stroked-button color="primary" (click)="openDialog()">
              Crear Aviso
            </button>
          </div>
        </mat-toolbar>
      </th>

      <!-- ROW -->
      <td mat-cell *matCellDef="let row">
        <mat-card class="mat-elevation-z4" style="margin: 20px;">
          <mat-card-title>{{row.title}}</mat-card-title>
          <mat-card-subtitle fxLayout="row" fxLayoutAlign="space-between center">
            <div>
              {{row.createdAt.toDate() | date:'d/M/yy, H:mm' }} h
            </div>
            <div fxLayoutAlign=" center">
              <mat-icon>
                person
              </mat-icon>
              &nbsp;
              <div>
                {{row.sender}}
              </div>
            </div>
          </mat-card-subtitle>
          <mat-card-content>
            <div fxLayout="column" fxFlex>

              <div class="ql-container ql-snow" style="border-width: 0;" >
                <div class="ql-editor" [innerHTML]="row.message | safevalue"></div>
              </div>
              
              <!-- <div [innerHTML]=row.message></div>
              <hr>
              <div>{{row.message | safevalue}}</div> -->
              <!-- <div>{{row.message}}</div>  -->

              &nbsp;
              <div fxLayout="row" fxLayoutAlign="start center">
                Filtros:&nbsp;
                <mat-chip-list *ngIf="row.filterDisplay">
                  <mat-chip *ngFor="let chipName of a2FilterToStringArr(row.filterDisplay)" color="primary" selected>
                    {{chipName}}
                  </mat-chip>
                </mat-chip-list>
              </div>
            </div>
          </mat-card-content>

          <mat-card-footer style="padding: 20px;" fxLayout="row" fxLayoutAlign="space-between center">
            <div><b>Usuarios:</b> {{row.userCount}}</div>
            <div><b>Válidos:</b> {{row.validUserCount}}</div>
            <div><b>Enviados:</b> {{row.sentUserCount}}</div>
            <div><b>Leídos:</b> {{row.readUserCount}}</div>
          </mat-card-footer>
        </mat-card>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns" style="background-color: #f5f5f5;"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

  </table>

  <mat-paginator #paginator [length]="dataSource?.count" [pageIndex]="0" [pageSize]="15"
    [pageSizeOptions]="[15, 25, 50, 100, 250]">
  </mat-paginator>
</div>
