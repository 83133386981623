import { Component, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable} from 'rxjs';
import { map, shareReplay} from 'rxjs/operators';
import { A2filter } from '@a2system/angular/common'; // } from '../shared/a2filter';
import { FormGroup, FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-incidences',
  templateUrl: './incidences.component.html',
  styleUrls: ['./incidences.component.scss']
})
export class IncidencesComponent implements OnInit{

  public data;
  public results;
  public isFetching;
  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
  .pipe(
    map(result => result.matches),
    shareReplay()
  );
  
  filterForm = new FormGroup ({
    location: new FormControl(''),
    createdAt: new FormControl(''),
    idType: new FormControl(''),
    vehicles_idType: new FormControl(''),// colocar un incidence
    vehicles_numberPlate: new FormControl(''),
    vehicles_idModel: new FormControl(''),
    vehicles_idBrand: new FormControl(''),
    vehicles_year: new FormControl(''),
    vehicles_color: new FormControl(''),
  })


  a2filter =  new A2filter(this.filterForm);

  constructor(
    private breakpointObserver: BreakpointObserver,
    public translate: TranslateService,
  ){}
  ngOnInit() {}
}
