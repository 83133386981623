import { Component, OnInit } from '@angular/core';
import { A2filter } from '@a2system/angular/common'; // } from '../shared/a2filter';
import { FormGroup, FormControl } from '@angular/forms';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-notices',
  templateUrl: './notices.component.html',
  styleUrls: ['./notices.component.scss']
})
export class NoticesComponent implements OnInit {

  // idSender
  // createdAt._seconds
  filterForm = new FormGroup ({ // filtros a usar

    // location: new FormControl(''),
    // vehicles_idType: new FormControl(''),
    // vehicles_numberPlate: new FormControl(''),
    // vehicles_idBrand: new FormControl(''),
    // model: new FormControl(''),
    // vehicles_year: new FormControl(''),
    // vehicles_color: new FormControl(''),
    
    idSender: new FormControl(''),
    createdAt: new FormControl(''),//createdAt._seconds
    
  });
  a2filter = new A2filter(this.filterForm) ;

  constructor() { }

  ngOnInit(): void {}
}