import { Injectable } from '@angular/core';
import { UserService } from '../core/user.service';
import { VehiclesService } from '../vehicles/vehicles.service';
import { VehicleModel } from '../models/vehicle.model';
import { AngularFirestore } from '@angular/fire/firestore';
import { map, concatMap, first, filter, take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AngularFireFunctions } from '@angular/fire/functions';
import { firestore } from 'firebase';
import { ElasticSearchService } from '@a2system/angular/common'; // } from '@a2system/angular/common';

@Injectable({
  providedIn: 'root'
})
export class PlateService {
  geocodingService: any;



  constructor(
    private userService: UserService,
    private vehicleService: VehiclesService,
    private db: AngularFirestore,
    private functions: AngularFireFunctions,
    private es: ElasticSearchService,

  ) {}

  //________________________________________________//
  
  /**
   * by Xav
   * @param numberPlate 'string' matrícula buscada 
   * @param found 'bool' encontró ? 
   * @summary Añade a la coleccion de 'searchLog'quien hizo una busqueda
   */
  setSearchLog(numberPlate, found, vehicleIds) {
    let data = {
      userId: this.userService.userG.id,
      displayName: this.userService.userG.displayName,
      firstName: this.userService.userG.firstName,
      lastName: this.userService.userG.lastName,
      email: this.userService.userG.email,
      numberPlate: numberPlate,
      createdAt: firestore.FieldValue.serverTimestamp(),
      found: found,
      vehicleIds: vehicleIds
    };

    return this.db.collection('searchLogs').add(data);
  }
  /**
   * @summary Envia la información al firebase para notificar un admin
   * @param data 
   */
  sendAdmin(data){
    let info = {
      idUser: this.userService.userG.id,
      query: data.query,
      comment: data.comment,
    }
    return  this.functions.httpsCallable('requestPlateSearch')(info).toPromise();
  }

  //________________________________________________//
  async query(paginator, sort, filter, options?){
    let extras = {

      createdAt:{
        type:"dateRange",
      },
      
      // vehicles_idType:{
      //   nested:"vehicles"
      // },
      // vehicles_year:{
      //   nested:"vehicles"
      // },
      // vehicles_color:{
      //   nested:"vehicles"
      // },
      // vehicles_idBrand:{
      //   nested:"vehicles"
      // },
      // vehicles_numberPlate:{
      //   nested:"vehicles",
      //   type:"wildcard"
      // },
      // location:{
      //   nested:"location.address_components"
      // },
      // postal_code:{
      //   nested:"location.address_components"
      // },
      // locality:{
      //   nested:"location.address_components"
      // },
      // administrative_area_level_2:{
      //   nested:"location.address_components"
      // },
      // administrative_area_level_1:{
      //   nested:"location.address_components"
      // },
      // country:{
      //   nested:"location.address_components"
      // },
    }
    // console.log("Filters antes:", filter);
    filter = await this.normalize(filter);
    // console.log("Filters:", filter);
    let _options:any=null;
    if (options?.body){
      _options ={
        body:true
      }

    }
    let body = this.es.mtToBody(paginator, sort, filter, extras) //convertimos los parámetros al formato de ElasticSearch
    // console.log("Body: ", body);
    return this.es.query("searchLogs",body, (item)=>{
      // //Transformamos todos los dates a Timestamp que es como lo utilizamos
      // item.orderDate = new firestore.Timestamp(item.orderDate._seconds, item.orderDate._nanoseconds);
      item.createdAt = new firestore.Timestamp(item.createdAt._seconds, item.createdAt._nanoseconds);
      // item.items.deliveryDate = new firestore.Timestamp(item.items.deliveryDate._seconds, item.items.deliveryDate._nanoseconds);
      return item;
    } )
  }
  async normalize(_filter){
    console.log("_filter",_filter);
    // _filter.vehicles_numberPlate.map(np=>{
    _filter.numberPlate.map(np=>{
      console.log(np);
      np.value = np.value.toUpperCase().replace(/[\ ]/g, '');
      return np;
    })
    return _filter;

  }

  
}
