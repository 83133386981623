import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-kpi-card',
  templateUrl: './kpi-card.component.html',
  styleUrls: ['./kpi-card.component.scss']
})
export class KpiCardComponent {
  
  @Input('title') title: string = '';
  @Input('value') value: Number = 0;
  @Input('iconName') iconName: string = '';

  constructor(
    
  ) { }

}
