import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { A2filter } from '@a2system/angular/common'; // } from '../shared/a2filter';


@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit{

  filterForm = new FormGroup ({
    location: new FormControl(''),
    createdAt: new FormControl(''),
    vehicles_idType: new FormControl(''),
    vehicles_numberPlate: new FormControl(''),
    vehicles_idBrand: new FormControl(''),
    vehicles_idModel: new FormControl(''),
    vehicles_year: new FormControl(''),
    vehicles_color: new FormControl(''),
    disabled: new FormControl('')//keyword

  });
  a2filter =  new A2filter(this.filterForm);

  constructor(
    public translate: TranslateService,
  ){}

  ngOnInit() {}
}
