export interface ReportModel{
  id?: string;
  idUser: string // id del usuario que ha pedido el reporte
  createdAt: Date //<Timestamp> usar ServerTimestamp()
  index: string // colección sobre la cual se hace la búsqueda (ejm: 'users')
  body: object // body query **completo** usado en elastic search.
  status: ReportModel.Status // estado actual
  reason: string // solo si status:error
  downloadUrl: string //solo si status:success
  filePath: string // path del archivo al storage, solo si status:success
  updatedAt?: Date // creado en el manejo de DB y ES
}

export namespace ReportModel{
  export enum Status {
    PENDING = 'pending',
    PROCESSING = 'processing',
    SUCCESS = 'success',
    ERROR = 'error'
  }
}