import { Component, OnInit } from '@angular/core';
import { MatToolbarModule } from '@angular/material/toolbar';
import { UserService } from './core/user.service';
import { AuthService } from './core/auth/auth.service';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

import * as firebase from 'firebase/app';
import { NgxPermissionsService } from 'ngx-permissions';
import { MatDialog } from '@angular/material/dialog';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { version } from 'package.json';
import { first } from 'rxjs/operators';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'Avisacar';
  public version: string = version;

  public user: any;
  constructor(
    matToolbarModule:MatToolbarModule,
    public afAuth: AngularFireAuth,
    public authService: AuthService,
    private userService: UserService,
    public dialog: MatDialog,
    public router: Router,
    public translate: TranslateService,
    private permissionsService: NgxPermissionsService

  ){

    this.setLanguage();
    this.initializeApp();
  }

  async initializeApp(){
    // let user = await this.afAuth.authState.pipe(first()).toPromise();
    // console.log("User", user);
    // let perm = ['admin'];
    // const datos = await user.getIdTokenResult();
    // let role = null;
    // if(datos && datos.claims.role){
    //   role= datos.claims.role;
    //   perm = [role];
    //   this.permissionsService.loadPermissions(perm);
    // }else{
    //   role=null;
    //   perm = [role];
    //   this.permissionsService.loadPermissions(perm);
    // }

    this.userService.user.subscribe(user => this.user = user);
  }
   ngOnInit(): void {
    //const perm = ["admin", "p2", "p3"];
    //this.permissionsService.loadPermissions(['admin']);
  }
  chgPassword(){
    // console.log("password")
    const dialogRef = this.dialog.open(ChangePasswordComponent, {
      data: {
        user:{}
      }
    });

    dialogRef.afterClosed().subscribe(user => {
      // console.log("password changed")
      // if(categoryName){
      //   category.name = categoryName;
      //   this.categoryService.upsertCategory(category);
      // }else{
      //   console.log("undefined, reestablece name");
      // }
    });
  }
  logout(){
    this.authService.doLogout()
    // .then(res => console.log("logout"));
     .then(res => this.router.navigate(['login']));
  }
  setLanguage() {
    this.translate.setDefaultLang('es');
  }
}
