import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'otherChkr'
})
export class OtherChkrPipe implements PipeTransform {

  constructor(
    private translate: TranslateService
  ){

  }


  transform(key: any , data): string {

    let value = this.othersChecker(key, data)

    return value;
  }



  /*
   * Aqui checkearemos si este valor posee un other, de ser así
   * buscará la data tipo other
  */
 othersChecker(key: any , data){

    let toShow =  this.translate.instant('' + data[key]);
    // let toShow =   data[key]);

    
    if (data[key + 'Other'] != undefined && data[key + 'Other'] != '' ) {
      
      toShow = (this.translate.instant('OTHER')) + ': (' + data[key + 'Other'] + ')';
    } else{


      switch (key) {
        case 'color':
         toShow = this.translate.instant('COLOR.' + data[key])
          break;
        default:
          break;
      }

    }
    return toShow;
  }
}
