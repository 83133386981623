<div class="search-container" fxLayout="column" fxLayoutAlign="center center">

  <!-- Titular -->
  <h1>Buscador de matrículas</h1>
  <div>Escribe la matrícula aquí abajo</div>

  <!-- Tarjeta centrada -->
  <mat-card class="mat-elevation-z6" fxLayout="column" fxLayoutAlign="center none">
    <form [formGroup]="filterForm" (ngSubmit)="onSubmit()">
      <mat-form-field fxFlex="100">
        <mat-label>Matrícula</mat-label>
        <input matInput formControlName="numberPlate" type="text" placeholder="1234ARP"
          autofocusplaceholder="Tipo de Vehículo" required>
        <mat-hint align="end">{{filterForm.controls.numberPlate.value?.length || 0}} / 20</mat-hint>
        <mat-error *ngIf="filterForm.get('numberPlate').invalid">
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <div>
              <mat-icon>info</mat-icon>
              {{getErrorMessage( filterForm.get('numberPlate') )}}
            </div>
            <mat-hint>{{filterForm.controls.numberPlate.value?.length || 0}} / 20</mat-hint>
          </div>
        </mat-error>
      </mat-form-field>
    </form>
  </mat-card>

  <!-- Boton de busqueda -->
  <button mat-raised-button [disabled]="!filterForm.controls['numberPlate'].valid" (click)="onSubmit()" color="primary">Buscar</button>

</div>