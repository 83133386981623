import { Injectable, NgZone } from '@angular/core';
import { Plugins } from 'protractor/built/plugins';
// const { Browser, Geolocation  } = Plugins;


@Injectable({
  providedIn: 'root'
})
export class GeocodingService {
  GooglePlacesSrv: google.maps.places.PlacesService;
  GoogleAutocomplete: google.maps.places.AutocompleteService;
  GooggleGeocoder: google.maps.Geocoder;

  constructor(
    public zone: NgZone,
  ) {
    this.GooglePlacesSrv  = new google.maps.places.PlacesService(document.createElement('div'));
    this.GoogleAutocomplete = new google.maps.places.AutocompleteService();
    this.GooggleGeocoder  = new google.maps.Geocoder();

  }
  //TODO documentar y colocar opciones por defecto
  getPlacePredictions(input, types, country){
    console.log("___GetPlacePredictions");
    return new Promise((resolve, reject) => {
      let results=[];
      this.GoogleAutocomplete.getPlacePredictions({
        input: input,
        componentRestrictions: { country: country },
        types: types },
        (predictions, status) => {
          this.zone.run(() => {
            predictions.forEach((prediction) => {
              results.push(prediction);
            });
            resolve(results)
          });
        }
      );
    })
  }

  getPlaceDetail(place_id){
    return new Promise((resolve, reject)=>{
      this.GooglePlacesSrv.getDetails(
        {placeId:place_id, fields:['address_components', 'formatted_address', 'geometry', 'name', 'place_id', 'url']}, 
        (placeResult, status)=>{
          this.zone.run(() => {
            const {address_components, formatted_address, geometry, name, place_id, url} = placeResult;
            const _geometry ={
              location:{
                lat: geometry.location.lat(),
                lng: geometry.location.lng(),
              }
            }
            const location = {address_components, formatted_address, geometry:_geometry, name, place_id, url};

            resolve(location);
          })
        }
      )
    })
  }

  /**
   *
   * @param ref Referencia a buscar, por ahora solo funciona con placeId sin embargo en un futuro pueden ser lat y lon
   *
   */
  // async gotoMap(ref){
  //   // await Browser.open({ url: 'http://capacitor.ionicframework.com/' });
  //   Browser.open({ url: 'https://www.google.com/maps/place/?q=place_id:'+ref.place_id })
  //   .then(_=>{
  //     console.log("Abrio Bien la pagina web");
  //   })
  //   .catch(err=>console.log("Geo Goto Err: ", err));
  //   //return window.location.href = 'https://www.google.com/maps/place/?q=place_id:'+ref.placeId;
  // }
  

  //TODO Se debe verificar y probar bien que pasa cuando no tienes ubicación o no tienes permiso.
  /**
   * Busca las coordenadas de la persona y en base a eso busca la dirección
   */
  // getCurrentLocation(){
  //   return Geolocation.getCurrentPosition()
  //   .then(resp => this.getDirection(resp.coords.latitude, resp.coords.longitude))
  // }

  /**
   * Obtiene la dirección a partir de una coordenadas
   * @param lat Latitud
   * @param lon Longitus
   * Return una promesa con un Objeto del tipo Location
   */
  getDirection(lat, lon){
    const request = {
      location:new google.maps.LatLng(lat, lon)
    };
    return new Promise((resolve, reject) => {
      this.GooggleGeocoder.geocode(request, (results, status) => {
        if (status == google.maps.GeocoderStatus.OK) {
          if (results[0] != null) {
            const {address_components, formatted_address, geometry, place_id, ...rest} = results[0];
            const _geometry ={
              location:{
                lat: geometry.location.lat(),
                lng: geometry.location.lng(),
              }
            }
            const location = {address_components, formatted_address, geometry:_geometry, place_id};
            this.zone.run(() => {
              resolve(location)
            })
          } else {
            reject("No address available")
          }
        }
      })
    })

  }
}
