<div *ngIf="filters?.length">
  <mat-divider ></mat-divider>
  <p>
    <mat-chip-list>
      <mat-chip
        *ngFor="let filter of filters; let i = index"
        (removed)="remove(i)"
      >
        {{ isObject(filter.value) ? filter.value.name :  filter.value}}
        <mat-icon matChipRemove>cancelar</mat-icon>
      </mat-chip>
    </mat-chip-list>
  </p>
</div>