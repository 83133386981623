<div fxFill fxLayout="column">
  <h1 mat-dialog-title>{{data.user.id ? "Editar Usuario" : "Crear Usuario"}}</h1>
  <mat-dialog-content fxFlex="1 1 grow">

    <form  [formGroup]="userForm" (ngSubmit)="onSubmit()" fxLayout="column" fxFlex="grow">
      <div fxLayout="row" fxLayoutGap="10px">
        <mat-form-field  fxFlex="50%">
          <input  matInput placeholder="Nombre" type="text" formControlName="firstName" required>
        </mat-form-field>
        <mat-form-field  fxFlex="50%">
          <input  matInput placeholder="Apellido" type="text" formControlName="lastName" required>
        </mat-form-field>
      </div>
      <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="space-between center">
        <mat-form-field  fxFlex="50%" >
          <input  matInput placeholder="Nombre a Mostrar" type="text" formControlName="displayName" required minlength="2">
          <mat-error>
           El nombre a mostrar es necesario
          </mat-error>
        </mat-form-field>
        <!-- Email -->
        <mat-form-field  fxFlex="50%" class="input-mail">
          <input  matInput placeholder="Correo electrónico" type="email" formControlName="email" required>
          <mat-error *ngIf="userForm.get('email').hasError('email') && !userForm.get('email').hasError('required')">
            Correo electrónico inválido
          </mat-error>
          <mat-error *ngIf="userForm.get('email').hasError('required')">
            Correo electrónico es requerido
          </mat-error>
        </mat-form-field>

        <!-- <mat-slide-toggle fxFlex="30%" formControlName="isAdmin" class="example-margin" color="acent">
          Admin
        </mat-slide-toggle> -->
      </div>

      <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="space-between center">
        <mat-form-field  fxFlex="33%">
          <input  matInput placeholder="Contraseña" type="password" formControlName="password" minlength="6">
          <mat-hint *ngIf="!data.user.id" align="start"><strong>Contraseña por defecto: ##4v1s4c4r##</strong> </mat-hint>
          <mat-error>
            Contraseña requiere al menos 6 caracteres
          </mat-error>
        </mat-form-field>




        <mat-form-field >
          <mat-label>Rol</mat-label>
          <mat-select [disabled]="isLoading" formControlName="role" required>
            <mat-option value="admin">Admin</mat-option>
            <mat-option value="p2">P2</mat-option>
            <mat-option value="p3">P3</mat-option>
            <mat-option value="p4">P4</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="space-between center">

      <span>Bloquear al usuario</span>
      <input mat-slide-toggle type="checkbox">
      </div> -->




      <div fxFlex="1 1 auto"></div>
      <div class="footer" fxLayoutAlign="end center" fxLayoutGap="10px">
        <button type="button" [disabled]="isLoading" mat-button mat-raised-button color="warn" [mat-dialog-close]="true">
          Cancelar
        </button>

        <button mat-button [disabled]="isLoading" mat-raised-button color="primary">
          Guardar
        </button>

        <mat-spinner diameter="20" *ngIf="isLoading"></mat-spinner>
      </div>
    </form>

  </mat-dialog-content>
</div>