import { AfterViewInit, Component, OnInit, ViewChild, Input, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
// import { AngularFirestore } from '@angular/fire/firestore';
import { UsersTableDataSource} from './users-table-datasource';
import { A2filter } from '@a2system/angular/common'; // } from '@a2system/angular/common';;
// import { AngularFireFunctions } from '@angular/fire/functions';

import { UserModel } from 'src/app/models/user.model';
import { UserService } from 'src/app/core/user.service';

import { timeout, takeWhile } from 'rxjs/operators';
import { ReportService } from 'src/app/shared/report.service';
import { ReportModel } from 'src/app/models/report.model';
import { merge } from 'rxjs';
import { keyValuesToMap } from '@angular/flex-layout/extended/typings/style/style-transforms';
import { UiService } from 'src/app/shared/ui.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from 'src/app/components/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-users-table',
  templateUrl: './users-table.component.html',
  styleUrls: ['./users-table.component.scss']
})
export class UsersTableComponent implements AfterViewInit, OnInit, OnDestroy {

  @Input() a2filter: A2filter;
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  @ViewChild(MatTable, {static: false}) table: MatTable<UserModel>;
  dataSource: UsersTableDataSource;
  displayedColumns: string[] = ['photoURL', 'firstName', 'lastName', 'email', 'vehicles','address','disabled', 'phoneNumber','createdAt', 'actions'];
  agSubscription;


  isLoading: boolean = false;
  url: string = null;
  private filterIndex: string =  "users";

  constructor(
    private  dataService: UserService,
    private reportService: ReportService,
    private userService: UserService,
    private uiService: UiService,
    public dialog: MatDialog,

  ){}

  ngOnInit() {
    this.dataSource = new UsersTableDataSource(this.dataService);


    // Por si queremos refresh. En este caso no aplica
    this.agSubscription = this.userService.onChange()
    .subscribe(data=>{
      this.refresh();
    })
  }

  ngAfterViewInit() {
    this.dataSource.a2filter = this.a2filter;
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.table.dataSource = this.dataSource;
    setTimeout(() => {
      this.refresh();
    }, 0);

    merge(
      this.a2filter.filterChange,
      this.sort.sortChange
    ).subscribe( () => {
      console.log( 'reset');
      this.url = null;
    })
  }
  ngOnDestroy(){
    this.agSubscription.unsubscribe();
  }

  refresh(){
    console.log("Refresh pez");
    this.a2filter.refresh();
  }

  //Trae la información de la dirección pero con los datos que queremos
  getAddressComponent(components = [], type, long = true) {
    let resp = ' '; // may be undefined

    //Buscaremos si el tipo esta presente en el componente
    // let c = components.find( e => e.types.includes( type ) );
    let c = components.find( e => e.types.includes( type ) );

    if ( c ) {
      resp = long ? c.long_name : c.short_name;

    }
    return resp;
  }

  disableUser(user){
    console.log("User", user)
    const newStat = !user.disabled;
    console.log("New Stat", newStat);
    this.uiService.spinner.show();
    this.userService.disableUser(user.id, user.uid, newStat)
    .then(_=>{
      this.uiService.toast("Usuario " + newStat ? 'Desactivado' : 'Activado' , 600);
      this.uiService.spinner.reset();
    })
    .catch(err=>{
      alert(err);
      this.uiService.spinner.reset();
    })
  }

  async deleteUser(user){
    const dialogRef =  this.openConfirmDialog(`¿Seguro quiere eliminar al usuario ${user.displayName}?`);
    const result = await dialogRef.afterClosed().toPromise();
    if(result) {
      let _user = {
        uid:user.uid,
        role:null
      }
      this.uiService.spinner.show();
      await this.userService.deleteUserUser(_user);
      this.uiService.spinner.reset();
    }
  }

  openConfirmDialog(message): any {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: message,
      // disableClose: true,
    });
    return dialogRef;
  }

  report(){
    //si ya esta lo descarga
    if (this.url != null) {
      window.open(this.url)
      return;
    }
    this.isLoading = true
    const paginator={
      pageIndex:0,
      pageSize:this.dataSource.count
    }
    const query = {
      paginator: paginator,
      sort: this.sort,
      filter: this.a2filter.filters,
    }
    this.reportService.startReport(this.filterIndex, query)
    .then(obs => {
      obs.pipe( takeWhile( ()=> this.isLoading))
        .subscribe( report => {
          console.log('Reporting......');
          if (report.status == ReportModel.Status.SUCCESS) {
            console.log(report);
            this.url = report.downloadUrl;
            this.isLoading = false
          }
          if (report.status == ReportModel.Status.ERROR) {
            console.log("Error: ",report);
            this.url = null;
            this.isLoading = false
          }
      })
    }).catch( error => {
      console.log(error)
      console.error(error)
      this.url = null;
      this.isLoading = false
    })

  }
}
