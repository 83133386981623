<div class="admin-container">

  <div fxLayout="column" fxLayoutAlign="center center" fxFill>
    
    <!-- Estado creando mensaje -->
    <ng-container *ngIf="inputUsed ; else adminThanks">
      <form [formGroup]="filterForm" (ngSubmit)="sendAdminForm()" style="width: 50%;">
        <h1 align="center">Informar a Administrador</h1>
        <mat-card class="mat-elevation-z8 mat-card-result">
          <mat-card-header>
            <mat-card-title>
              {{filterForm.controls.numberPlate.value}}
            </mat-card-title>
          </mat-card-header>
          <mat-card-content>

            <div fxLayout="column" fxLayoutAlign="center center">

              <mat-form-field>
                <mat-label>Deja un comentario</mat-label>
                <textarea matInput formControlName="message" rows="5" autofocus maxlength="500" required></textarea>
                <mat-hint align="end">
                  {{filterForm.controls.message.value?.length || 0}} / 500
                </mat-hint>
                <mat-error *ngIf="filterForm.get('message').invalid">
                  <div fxLayout="row" fxLayoutAlign="space-between center">
                    <div>
                      <mat-icon>info</mat-icon>
                      {{getErrorMessage( filterForm.get('message') )}}
                    </div>
                    <mat-hint>{{filterForm.controls.message.value?.length || 0}} / 500</mat-hint>
                  </div>
                </mat-error>
              </mat-form-field>
            </div>

          </mat-card-content>
        </mat-card>

        <div fxLayoutAlign="center center">
          <button mat-raised-button type="button" (click)="changeScreen()" color="warn">Regresar</button>
          &nbsp;
          <button mat-raised-button type="submit" [disabled]="!filterForm.valid" color="primary">Enviar</button>
        </div>
      </form>
    </ng-container>

    <ng-template #adminThanks>

      <!-- Estado cargando -->
      <mat-spinner *ngIf="isLoading" diameter="50"></mat-spinner>

      <!-- Estado enviado -->
      <div *ngIf="!isLoading" fxLayout="column" fxLayoutAlign="space-between center">
        <h1 align="center">Enviado ! </h1>
        <h2>Gracias por enviar.</h2>
        <button mat-raised-button align="center" (click)="changeScreen()" color="primary">Regresar a inicio</button>
      </div>

    </ng-template>
  </div>

</div>
