<div class="result-container">
  <!--poner un div que envuelva el contenido....  -->
  <div fxLayout="column" fxLayoutAlign="start center" fxFill>

    <!-- Estado encuentran resultados -->
    <ng-container *ngIf="owners.length > 0 ; else notFound">
      <h1 align="center">Resultados encontrados de</h1>
      <h2 align="center">"{{searched}}"</h2>
      <div fxLayout="column" fxLayoutAlign="start center">
        <mat-card *ngFor="let owner of owners" class="mat-elevation-z8 mat-card-result">
          <mat-card-content>

            <div fxLayout="row" fxLayoutAlign="none">

              <div fxFlex="45" fxLayout="column" fxLayoutAlign="space-evenly none">

                <h2 class="titles">Información del vehículo:</h2>

                <div class="info-adjust" fxLayout="column" fxLayoutAlign="center none">
                  <!-- "Otro: ("+ (("VEHICLE_TYPE."+owner.vehicle.typeOther) | translate) + ")" -->
                  <ng-container [ngTemplateOutlet]="userDetail"[ngTemplateOutletContext]="
                  { 
                    data:{ 
                      title: 'Tipo', 
                      info: owner.vehicle?.idType == '-1' 
                      ? ownerTypeOther(owner) 
                      :  ('type' | otherChkr: owner.vehicle)
                    }
                  }"></ng-container>
                  <ng-container [ngTemplateOutlet]="userDetail"[ngTemplateOutletContext]="{ data:{ title: 'Marca', info: ('brand' | otherChkr: owner.vehicle) }}"></ng-container>
                  <ng-container [ngTemplateOutlet]="userDetail"[ngTemplateOutletContext]="{ data:{ title: 'Modelo', info: ('model' | otherChkr: owner.vehicle) }}"></ng-container>
                  <ng-container [ngTemplateOutlet]="userDetail"[ngTemplateOutletContext]="{ data:{ title: 'Año', info: ('year' | otherChkr: owner.vehicle) }}"></ng-container>
                  <ng-container [ngTemplateOutlet]="userDetail"[ngTemplateOutletContext]="{ data:{ title: 'Color', info: ('color' | otherChkr: owner.vehicle) }}"></ng-container>
                </div>

              </div>

              <div fxFlex="10" fxLayout="row" fxLayoutAlign="center none">
                <mat-divider vertical="true"></mat-divider>
              </div>

              <div fxFlex="45" fxLayout="column" fxLayoutAlign="space-evenly none">

                <h2 class="titles">Información del Usuario:</h2>

                <div class="info-adjust" fxLayout="column" fxLayoutAlign="center none">                    
                  <ng-container *ngIf="userService.userG && userService.userG.role == 'admin'"  [ngTemplateOutlet]="userDetail"[ngTemplateOutletContext]="{ data:{ title: 'Nombre', info: owner.user.displayName}}"></ng-container>
                  <ng-container [ngTemplateOutlet]="userDetail"[ngTemplateOutletContext]="{ data:{ title: 'Número', info: owner.user.phoneNumber}}"></ng-container>
                  <ng-container *ngIf="userService.userG && userService.userG.role == 'admin'"  [ngTemplateOutlet]="userDetail" [ngTemplateOutletContext]="{ data:{ title: 'Dirección', info: owner.user.location.formatted_address}}">
                  </ng-container>
                </div>

              </div>
            </div>
          </mat-card-content>
          <div fxLayout="row" fxLayoutAlign="center center">
            <div fxLayout="column" fxLayoutAlign="center center" class="pointer-events-none" [ngClass]="{'can-edit-resident':canEditResident}"  (click)="onChangeRes({owner:owner, property:'resident'})">
              <span>Residente</span>
              <mat-icon >
                {{owner.vehicle.resident ? 'radio_button_checked': 'radio_button_unchecked'}}
              </mat-icon>            
            </div>
            <div fxLayout="row" fxLayoutAlign="center center">
              <div fxLayout="column" fxLayoutAlign="center center" class="pointer-events-none" [ngClass]="{'can-edit-resident':canEditResident}"  (click)="onChangeRes({owner:owner, property:'aRes'})">
                <span>A</span>
                <mat-icon >
                  {{owner.vehicle.aRes ? 'check_box_outline': 'check_box_outline_blank'}}
                </mat-icon> 
              </div>
              <div fxLayout="column" fxLayoutAlign="center center" class="pointer-events-none" [ngClass]="{'can-edit-resident':canEditResident}"  (click)="onChangeRes({owner:owner, property:'bRes'})">
                <span>B</span>
                <mat-icon >
                  {{owner.vehicle.bRes ? 'check_box_outline': 'check_box_outline_blank'}}
                </mat-icon> 
              </div>
              <div fxLayout="column" fxLayoutAlign="center center" class="pointer-events-none" [ngClass]="{'can-edit-resident':canEditResident}"  (click)="onChangeRes({owner:owner, property:'cRes'})">
                <span>C</span>
                <mat-icon >
                  {{owner.vehicle.cRes ? 'check_box_outline': 'check_box_outline_blank'}}
                </mat-icon> 
              </div>
            </div>
            
          </div>
        </mat-card>
  
      </div>

    </ng-container>

    <!-- Estado No encuentran resultados -->
    <ng-template #notFound>

      <h1>No encontramos la matrícula</h1>
      <h2 align="center">
        "{{searched}}"
      </h2>
    </ng-template>


    <div fxLayoutAlign="center center" class="action-buttons">
      <button mat-raised-button (click)="clear()" color="warn">Hacer otra búsqueda</button>
      &nbsp;
      <button mat-raised-button (click)="changeScreen()" color="primary">Contactar a un Administrador</button>
    </div>

  </div>


</div>

<!-- Template Section -->

<!-- User detail  Template-->
<ng-template #userDetail let-data="data">
  <div>
    <span fxFlex="50">{{data.title}}</span>
    <b fxFlex="50">{{data.info}}</b>
  </div>
</ng-template>







<!-- old -->

<!-- <div>
    <span fxFlex="50">Tipo</span>
    <b fxFlex="50">
      {{'type' | otherChkr: owner.vehicle}}
    </b>
  </div> -->
<!-- <div>
    <span fxFlex="50">Marca</span>
    <b fxFlex="50">
      {{'brand' | otherChkr: owner.vehicle}}
    </b>
  </div> -->
<!-- <div>
    <span fxFlex="50">Modelo</span>
    <b fxFlex="50">
      {{'model' | otherChkr: owner.vehicle}}
    </b>
  </div> -->


<!-- <div>
    <span fxFlex="50">Año</span>
    <b fxFlex="50">
      {{'year' | otherChkr: owner.vehicle}}
    </b>
  </div> -->


<!-- <div>
    <span fxFlex="50">Color</span>
    <b fxFlex="50">
      {{'color' | otherChkr: owner.vehicle}}
    </b>
  </div> -->




<!-- user detail -->
<!-- <div>
    <span fxFlex="50">Nombre</span>
    <b fxFlex="50">{{owner.user.displayName}}</b>
  </div> -->
<!-- <div>
    <span fxFlex="50">Número</span>
    <b fxFlex="50">{{owner.user.phoneNumber}}</b>
  </div>

  <div>
    <span fxFlex="50">Dirección:</span>
    <b fxFlex="50">{{owner.user.location.formatted_address}}</b>
  </div> -->
