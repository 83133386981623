<div class="container-boxed">
  <div fxLayout="column" fxLayoutGap="10px" fxFlexFill>

    <!-- <div> -->
    <div fxLayout="row" fxLayoutAlign="end center">
      <form [formGroup]="formDateDashboard">
        <!-- <mat-form-field appearance="outline" class="form-field-calendar"> -->
        <mat-form-field appearance="outline" class="form-field-calendar  spacing-bottom">
          <mat-label>Rango de Fechas</mat-label>
          <input  matInput ngxDaterangepickerMd formControlName="dateRanges" [alwaysShowCalendars]="true"
            [ranges]="ranges" [showClearButton]="false" [showCancel]="true" [linkedCalendars]="false"
            [showCustomRangeLabel]="true" [locale]="datePickerOptions" [keepCalendarOpeningWithRange]="true"
            [showRangeLabelOnInput]="true" opens='left' drops='down' [showDropdowns]="true" autocomplete="new-password"
            autocomplete="off" [readonly]="true" (rangeClicked)="rangeClicked($event)"
            (datesUpdated)="datesUpdated($event)" />
          <!-- <input fxFlex matInput ngxDaterangepickerMd formControlName="dateRanges" [alwaysShowCalendars]="true"[ranges]="ranges" [showClearButton]="false" [showCancel]="true" [linkedCalendars]="false"[showCustomRangeLabel]="true" [locale]="datePickerOptions" [keepCalendarOpeningWithRange]="true"[showRangeLabelOnInput]="true" opens='left' drops='down' [showDropdowns]="true" autocomplete="new-password"autocomplete="off" [readonly]="true" (rangeClicked)="rangeClicked($event)"(datesUpdated)="datesUpdated($event)" /> -->
          <mat-icon color="accent" matSuffix (click)="openDateRange()" class="ngx-daterangepicker-action">date_range
          </mat-icon>
        </mat-form-field>
      </form>
    </div>


    <!-- Last kpi -->
    <div fxLayout="column" [fxLayout.gt-md]="'row'" fxLayoutGap="10px" fxLayoutAlign="space-between none">
    <app-kpi-card *ngFor="let item of totals" [fxFlex.gt-md]="'20%'" [title]="item.name" [value]="item.value"[iconName]="item.iconName"></app-kpi-card>
  </div>

    <!-- <div fxLayout="row wrap" fxFlex fxLayoutAlign="space-between none" fxLayoutGap="10px">
    <app-kpi-card *ngFor="let item of totals" fxFlex="100%" [fxFlex.gt-md]="'calc(20% - 10px)'"   [title]="item.name" [value]="item.value"[iconName]="item.iconName"></app-kpi-card>
  </div> -->

    <div fxLayout="row wrap" fxLayoutAlign="center">

      <ng-container [ngTemplateOutlet]="barChart"[ngTemplateOutletContext]=" {data: usersByDate, title:'Usuarios Registrados por fecha'} "></ng-container>
      <div class="barcharter"></div>
      <ng-container [ngTemplateOutlet]="pieChart"[ngTemplateOutletContext]=" {data: vehiclesType, title:'Vehículos por tipo'} "></ng-container>

      <ng-container [ngTemplateOutlet]="barChart"[ngTemplateOutletContext]=" {data: vehiclesByDate, title:'Vehículos Registrados por fecha'} "></ng-container>
      <div class="barcharter"></div>
      <ng-container [ngTemplateOutlet]="pieChart"[ngTemplateOutletContext]=" {data: vehiclesBrand, title:'Vehículos por marca'} "></ng-container>

      <ng-container [ngTemplateOutlet]="barChart"[ngTemplateOutletContext]=" {data: incidencesByDate, title:'Incidencias Reportadas por fecha'} "></ng-container>
      <div class="barcharter"></div>
      <ng-container [ngTemplateOutlet]="pieChart"[ngTemplateOutletContext]=" {data: incidenceType, title:'Incidencias por tipo'} "></ng-container>

    </div>
  </div>

</div>








<!-- Template BarChart -->
<ng-template #barChart let-data="data" let-title="title">

  <!-- 100% last  -->
  <mat-card fxFlex="100%" fxFlex.gt-md="calc(60% - 10px)" class="spacing-bottom">
    <mat-card-header>
      <mat-card-title>
        {{title}} <label>{{agrupation}}</label>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div>
        <mat-spinner *ngIf="loadingData"></mat-spinner>
        <google-chart [options]="chartOptions" fxFlex *ngIf="!loadingData && data?.length > 0" [type]="'Bar'" [data]="data"
          [options]="{legend: {position: 'none'} }" [dynamicResize]="true"></google-chart>
        <p *ngIf="!loadingData && !(data?.length > 0)" class="chart-data-empty">No hay datos para este rango de fechas
        </p>
      </div>
    </mat-card-content>
  </mat-card>
</ng-template>

<!-- Template PieChart -->
<ng-template #pieChart let-data="data" let-title="title">
  <!-- <mat-card fxFlex="100%" fxFlex.gt-md="calc(40% - 10px)" class="test"> -->

  <!-- 100% last fxFlex="100%"-->
  <mat-card fxFlex="100%" fxFlex.gt-md="calc(40% - 10px)" class="spacing-bottom">
    <mat-card-header>
      <mat-card-title>{{title}}</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div>
        <mat-spinner *ngIf="loadingData"></mat-spinner>
        <google-chart [options]="chartOptions"  fxFlex *ngIf="!loadingData && data?.length > 0" [type]="'PieChart'" [data]="data"
          [dynamicResize]="true"></google-chart>
        <p *ngIf="!loadingData && !(data?.length > 0)" class="chart-data-empty">No hay datos para este rango de fechas
        </p>
      </div>
    </mat-card-content>
  </mat-card>
</ng-template>
