<!-- Columna -->
<div fxLayout="row" fxLayoutAlign="space-between start">

  <!-- row Foto -->
  <div class="container" fxFlex fxLayout="row" fxLayoutAlign="center center">

    <img class="incidence-picture" (click)="openIncidentPhoto(data)"
      src="{{ data.pictureMedium ? data.pictureMedium : data.pictureThumb ? data.pictureThumb : '/assets/images/without-photo.png'}}">
  </div>

  <!--row Details -->
  <div class="container incidence-details" fxFlex="35" fxLayout="column" fxLayoutAlign="space-evenly none">

    <div fxLayout="column" fxLayoutAlign="center end">
      <strong>{{data.createdAt.toDate() | date:'d/M/yy' }} </strong>
      <div class="mat-small">{{data.createdAt.toDate() | date:'H:mm' }} h</div>
    </div>

    <div>
      <div>Matrícula</div>
      <b style="word-break: break-all;">{{data.numberPlate}}</b>
    </div>

    <div fxLayout="column">
      <div>Ubicación</div>
      <b>

        <a target="_blank" href="{{url}}" style="word-break: break-all;">
          {{data.location 
            ? (
              (getAddressComponent(data.location.address_components, 'administrative_area_level_1'))
              + " " + (getAddressComponent(data.location.address_components, 'locality'))
              + " " +(getAddressComponent(data.location.address_components, 'postal_code'))
              )
              : '-'
            }}
        </a>
      </b>
    </div>

    <div fxLayout="column">
      <div>Tipo</div>
      <b style="word-break: break-all;" translate>{{data.type}}</b>
    </div>

    <div fxLayout="column">
      <div>{{data.vehicles.length > 1 ? 'Vehículos' : 'Vehículo'}}</div>
      <div fxLayout="column" fxLayoutAlign="center  center">

        <span *ngFor="let vehicle of data.vehicles">
          <b>{{
            vehicle?.idType == '-1'  
            ? 'Otro: (' + ("VEHICLE_TYPE."+vehicle.typeOther | translate) + ')' 
            : ('type' | otherChkr: vehicle) 
          }}</b>
          {{'brand' | otherChkr: vehicle}}
          <!-- {{vehicle.brand}} -->
          {{'model' | otherChkr: vehicle}},
          <!-- {{vehicle.model}}, -->
          {{'year' | otherChkr: vehicle}}
          <!-- {{vehicle.year}}, -->
          {{'color' | otherChkr: vehicle}}
          <!-- {{"COLOR." + vehicle.color | translate}}, -->
          ({{vehicle.numberPlate}})
        </span>
      </div>
    </div>

    <div fxLayout="column" >
      <div>{{ data.vehicles.length > 1 ? 'Propietarios' : 'Propietario'}}</div>
      <div fxLayout="column" fxLayoutAlign="center  center">
        <span *ngFor="let owner of owners">
          <b style="word-break: break-all;">
            {{owner.user.displayName}} ( {{owner.inbox.read == true ? 'Leído' : 'No Leído'}}  )
          </b>
        </span>
      </div>
    </div>


    <div fxLayout="column">
      <div>Reportado por</div>

      <b style="word-break: break-all;">{{data.sender }}</b>
    </div>

  </div>


</div>
<div fxLayoutAlign="end end" style="margin-top: 24px;">
  <button mat-stroked-button color="warn" matDialogClose>Cerrar</button>
</div>

<!-- LOCATIOn -->
<!-- <div fxLayout="column">
  <b>Location</b>
  <div>
    {{data.location ? ((getAddressComponent(data.location.address_components, 'administrative_area_level_1')) + " " + (getAddressComponent(data.location.address_components, 'locality')) + " " +(getAddressComponent(data.location.address_components, 'postal_code'))) : '-' }}
  </div>
  <button mat-stroked-button (click)="gotoMap()">boton para google maps</button>
</div> -->