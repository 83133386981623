<div class="container-boxed">


  <mat-card class="mat-elevation-z3">

    <mat-card-title fxLayoutAlign="space-between center">
      <span>Perfiles</span>
      <button mat-stroked-button color="primary" (click)="new()">
        Nuevo
      </button>
    </mat-card-title>

    <table mat-table [dataSource]="users" class="mat-elevation-z8">
      <!-- Display Column -->
      <ng-container matColumnDef="displayName">
        <th mat-header-cell *matHeaderCellDef> Nombre a Mostrar </th>
        <td mat-cell *matCellDef="let element"> {{element.displayName}} </td>
      </ng-container>
      <!-- Name Column -->
      <ng-container matColumnDef="firstName">
        <th mat-header-cell *matHeaderCellDef> Nombre </th>
        <td mat-cell *matCellDef="let element"> {{element.firstName}} </td>
      </ng-container>
      <!-- Last Name Column -->
      <ng-container matColumnDef="lastName">
        <th mat-header-cell *matHeaderCellDef> Apellido </th>
        <td mat-cell *matCellDef="let element"> {{element.lastName}} </td>
      </ng-container>
      <!-- Email Column -->
      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef> Correo </th>
        <td mat-cell *matCellDef="let element"> {{element.email}} </td>
      </ng-container>
      <!-- Role Column -->
      <ng-container matColumnDef="role">
        <th mat-header-cell *matHeaderCellDef> Rol </th>
        <td mat-cell *matCellDef="let element"> {{element.role}} </td>
      </ng-container>
      <!-- Actions -->
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef> Acciones </th>
        <td mat-cell *matCellDef="let row">
          <!-- si no eres admin esto esta disabled -->
          <ng-container *ngIf="user.role === 'admin'">
            <button mat-icon-button (click)="edit(row)">
              <mat-icon>edit</mat-icon>
            </button>
            <button mat-icon-button (click)="delete(row)">
              <mat-icon color="warn">delete_outline</mat-icon>
            </button>
          </ng-container>
          <!-- <button mat-icon-button (click)="delete(row)">
          <mat-icon>vpn_key</mat-icon>
        </button> -->
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </mat-card>

</div>
