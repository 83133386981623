import { AppComponent } from './app.component';
import { NgModule } from '@angular/core';
import { GuardService } from './core/auth/guard.service';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { UsersComponent } from './users/users.component';
import { VehiclesComponent } from './vehicles/vehicles.component';
import { IncidencesComponent } from './incidences/incidences.component';
import { PlatesComponent } from './plates/plates.component';
import { LoginComponent } from './login/login.component';
import { NoticesComponent } from './notices/notices.component';
import { NgxPermissionsGuard } from 'ngx-permissions';
import { UsersAdminComponent } from './users-admin/users-admin.component';
import { LogsComponent } from './logs/logs.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';


const routes: Routes = [
  { path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  },

  //{

  // path: '',
  //   redirectTo: 'home',
  //   pathMatch: 'full',
    // path: '',
    // // canActivate: [GuardService],
    // component: HomeComponent,
    // // canLoad: [NgxPermissionsGuard],
    // // data: {
    // //   permissions: {
    // //     only: ['admin','p2','p3']
    // //   }
    // // }


  {
    path: 'home',
    component: HomeComponent,
    canActivate: [GuardService],
    data: {
      permissions: {
        only: ['admin','p2'],
        redirectTo: '/plates'
      },

    }
  },
  {
    path: 'login',
    component: LoginComponent
  },
  { path: 'forgot-password', component: ForgotPasswordComponent},
  {
    path: 'users-admin',
    canActivate: [GuardService],
    component: UsersAdminComponent,
    data: {
      permissions: {
        only: ['admin']
      }
    }
  },
  {
    path: 'users',
    canActivate: [GuardService],
    //canActivate: [GuardService],
    component: UsersComponent,
    data: {
      permissions: {
        only: ['admin']
      }
    }
  },
  {
    path: 'vehicles',
    canActivate: [GuardService],
    component: VehiclesComponent,
    data: {
      permissions: {
        only: ['admin']
      }
    }
  },
  {
    path: 'incidences',
    canActivate: [GuardService],
    component: IncidencesComponent,
    data: {
      permissions: {
        only: ['admin','p2']
      }
    }
  },
  {
    path: 'plates',
    component: PlatesComponent,
    data: {
      permissions: {
        only: ['admin','p2','p3','p4']
      }
    }
  },
  {
    path: 'logs',
    canActivate: [GuardService],
    component: LogsComponent,
    data: {
      permissions: {
        only: ['admin']
      }
    }
  },
  {
    path: 'notices',
    canActivate: [GuardService],
    component: NoticesComponent,
    data: {
      permissions: {
        only: ['admin','p2']
      }
    }
  },


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
