import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IncidencesTableComponent } from '../incidences-table.component';
import { GeocodingService } from 'src/app/shared/geocoding.service';
import { TranslateService } from '@ngx-translate/core';
import { UserModel } from 'src/app/models/user.model';
import { IncidentsService } from '../../incidents.service';
import { UserService } from 'src/app/core/user.service';

@Component({
  selector: 'app-incidence-detail',
  templateUrl: './incidence-detail.component.html',
  styleUrls: ['./incidence-detail.component.scss']
})
export class IncidenceDetailComponent implements OnInit {

  url = '';
  users: Array<UserModel> = [];
  owners: Array<any>;
  constructor(
    public dialogRef: MatDialogRef <IncidencesTableComponent>,
    public translate: TranslateService,
    public incidentsService: IncidentsService,
    private userService: UserService,
    private geocodingService: GeocodingService ,
    @Inject (MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void {
    this.getOwners();
    console.log(this.data) 
    this.gotoMap();
  }

  async getOwners(){
    //revisar tambien si los han leido
    //por cada user pasar un where con el id de la incidencia y el inbox de su typeIdRef
    let ownersPromises = [];
    this.data.vehicles.forEach(vehicle => {
      ownersPromises.push(
        this.userService.getUser(vehicle.idUser)
        .toPromise()
        .then( user => {
          return this.incidentsService.getInboxByIncidence(user, this.data.id)
          .toPromise()
          .then(inbox => {
            console.log({inbox: inbox, user: user});
            return {inbox: inbox, user: user};
          })
        })
      )
    });
    Promise.all(ownersPromises).then(
      owners => {
      this.owners = owners;
      console.log("Result!", this.owners);
    })



  }

  //Trae la información de la dirección pero con los datos que queremos
  getAddressComponent(components = [], type, long = true) {
    let resp = ' '; // may be undefined

    //Buscaremos si el tipo esta presente en el componente
    // let c = components.find( e => e.types.includes( type ) );
    let c = components.find( e => e.types.includes( type ) );

    if ( c ) {
      resp = long ? c.long_name : c.short_name;

    }
    return resp;
  } 
    //Muestra la foto en otra ventana, con la mejor 
  //calidad posible, caso contrario la peor o no tener
  openIncidentPhoto( row ){
    window.open(
      row.picture
        ? row.picture
        : row.pictureMedium
          ? row.pictureMedium 
          : row.pictureThumb  
            ? row.pictureThumb 
            : '/assets/images/without-photo.png'
    );

  }


 async gotoMap(){
    // this.geocodingService.gotoMap({place_id: (this.message.incidence ? this.message.incidence.location.place_id : this.message.location.place_id) })
    
    const way = this.geocodingService.getPlaceDetail(this.data.location.place_id)
    .then( (a:any) => {
        this.url = a.url;
    });

  }
}
