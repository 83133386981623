<!--  -->
<mat-card fxFlex="100%" [fxFlex.gt-md]="''">
  <mat-card-content fxLayout="column" fxLayoutAlign="space-between none">

    <div class="title" fxLayout="row" fxLayoutAlign="end center" >
      {{title}}
    </div>

    <div fxLayout="row" fxLayoutAlign="space-between center" class="info-container">
      <mat-icon color="accent">{{iconName}}</mat-icon>
      <span class="total-result">{{value}}</span>
    </div>
  </mat-card-content>
</mat-card>

<!--
  Por qué no usa mat-card-header?:
  El card content al ponerle 100% posee un height basado en el height del card,
  pero si colocas un header implicará que el height del card content sea mayor,
  y se desborde...
  por otro lado si no se da el 100% al content, habrá unos casos donde hay bloques
  con la informacion que no se encontrarán en la misma posición alineadamente, viendose raro

  buscar una mejor forma...
-->




<!-- old version
<mat-card fxFlex="100%" [fxFlex.gt-md]="''">
      <mat-card-header>
        <div class="title" fxLayout="row" fxLayoutAlign="end center" fxFlex>
          {{title}}
        </div>
      </mat-card-header>
      <mat-card-content fxLayout="column" fxLayoutAlign="end none">

        <div class="content"  fxLayout="row" fxLayoutAlign="space-between center">

          <mat-icon color="accent">{{iconName}}</mat-icon>
          <span class="total-result">{{value}}</span>

        </div>
      </mat-card-content>
    </mat-card>
-->