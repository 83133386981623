import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { A2filter } from '@a2system/angular/common'; // } from '../shared/a2filter';
import { Observable, from, of, merge } from 'rxjs';
import { map, shareReplay, startWith, switchMap, last } from 'rxjs/operators';

import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { AngularFireFunctions } from '@angular/fire/functions';

import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-vehicles',
  templateUrl: './vehicles.component.html',
  styleUrls: ['./vehicles.component.scss']
})
export class VehiclesComponent implements OnInit{
  public data;
  public results;
  public isFetching;
  isHandset$: Observable<boolean> = this.breakpointObserver
  .observe(Breakpoints.Handset)
  .pipe(
    map(result => result.matches),
    shareReplay()
  );

  filterForm = new FormGroup ({
    location: new FormControl(''),
    createdAt: new FormControl(''),
    idType: new FormControl(''),
    numberPlate: new FormControl(''),
    idBrand: new FormControl(''),
    idModel: new FormControl(''),
    year: new FormControl(''),
    color: new FormControl('')
  });

  a2filter =  new A2filter(this.filterForm);
  


  constructor(
    private breakpointObserver: BreakpointObserver,
    private functions: AngularFireFunctions,
    public translate: TranslateService,
  ) {}
  ngOnInit() {}

  getData(){
    this.results = [];
    const query ="Ja";
    const body = {
      "query": {
        "bool":{
          "filter":[
            {"wildcard":{ "name" : "jo*"}},
            {"term":{ "eyeColor" : "brown"}},
            {"range":{"age":{"lte":23}}}
          ]
        }
      },
      "from": 10,
      "size": 10,
      "sort": [
        {
          "age": {
            "order": "asc"
          }
        }
      ],
      "_source": ["name", "eyeColor"]
    }
    const call = this.functions.httpsCallable('search');
    this.isFetching = true;
    call({ body, index: "users_test" }).subscribe(
      response => {
        console.log("got response")
        if (response) {
          console.log("got data", response);
          this.data = response;
          if (this.data.hits && this.data.hits.hits.length) {
            console.log("total rec", this.data.hits.total.value);
            this.results = [];
            this.data.hits.hits.forEach((item, index) => this.results[index] = item._source);
          } else {
            //this.reset();
          }
        }
        this.isFetching = false;
      },
      error => {
        console.error(`Error fetching search results`, error);
        this.isFetching = false;
      }
    );
  }

/**
 * This function calls the `getSomeInfo` Cloud Function and logs the response.
 */
exFunction() {
  const call = this.functions.httpsCallable('getSomeInfo');

  call({}).subscribe(
    (getSomeInfoResponse) => {
      if (getSomeInfoResponse) {
        console.log(getSomeInfoResponse);
      } else {
        console.log("No response received from the Cloud Function.");
      }
    },
    (error) => {
      console.error(`Error fetching information from the Cloud Function:`, error);
    }
  );
}

}
