import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { UserService } from '../core/user.service';
import { firestore } from 'firebase';
import { ElasticSearchService } from '@a2system/angular/common'; // } from '@a2system/angular/common';
import { AngularFirestoreCollection, AngularFirestore } from '@angular/fire/firestore';
import { take, map, takeWhile } from 'rxjs/operators';

import { ReportModel } from '../models/report.model';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  private collection: AngularFirestoreCollection<ReportModel>;

  constructor(
    private db: AngularFirestore,
    private userService: UserService,
    private es: ElasticSearchService
  ) {
    this.collection = this.db.collection<ReportModel>('reports');
  }

  get(id){
    return this.collection.doc(id)
    .get()
    .pipe(
      map(doc => {
        const id= doc.id;
        const data = doc.data() as ReportModel
        return {id, ...data};
      })
    )
  }
  delete(id: string){
    return this.collection.doc<ReportModel>(id).delete();
  }

  upsert(_data): Promise<any>{
    const {id, ...data} = _data;
    if(!id){data.createdAt = firestore.FieldValue.serverTimestamp();}
    data.updatedAt = firestore.FieldValue.serverTimestamp();
    return (id)
      ? this.collection.doc(id).update(data)
      : this.collection.add(data)
    ;
  }


  // Creo un reporte y regreso una promesa con un observable 
  public startReport(index: string, query: any){
    let extras = {
      body: true
    }
    
    const idUser = this.userService.userG.id
    const createdAt = firestore.FieldValue.serverTimestamp() ;
    const body = this.es.mtToBody(query.paginator, query.sort, query.filter, extras)
    const status = 'pending';

    const data: any = {
      idUser: idUser,
      createdAt: createdAt,
      index: index,
      body: body,
      status: status,
      reason: '',
      downloadUrl: '',
      filePath: ''
    };
    console.log('###-Body: ',body)
    // ya tengo los datos que quiero crear
    return this.upsert(data)
      .then( doc => 
        // this.get(val.id)
        this.collection.doc<ReportModel>(doc.id).valueChanges()
      )
    ;
  }
}