import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'safevalue'
})
export class SafevaluePipe implements PipeTransform {

  constructor(
    private sanitizer: DomSanitizer
  ){
  }
  transform(value: any): any {
    let template = value;

    
    const reg = /href="www./g;
    template = template.toString().replace(reg, 'href="https://www.')
    template = this.sanitizer.bypassSecurityTrustHtml(template);
    return template;
  }

}

// https://stackoverflow.com/questions/31548311/angular-html-binding?rq=1
// https://angular.io/api/platform-browser/DomSanitizer