import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OtherChkrPipe } from './other-chkr.pipe';
import { SafevaluePipe } from './safevalue.pipe';



@NgModule({
  declarations: [
    OtherChkrPipe,
    SafevaluePipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    OtherChkrPipe,
    SafevaluePipe
  ]
})
export class PipeModule { }
