import { Component, OnInit, Input, ViewChild, OnDestroy, AfterViewInit } from '@angular/core';
import { A2filter } from '@a2system/angular/common'; // } from '@a2system/angular/common';;
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';

import { NoticeModel } from 'src/app/models/notice.model';
import { NoticesTableDataSource } from './notices-table-datasource';
import { NoticesService } from '../notices.service';
import { MatDialog } from '@angular/material/dialog';
import { NoticeFormComponent } from './notice-form/notice-form.component';

@Component({
  selector: 'app-notice-table',
  templateUrl: './notice-table.component.html',
  styleUrls: ['./notice-table.component.scss'],
  // encapsulation: ViewEncapsulation.None
}) 
export class NoticeTableComponent implements AfterViewInit, OnInit, OnDestroy {
  @Input() a2filter: A2filter;
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  @ViewChild(MatTable, {static: false}) table: MatTable<NoticeModel>;
  
  dataSource: NoticesTableDataSource ;
  displayedColumns: string[] = ['id'];
  agSubscription;// ??

  constructor(
    private  dataService: NoticesService ,
    public dialog: MatDialog

  ) { }

  ngOnInit(): void {
    this.dataSource = new NoticesTableDataSource(this.dataService);

    this.agSubscription = this.dataService.onChange()
    .subscribe(()=>{
      this.refresh();
    })
  }
  ngAfterViewInit() {

    this.dataSource.a2filter = this.a2filter;
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.table.dataSource = this.dataSource;

    
    setTimeout(() => {
      this.refresh();
    }, 0);
    console.log(this.dataSource.count);

  }
  ngOnDestroy(){
    // this.agSubscription.unsubscribe();
  }

  refresh(){
    console.log("Refresh pez");
    this.a2filter.refresh();
  }

  openDialog(){
    const dialogRef = this.dialog.open(NoticeFormComponent  , {
      // width: '80%',
      width: '80%',
      disableClose: true,

    });
  }

   a2FilterToStringArr(a2FilterObj){
    // console.log(a2FilterObj);
    let arrayRecipienteChips = [];
     Object.keys(a2FilterObj).forEach(key => {
      a2FilterObj[key].forEach(filter=>{
        if(filter.value){
          arrayRecipienteChips.push(filter.value.name ? filter.value.name :filter.value)   
        }
      })    
    })
    return arrayRecipienteChips;

  }
}