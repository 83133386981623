import { Component, OnInit, ViewChild, OnDestroy, AfterViewInit, Input } from '@angular/core';
import { DataSource } from '@angular/cdk/table';
import { Observable, of as observableOf, merge, BehaviorSubject } from 'rxjs';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { A2filter } from '@a2system/angular/common'; // } from '@a2system/angular/common';;
import { switchMap, takeWhile } from 'rxjs/operators';
import { MatTable } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';


import { Incidence } from 'src/app/models/incidence.model';
import { IncidentsService } from '../incidents.service';
import { IncidencesTableDataSource } from './incidences-table-datasource';
import { IncidenceDetailComponent } from './incidence-detail/incidence-detail.component';
import { UserService } from 'src/app/core/user.service';
import { ReportService } from 'src/app/shared/report.service';
import { ReportModel } from 'src/app/models/report.model';

@Component({
  selector: 'app-incidences-table',
  templateUrl: './incidences-table.component.html',
  styleUrls: ['./incidences-table.component.scss']
})
export class IncidencesTableComponent implements AfterViewInit, OnInit, OnDestroy {
  @Input() a2filter: A2filter;
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  @ViewChild(MatTable, {static: false}) table: MatTable<Incidence>;


  dataSource: IncidencesTableDataSource;
  displayedColumns: string[] = ['image','sender','type','numberPlate','vehicles','location','createdAt'];
  agSubscription;

  isLoading: boolean = false;
  url: string = null;
  private filterIndex: string =  'incidences';

  constructor(
    //Servicio

    public dataService: IncidentsService,
    public dialog: MatDialog,
    private userService: UserService,
    private reportService: ReportService,

  ) {}

  ngOnInit(){
    this.dataSource = new IncidencesTableDataSource(this.dataService);

    console.log('LLEGA??',this.dataSource);
  }
  ngAfterViewInit() {
    console.log(this.sort.sortables);
    this.dataSource.a2filter = this.a2filter;
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.table.dataSource = this.dataSource;


    merge( 
      this.a2filter.filterChange,
      this.sort.sortChange  
    ).subscribe( () => {
      console.log( 'reset');
      this.url = null;
    })
    
    setTimeout(() => {
      this.refresh();
    }, 0);
  }
  ngOnDestroy(){
  }
  refresh(){
    console.log('Refresh pez');
    this.a2filter.refresh();
  }
  //Trae la información de la dirección pero con los datos que queremos
  getAddressComponent(components = [], type, long = true) {
    let resp = ' '; // may be undefined

    //Buscaremos si el tipo esta presente en el componente
    // let c = components.find( e => e.types.includes( type ) );
    let c = components.find( e => e.types.includes( type ) );

    if ( c ) {
      resp = long ? c.long_name : c.short_name;

    }
    return resp;
  } 

  //Muestra la foto en otra ventana, con la mejor 
  //calidad posible, caso contrario la peor o no tener
  openIncidentPhoto( row, event ){
    console.log(row);
    // event.stopPropagation();
    event.stopPropagation(); 
    window.open(
      row.picture
        ? row.picture
        : row.pictureThumb    
          ? row.pictureThumb 
          : '/assets/images/without-photo.png'
    );

  }


  openDialog(row): void {
    console.log(row);
    console.log('Acceso solo a "admin" , tu eres : ',this.userService.userG.role)
    if (this.userService.userG.role == 'admin') {
      const dialogRef = this.dialog.open(IncidenceDetailComponent, {
        width: '70vw',
        data: row
      });
    }
  }

  report(){
    //si ya esta lo descarga
    if (this.url != null) {
      window.open(this.url)
      return;
    }
    this.isLoading = true
    const paginator={
      pageIndex:0,
      pageSize:this.dataSource.count
    }
    const query = {
      paginator: paginator,
      sort: this.sort,
      filter: this.a2filter.filters,
    }
    this.reportService.startReport(this.filterIndex, query)
    .then(obs => {
      obs.pipe( takeWhile( ()=> this.isLoading))
        .subscribe( report => {
          console.log('Reporting......');
          if (report.status == ReportModel.Status.SUCCESS) {
            console.log(report);
            this.url = report.downloadUrl;
            this.isLoading = false
          }
          if (report.status == ReportModel.Status.ERROR) {
            console.log("Error: ",report);
            this.url = null;
            this.isLoading = false
          }
      })
    }).catch( error => {
      console.log(error)
      console.error(error)
      this.url = null;
      this.isLoading = false
    })
    
  }
  // report(){
  //   //si ya esta lo descarga
  //   if (this.url != null) {
  //     window.open(this.url)
  //     return;
  //   }
  //   this.isLoading = true
  //   const query = {
  //     paginator: this.paginator,
  //     sort: this.sort,
  //     filter: this.a2filter.filters,
  //   }

  //   console.log('starting report')
  //   this.reportService.startReport('incidences', query)
  //   .then(obs => {
  //     obs.pipe( takeWhile( ()=> this.isLoading))
  //       .subscribe( report => {
  //         if (report.status == 'success') {
  //           console.log(report);
  //           this.url = report.downloadUrl;
  //           this.isLoading = false
  //         }
  //     })
  //   })
  // }

}
