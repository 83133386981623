<mat-sidenav-container class="sidenav-container">

  <mat-sidenav #drawer class="sidenav" attr.role='navigation' opened disableClose mode='side'>
    <mat-toolbar>Filtros</mat-toolbar>
    <div fxLayout="column" fxLayoutAlign="start center" class="search-form">
      <!-- Filtros de User -->
      <app-filters [filterForm]="filterForm" [a2filter]="a2filter"></app-filters>
    </div>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-card>
      <!-- Tabla de User -->
      <app-users-table [a2filter]="a2filter"></app-users-table>
    </mat-card>
  </mat-sidenav-content>
</mat-sidenav-container>
