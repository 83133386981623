<div class="main-container" fxLayout="column" fxLayoutAlign="center center" >
  <form [formGroup]="loginForm" (ngSubmit)="login()">
    <mat-card>
      <mat-card-header>
        <div mat-card-avatar class="header-image"></div>
        <mat-card-title>Login</mat-card-title>
        <mat-card-subtitle>Introduzca su correo y contraseña</mat-card-subtitle>
      </mat-card-header>

      <mat-card-content fxLayout="column">
        <mat-form-field>
          <input class="email-input" appAutoFocus matInput placeholder="Correo"  name="email" type="email" required  formControlName="email" >
        </mat-form-field>
        <div class="error-container">
          <ng-container *ngFor="let validation of validation_messages.email">
            <div class="error-message" *ngIf="loginForm.get('email').hasError(validation.type) && (loginForm.get('email').dirty || loginForm.get('email').touched)">
              <mat-icon>info</mat-icon>
              <span >{{ validation.message }}</span>
            </div>
          </ng-container>
        </div>

        <mat-form-field >
          <input matInput placeholder="Contraseña"  name="clave" type="password" formControlName="password">
        </mat-form-field>
        <div class="error-container">
          <ng-container *ngFor="let validation of validation_messages.password">
            <div class="error-message" *ngIf="loginForm.get('password').hasError(validation.type) && (loginForm.get('password').dirty || loginForm.get('password').touched)">
              <mat-icon>info</mat-icon>
              <span >{{ validation.message }}</span>
            </div>
          </ng-container>
        </div>
        <a [routerLink]="['/forgot-password']" style="text-align: right;">¿Olvidó su contraseña?</a>

      </mat-card-content>

      <mat-card-actions>
        <span fxFlex="1 1 auto"></span>
        <button mat-raised-button color="primary" type="submit" >Entrar</button>
      </mat-card-actions>
    </mat-card>
  </form>
</div>
