<div  class="plates-container">
  <!-- fxLayout="row" fxLayoutAlign="center center" -->
  
  <ng-container *ngIf="!openAdminForm; else adminForm">
    <!-- Estado de busqueda -->
    <app-plate-search *ngIf="!inputUsed" [filterForm]="filterForm" [validationMessages]="validationMessages" (submitter)="onSubmit()"></app-plate-search>

    <!-- Estado de cargando -->
    <div *ngIf="isLoading" fxLayoutAlign="center center" class="spinner-container">
      <mat-spinner diameter="50"></mat-spinner>
    </div>

    <!-- Estado de resultados encontrados ó no encontrado -->
    <app-plates-result *ngIf="inputUsed && !isLoading" [owners]="owners" [searched]="filterForm.controls.numberPlate.value" (back)="clear()"(changeScr)="changeScreen()"></app-plates-result>
  </ng-container>

  <ng-template #adminForm>

    <!-- Estado reportar al admin y pantalla de mensaje enviado -->
      <app-inform-admin [isLoading]="isLoading" [filterForm]="filterForm" [inputUsed]="inputUsed" [validationMessages]="validationMessages" (scrChanger)="changeScreen()" (sendForm)="sendAdminForm()"></app-inform-admin>

  </ng-template>
</div>