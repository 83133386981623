import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { AuthService } from '../core/auth/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;

  validation_messages = {
    'email': [
      { type: 'required', message: 'Email Requerido' },
      { type: 'pattern', message: 'Emal Inválido' }
    ],
    'password': [
      { type: 'required', message: 'Clave requerida' },
      { type: 'minlength', message: 'La clave debe contener al menos 5 caracteres' }
    ],
  }

  constructor(
    public router: Router,
    private authService: AuthService
  ) {
    this.loginForm = new FormGroup({
      'email': new FormControl('', Validators.compose([
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')
      ])),
      'password': new FormControl('', Validators.compose([
        Validators.minLength(5),
        Validators.required
      ])),
    });
  }

  ngOnInit(): void {
  }

  login(){
    console.log("Login");
    if (this.loginForm.valid){

      this.authService.doEmailLogin(this.loginForm.value)
      .then(res => {
        console.log(res);
        this.router.navigate(['home']);
      })
      .catch(res =>{
        switch (res.code) {
          case 'auth/user-not-found':
            this.loginForm.controls['email'].setErrors({'incorrect': true});
            break;
          case 'auth/wrong-password':
            this.loginForm.controls['password'].setErrors({'incorrect': true});
            break;
          case 'auth/unauthorized':
            console.log("nonono autorizado");
            this.loginForm.controls['password'].setErrors({'incorrect': true});
            break;
        }
      });



    }else{
      console.log("Invalid Data");
    }
  }

}
