import { Component, OnInit } from '@angular/core';
import { UserService } from '../core/user.service';
import { MatDialog } from '@angular/material/dialog';
import { UserFormComponent } from './user-form/user-form.component';
import { UserModel } from '../models/user.model';
import { ConfirmationDialogComponent } from '../components/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-users-admin',
  templateUrl: './users-admin.component.html',
  styleUrls: ['./users-admin.component.scss']
})
export class UsersAdminComponent implements OnInit {
  public users;
  user;
  displayedColumns: string[] = ['displayName','firstName','lastName','email','role', 'actions'];
  constructor(
    private userService: UserService,
    public dialog: MatDialog,
  ) {
    this.user = userService.userG
   }

  ngOnInit(): void {
    this.userService.getAdminUsers()
    .subscribe(users =>{
      this.users = users;
      this.user = this.userService.userG;
    })
  }
  openUserDialog(user){
    const dialogRef = this.dialog.open(UserFormComponent, {
      height: '50%',
      width: '50%',
      data: {
        user
      },
      disableClose: true
    });
    
  }
  edit(user){
    this.openUserDialog(user);
  }

  delete(_user){
    const dialogRef =  this.openConfirmDialog(`¿Seguro quiere eliminar al usuario ${_user.displayName}?`)
    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        let user = {
          uid:_user.uid,
          role:null
        }
        this.userService.deleteAdminUser(user);
      }
    });
  }

  new(){
    const nUser = new UserModel();
    this.openUserDialog(nUser);
  }

  openConfirmDialog(message): any {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: message,
      // disableClose: true,
    });
    return dialogRef;
  }
}
