import { Component, OnInit, ViewChild, OnDestroy, AfterViewInit, Input } from '@angular/core';
import { DataSource } from '@angular/cdk/table';
import { VehicleModel } from 'src/app/models/vehicle.model';
import { VehiclesService } from '../vehicles.service';

import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { A2filter } from '@a2system/angular/common'; // } from '@a2system/angular/common';;
import { switchMap, takeWhile } from 'rxjs/operators';
import { MatTable } from '@angular/material/table';
import { VehiclesTableDataSource } from './vehicles-table-datasource';
import { ReportService } from 'src/app/shared/report.service';
import { report } from 'process';
import { ReportModel } from 'src/app/models/report.model';
import { merge } from 'rxjs';

@Component({
  selector: 'app-vehicles-table',
  templateUrl: './vehicles-table.component.html',
  styleUrls: ['./vehicles-table.component.scss']
})

export class VehiclesTableComponent implements AfterViewInit, OnInit, OnDestroy {
  @Input() a2filter: A2filter;
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  @ViewChild(MatTable, {static: false}) table: MatTable<VehicleModel>;


  dataSource: VehiclesTableDataSource;
  displayedColumns: string[] = ['type','brand','model','year','color','numberPlate', 'resident', 'aRes', 'bRes', 'cRes' ,'location','createdAt'];
  agSubscription;

  isLoading: boolean = false;
  url: string = null;
  private filterIndex: string =  'vehicles';

  constructor(
    //Servicio
    private reportService: ReportService,
    public dataService: VehiclesService,
  ) {}

  ngOnInit(){
    this.dataSource = new VehiclesTableDataSource(this.dataService);
    this.a2filter.filterChange.subscribe( () => {
      this.url = null;
    })
  }
  ngAfterViewInit() {
    this.dataSource.a2filter = this.a2filter;
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.table.dataSource = this.dataSource;
    
    merge( 
      this.a2filter.filterChange,
      this.sort.sortChange  
    ).subscribe( () => {
      this.url = null;
    })

    setTimeout(() => {
      this.refresh();
    }, 0);
  }
  ngOnDestroy(){
  }
  refresh(){
    this.a2filter.refresh();
  }


  //Trae la información de la dirección pero con los datos que queremos
  getAddressComponent(components = [], type, long = true) {
    let resp = ' '; // may be undefined
    //Buscaremos si el tipo esta presente en el componente
    // let c = components.find( e => e.types.includes( type ) );
    let c = components.find( e => e.types.includes( type ) );
    if ( c ) {
      resp = long ? c.long_name : c.short_name;
    }
    return resp;
  } 


   report(){
    //si ya esta lo descarga
    if (this.url != null) {
      window.open(this.url)
      return;
    }
    this.isLoading = true
    const paginator={
      pageIndex:0,
      pageSize:this.dataSource.count
    }
    const query = {
      paginator: paginator,
      sort: this.sort,
      filter: this.a2filter.filters,
    }
    this.reportService.startReport(this.filterIndex, query)
    .then(obs => {
      obs.pipe( takeWhile( ()=> this.isLoading))
        .subscribe( report => {
          if (report.status == ReportModel.Status.SUCCESS) {
            this.url = report.downloadUrl;
            this.isLoading = false
          }
          if (report.status == ReportModel.Status.ERROR) {
            console.log("Error: ",report);
            this.url = null;
            this.isLoading = false
          }
      })
    }).catch( error => {
      console.error(error)
      this.url = null;
      this.isLoading = false
    })
    
  }


  onChangeResidentOptions(row, property){
    row[property]= !row[property];
    if(property=='resident' && ! row[property]){
      row['aRes'] = false;
      row['bRes'] = false;
      row['cRes'] = false;
    }
    const {id, resident=false, aRes=false, bRes=false, cRes=false} = row;
    //Update
    const res = this.dataService.updateVehicle(id, {
      resident,
      aRes,
      bRes,
      cRes
    })

  }
}