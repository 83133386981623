import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map, shareReplay, concatMap, first, filter, take, startWith } from 'rxjs/operators';
import { VehiclesService } from 'src/app/vehicles/vehicles.service';
import { UserService } from '../core/user.service';
import { PlateService } from './plate.service';
// import { NgxPermissionsService } from 'ngx-permissions';


@Component({
  selector: 'app-plates',
  templateUrl: './plates.component.html',
  styleUrls: ['./plates.component.scss']
})
export class PlatesComponent implements OnInit  {

  inputUsed = false;
  openAdminForm = false;
  isLoading= false;
  owners = [];

  isHandset$ : Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
  .pipe(
    map(result => result.matches),
    shareReplay()
  );
  filterForm = this.fb.group({
    numberPlate  :['', [Validators.required, Validators.minLength(2),  Validators.maxLength(20)]   ],
    message      :['', [Validators.required, Validators.minLength(10), Validators.maxLength(500)] ],
  });
  validationMessages = {
    numberPlate : [ {type: 'required', message: 'Matrícula vacía'}, {type: 'minLength', message: 'Matrícula muy pequeña'}, {type: 'maxLength', message: 'Matrícula muy Larga'} ],
    message : [ {type: 'required', message: 'Mensaje vacío'}, {type: 'minLength', message: 'Mensaje corto'}, {type: 'maxLength', message: 'Mensaje Largo'} ]
  };

  constructor(
    private breakpointObserver: BreakpointObserver,
    private vehiclesService: VehiclesService ,
    private userService: UserService ,
    private plateService: PlateService,
    private fb: FormBuilder,
    public translate: TranslateService ,

  ) {}
  ngOnInit(): void {

    // // Matrícula
    // this.filterForm.controls.numberPlate.valueChanges.pipe(
    //   map( () => {
    //     const alphaNumeric = /[A-Za-z0-9]/g;
    //     const asterisk = /[*]/g;
    //     const numberPlate = this.filterForm.controls.numberPlate.value;
    //     const asteriskContain = (numberPlate.match(asterisk) || []);
    //     let newNumberPlate: Array<string> = (numberPlate.match(alphaNumeric) || []);
    //     if (asteriskContain.length == 1) {
    //       newNumberPlate.pop();
    //     }
    //     if (newNumberPlate.length != 0){
    //       newNumberPlate.push("*");
    //       newNumberPlate.unshift("*");
    //     }
    //     this.filterForm.controls.numberPlate.setValue(newNumberPlate.join(''), {onlySelf: true , emitEvent: false });
    //   })
    // ).subscribe();


  }

  async onSubmit(){

    //Arreglamos lo escrito
    let writedPlate = this.normalize(this.filterForm.controls.numberPlate.value) ;
    this.filterForm.controls.numberPlate.setValue(writedPlate);

    //Confirmamos las validaciones
    if (this.filterForm.controls.numberPlate.valid ) {
      this.isLoading = true;
      this.inputUsed = true;

      //1. Buscamos los vehículos
      console.log("writedPlate:", writedPlate);
      this.vehiclesService.getVehicleByPlate(writedPlate)
      .toPromise()
      .then(vehicles=>{
        console.log("vehicles:",vehicles)

        this.owners = []
        let ownersPromises = [];
        let vehicleIds = [];
        //2. Buscar los usuarios de cada vehicle
        vehicles.forEach( vehicle => {

          ownersPromises.push(
            this.userService.getUser(vehicle.idUser)
            .toPromise()
            .then(user=>{
              return {vehicle, user};
            })
          )

          vehicleIds.push(vehicle.id);

        })

        Promise.all(ownersPromises)
        .then(owners=>{
          this.owners = owners;
          this.isLoading = false;
          console.log("Owners : ",this.owners);
        })

        this.sendSearchLog(writedPlate, vehicleIds);

      })
    } else{
      // this.filterForm.markAllAsTouched(); // or 
      this.filterForm.get('numberPlate').markAsTouched();
    }

  }

  /**
   * @summary Envia la informacion de quien buscó a la coleccion
   */
  sendSearchLog(numberPlate, vehiclesIds){

    let found = vehiclesIds.length != 0;
    console.log("Informacióna  enviar a la coleccion",numberPlate,found,vehiclesIds);

    this.plateService.setSearchLog(numberPlate, found, vehiclesIds);
  }


  /**
   * @summary Envia al servicio la matricula y el comentario
  */
  sendAdminForm(){

    if ( this.filterForm.valid ) {
      console.log("enviando a Admin.....");
      this.isLoading = true;
      this.inputUsed = false;

      let data = {
        query: this.filterForm.controls.numberPlate.value,
        comment: this.filterForm.controls.message.value
      }
      this.plateService.sendAdmin(data).then( a => {
        this.clear();
        this.isLoading = false;
      });
    }
  }


  //Arregla el numberPlate y lo devuelve para su uso
  //**************
  normalize(numberPlate){
    let toClear = /[*]|[\ ]/g;
   return numberPlate.toUpperCase().replace(toClear, '');
  }

//Para conocer los errores en el validator
  //**************
    getErrorMessage(group){
      //Se devuelve el primer Error que se consigue
      const control = this.getControlName(group);
      let errorMessage = "Error";
      if (this.validationMessages[control]){

        this.validationMessages[control].some((errorType) =>{

          if (group.hasError(errorType.type)) {
            errorMessage = errorType.message;
          }
          return group.hasError(errorType.type);
        });
      }

      return errorMessage;
    }
    getControlName(c: AbstractControl): string | null {
      const formGroup = c.parent.controls;
      return Object.keys(formGroup).find(name => c === formGroup[name]) || null;
    }
  //**************


  //Para conocer el location correctamente
  //**************
  getAddressComponent(components = [], type, long = true) {
    let resp = ' '; // may be undefined
    let c = components.find( e => e.types.includes( type ) );
    if ( c ) {
      resp = long ? c.long_name : c.short_name;
    }
    return resp;
  }
  //**************
  //Limpia el formulario
  //**************
  clear(){
    this.inputUsed = false;
    this.filterForm.controls.numberPlate.setValue('');
    this.filterForm.controls.message.setValue('');
    this.filterForm.markAsUntouched();
    this.filterForm.updateValueAndValidity();
  }

  //Cambia de pantalla entre la de admin o no
  //**************
  changeScreen(){
    this.openAdminForm = !this.openAdminForm;
  }

}



