<form [formGroup]="filterForm" fxLayout="column" fxLayoutAlign="start center">
  <div *ngFor="let filter of filters; index as i">
    <div [ngSwitch]="filter">
      <!-- este codigo puede ser mas reducido -->
      <div *ngSwitchCase="  (filter == 'vehicles_location' || filter == 'location') ? filter : '' ">
        <mat-form-field appearance="outline">
          <mat-label>Localidad</mat-label>
          <input matInput placeholder="Localidad" [formControlName]="filter" [matAutocomplete]="locationAutoComplete">
          <mat-autocomplete autoActiveFirstOption #locationAutoComplete="matAutocomplete"
            (optionSelected)="this.a2filter.set(filter)" panelWidth="350">
            <mat-option *ngFor="let location of filtereds[filter] | async" [value]="location">
              {{location.name }}
            </mat-option>
          </mat-autocomplete>
          <ng-container [ngTemplateOutlet]="chipsTemplate" [ngTemplateOutletContext]="{$implicit: filter}" ></ng-container>
        </mat-form-field>
      </div>
      <div *ngSwitchCase="  (filter == 'vehicles_idType' || filter == 'idType') ? filter : '' ">
        <ng-container *ngIf="filter !== incidence; else incident">
          <mat-form-field appearance="outline">
            <mat-label>Tipo de Vehículo</mat-label>
            <input matInput placeholder="Tipo de Vehículo" [formControlName]="filter"
              [matAutocomplete]="typeAutoComplete">
            <mat-autocomplete autoActiveFirstOption #typeAutoComplete="matAutocomplete"
              (optionSelected)="this.a2filter.add(filter)">
              <mat-option *ngFor="let type of filtereds[filter] | async" [value]="type">
                {{ type.name}}
              </mat-option>
            </mat-autocomplete>
            <ng-container [ngTemplateOutlet]="chipsTemplate" [ngTemplateOutletContext]="{$implicit: filter}" ></ng-container>
          </mat-form-field>
        </ng-container>
        <ng-template #incident>
          <mat-form-field appearance="outline">
            <mat-label>Tipo de Incidencia</mat-label>
            <input matInput placeholder="Tipo de Incidencia" [formControlName]="filter"
              [matAutocomplete]="typeAutoComplete1">
            <mat-autocomplete autoActiveFirstOption #typeAutoComplete1="matAutocomplete"
              (optionSelected)="this.a2filter.add(filter)">
              <mat-option *ngFor="let type of filtereds[filter] | async" [value]="type">
                {{type.name}}
              </mat-option>
            </mat-autocomplete>
            <ng-container [ngTemplateOutlet]="chipsTemplate" [ngTemplateOutletContext]="{$implicit: filter}" ></ng-container>
          </mat-form-field>
        </ng-template>
      </div>
      <div *ngSwitchCase="  (filter == 'vehicles_idBrand' || filter == 'idBrand') ? filter : ''  ">
        <mat-form-field appearance="outline">
          <mat-label>Marca</mat-label>
          <input matInput placeholder="Marca" [formControlName]="filter" [matAutocomplete]="brandAutoComplete">
          <mat-autocomplete autoActiveFirstOption #brandAutoComplete="matAutocomplete"
            (optionSelected)="this.a2filter.add(filter)">
            <mat-option *ngFor="let brand of filtereds[filter] | async" [value]="brand">
              {{ brand.name}}
            </mat-option>
          </mat-autocomplete>
          <ng-container [ngTemplateOutlet]="chipsTemplate" [ngTemplateOutletContext]="{$implicit: filter}" ></ng-container>
        </mat-form-field>
      </div>
      <div *ngSwitchCase="  (filter == 'vehicles_model' || filter == 'model' || filter == 'idModel' || filter == 'vehicles_idModel') ? filter : '' ">
        <mat-form-field appearance="outline">
          <mat-label>Modelo</mat-label>
          <input matInput placeholder="Modelo" [formControlName]="filter" [matAutocomplete]="modelAutoComplete">
          <mat-autocomplete autoActiveFirstOption #modelAutoComplete="matAutocomplete"
            (optionSelected)="this.a2filter.add(filter)">
            <mat-option *ngFor="let model of filtereds[filter] | async" [value]="model">
              {{ model.name}}
            </mat-option>
          </mat-autocomplete>
          <ng-container [ngTemplateOutlet]="chipsTemplate" [ngTemplateOutletContext]="{$implicit: filter}" ></ng-container>
        </mat-form-field>
      </div>
      <div *ngSwitchCase="  (filter == 'vehicles_year' || filter == 'year') ? filter : '' ">
        <mat-form-field appearance="outline">
          <mat-label>Año</mat-label>
          <input matInput placeholder="Año" (keyup.enter)="a2filter.add(filter)" [formControlName]="filter"
            maxlength="4">
            <ng-container [ngTemplateOutlet]="chipsTemplate" [ngTemplateOutletContext]="{$implicit: filter}" ></ng-container>
        </mat-form-field>
      </div>
      <div *ngSwitchCase="  (filter == 'vehicles_color' || filter == 'color') ? filter : '' ">
        <mat-form-field appearance="outline">
          <mat-label>Color</mat-label>
          <!-- <ng-container [ngTemplateOutlet]="selectorTemplate" [ngTemplateOutletContext]="{$implicit: filter, placeholder: 'inserte el texto'}"></ng-container> -->
          <input matInput placeholder="Color" [formControlName]="filter" [matAutocomplete]="colorAutoComplete">
           <mat-autocomplete autoActiveFirstOption  #colorAutoComplete="matAutocomplete"
            (optionSelected)="this.a2filter.add(filter)">
            <mat-option *ngFor="let color of filtereds[filter] | async" [value]="color">
              {{ color.name }}
            </mat-option>
          </mat-autocomplete>
          <ng-container [ngTemplateOutlet]="chipsTemplate" [ngTemplateOutletContext]="{$implicit: filter}" ></ng-container>
        </mat-form-field>
      </div>
      <div *ngSwitchCase="  (filter == 'vehicles_numberPlate' || filter == 'numberPlate')  ? filter : '' ">
        <mat-form-field appearance="outline" class="input-plate">
          <mat-label>Matrícula</mat-label>
          <input matInput placeholder="Matrícula" maxlength="20" (keyup.enter)="a2filter.add(filter)"
            [formControlName]="filter">
            <ng-container [ngTemplateOutlet]="chipsTemplate" [ngTemplateOutletContext]="{$implicit: filter}" ></ng-container>
        </mat-form-field>
      </div>
      <div *ngSwitchCase="  (filter == 'vehicles_email' || filter == 'email') ? filter : '' ">
        <!-- <mat-form-field appearance="outline" class="input-mail">
          <mat-label>Correo</mat-label>
          <input matInput placeholder="Correo" (keyup.enter)="a2filter.add(filter)" [formControlName]="filter">
          <ng-container [ngTemplateOutlet]="chipsTemplate" [ngTemplateOutletContext]="{$implicit: filter}" ></ng-container>
        </mat-form-field> -->
          <app-filter [type]="'lowerCase'" [label]="'Correo'" [placeholder]="'Correo'" [filter]="filter" [a2filter]="a2filter" [form]="filterForm"></app-filter>
      </div>

      <!-- Test -->
      <div *ngSwitchCase="  (filter == 'vehicles_disabled' || filter == 'disabled') ? filter : '' ">
          <mat-form-field appearance="outline">
            <mat-label>Usuarios Desactivados</mat-label>
            <!-- <ng-container [ngTemplateOutlet]="selectorTemplate" [ngTemplateOutletContext]="{$implicit: filter, placeholder: 'inserte el texto'}"></ng-container> -->
            <input matInput placeholder="Disabled" [formControlName]="filter" [matAutocomplete]="disabledAutoComplete">
             <mat-autocomplete autoActiveFirstOption  #disabledAutoComplete="matAutocomplete"
              (optionSelected)=" disabledUpdate(filter)">
              <!-- this.a2filter.set(filter); filterForm.controls[filter].setValue(lastDisabled.name) -->
              <!-- ; lastDisabled = filterForm.controls[filter].value ; -->
              <!-- ; filterForm.controls[filter].setValue() -->
              <mat-option *ngFor="let disabled of filtereds[filter] | async" [value]="disabled"  >
                {{ disabled.name }}
              </mat-option>
            </mat-autocomplete>

            <!-- <ng-container [ngTemplateOutlet]="chipsTemplate" [ngTemplateOutletContext]="{$implicit: filter}" ></ng-container> -->
          </mat-form-field>
        </div>



      <div *ngSwitchCase="  (filter == 'idSender' || filter == 'vehicles_idSender') ? filter : '' ">
        <mat-form-field appearance="outline">
          <mat-label>Remitente</mat-label>
          <input matInput placeholder="Remitente" [formControlName]="filter" [matAutocomplete]="senderAutoComplete">
          <mat-autocomplete autoActiveFirstOption #senderAutoComplete="matAutocomplete"
            (optionSelected)="this.a2filter.add(filter)">
            <mat-option *ngFor="let el of filtereds[filter] | async" [value]="el">
              {{el.name}}
            </mat-option>
          </mat-autocomplete>
          <ng-container [ngTemplateOutlet]="chipsTemplate" [ngTemplateOutletContext]="{$implicit: filter}" ></ng-container>
        </mat-form-field>
      </div>

      <!-- DATE of Birthday -->
      <div *ngSwitchCase="  (filter == 'createdAt' || filter == 'vehicles_createdAt') ? filter : '' ">
          <mat-form-field appearance="outline" class="form-field-calendar" >
              <mat-label>Rango de Fechas</mat-label>
              <!--  [startKey]="'fi'"
                [endKey]="'fe'" -->
              <!-- <input fxFlex -->
              <input 
                matInput
                ngxDaterangepickerMd

                [formControlName]="filter"
                [ranges]="ranges"
                [locale]="datePickerOptions"
               

                [alwaysShowCalendars]="true"
                [showClearButton]="false"
                [showCancel]="true"
                [linkedCalendars]="false"
                [showCustomRangeLabel]="true"
                [keepCalendarOpeningWithRange]="true"
                [showRangeLabelOnInput]="true"
                opens='right'
                drops='down'
                [showDropdowns]="true"
                autocomplete="new-password"
                autocomplete="off"
                [readonly]="true"
                (rangeClicked)="rangeClicked($event, filter)"
                (datesUpdated)="datesUpdated($event, filter)"
                />
            </mat-form-field>
      </div>

    </div>
  </div>
</form>




<!-- _____________________________________ -->
<!-- <ng-container #A [ngTemplateOutlet]="selectorTemplate" [ngTemplateOutletContext]="{$implicit: filter, placeholder: 'inserte el texto'}"></ng-container> -->
<!-- Autocomplete Template -->
<ng-template #selectorTemplate let-filter="filter" let-placeholder="placeholder">
  <mat-autocomplete autoActiveFirstOption #AutoComplete="matAutocomplete"
    (optionSelected)="this.a2filter.add(filter)">
    <mat-option *ngFor="let filtered of filtereds[filter] | async" [value]="filtered">
      {{ filtered.name}}
    </mat-option>
  </mat-autocomplete>
</ng-template>

<!-- _____________________________________ -->





<!-- A2 Chip filter container -->
<!-- <ng-container [ngTemplateOutlet]="chipsTemplate" [ngTemplateOutletContext]="filter" ></ng-container> -->
<!-- A2 Chip filter Template -->
<ng-template #chipsTemplate let-filter>
  <app-chip-filter [filterName]="filter" [filters]="a2filter.filters[filter]"
  (chipRemoved)="a2filter.remove($event)">
  </app-chip-filter>
</ng-template>
