<!--Colocar en este div si el mouse esta encima-->
<div class="mat-elevation-z8 card-table-container">

  <mat-toolbar fxLayoutAlign="space-between center">
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
      <ng-container *ngIf="dataSource">
        <mat-spinner diameter="20" *ngIf="dataSource.loading$ | async"></mat-spinner>
      </ng-container>

      <span>Incidencias</span>
      <span class="mat-small">{{dataSource?.count}} Total</span>
    </div>
    <div fxLayout="row" fxLayoutAlign="none center" fxLayoutGap="10px" class="mat-small">
      <ng-container *ngIf="!isLoading; else Loading">
        <!-- No hay url -->
        <button *ngIf="url == null" mat-stroked-button color="primary" (click)="report()">
          Descargar Reporte          
        </button>
        <!-- si hay url -->
        <button *ngIf="url != null" mat-icon-button (click)="report()">
          <mat-icon>get_app</mat-icon>
        </button>
      </ng-container>
      <ng-template #Loading>
        <mat-spinner diameter="20"></mat-spinner>
      </ng-template>
    </div>
  </mat-toolbar>
  <table  mat-table class="full-width-table" matSort 
    matSortStart="desc" 
    matSortDisableClear="true"
    matSortActive="createdAt._seconds"
    matSortDirection="desc"
  aria-label="Elements">


    <!-- Image Column -->
    <ng-container matColumnDef="image">
      <th mat-header-cell *matHeaderCellDef>Imagen</th>
      <td mat-cell *matCellDef="let row" translate>
        <img class="avatar-cover" (click)="openIncidentPhoto(row , $event )"  src="{{row.pictureThumb 
            ? row.pictureThumb 
            : row.picture 
            ? row.picture
            : '/assets/images/without-photo.png'
          }}">
      </td>
    </ng-container>

    <!-- Sender Column -->
    <ng-container matColumnDef="sender">
      <th mat-header-cell *matHeaderCellDef>Reportado por</th>
      <td mat-cell *matCellDef="let row" translate>{{row.sender}}</td>
    </ng-container>

    <!-- Id Column -->
    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef>Tipo</th>
      <td mat-cell *matCellDef="let row" translate>{{row.type}}</td>
    </ng-container>

    <!-- numberPlate Column -->
    <ng-container matColumnDef="numberPlate">
      <th mat-header-cell *matHeaderCellDef>Matrícula</th>
      <td mat-cell *matCellDef="let row" translate>{{row.numberPlate}}</td>
    </ng-container>

    <!-- Vehicles Column -->
    <ng-container matColumnDef="vehicles">
      <th mat-header-cell *matHeaderCellDef>Vehículos</th>
      <td mat-cell *matCellDef="let row">
        <!-- {{row.vehicles | json}} -->
        <span *ngFor="let vehicle of row.vehicles">
          <!-- <span><b>Vehículo : {{vehicle.type  | translate}}</b></span> -->
          <span><b>{{
            vehicle?.idType == '-1'  
            ? 'Otro: (' + ("VEHICLE_TYPE."+vehicle.typeOther | translate) + ')' 
            : ('type' | otherChkr: vehicle) 
          }}
          </b></span>
          
          <!-- {{vehicle.brand}} -->
          {{'brand' | otherChkr: vehicle}}

          {{'model' | otherChkr: vehicle}}
          <!-- {{vehicle.model}}, -->
          {{'year' | otherChkr: vehicle}}
          <!-- {{vehicle.year}},  -->
          {{'color' | otherChkr: vehicle}}
          <!-- {{"COLOR." + vehicle.color | translate}}, -->
          ({{vehicle.numberPlate}})<br>
        </span>
      </td>
    </ng-container>


    <!-- location Column -->
    <ng-container matColumnDef="location">
      <th mat-header-cell *matHeaderCellDef>Ubicación</th>
      <td mat-cell *matCellDef="let row">

        {{row.location 
            ? (
              (getAddressComponent(row.location.address_components, 'administrative_area_level_1'))
              + " " + (getAddressComponent(row.location.address_components, 'locality'))
              + " " +(getAddressComponent(row.location.address_components, 'postal_code'))
              )
            : '-'
          }}

      </td>
    </ng-container>


    <!-- CreatedAt Column -->
    <ng-container matColumnDef="createdAt">
      <th mat-sort-header="createdAt._seconds" mat-header-cell *matHeaderCellDef>Fecha</th>
      <td mat-cell *matCellDef="let row">
        <div>
          {{row.createdAt.toDate() | date:'d/M/yy, H:mm' }} h
          <!-- {{row.createdAt | json}} -->
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row matRipple (click)="openDialog(row)" *matRowDef="let row; columns: displayedColumns;"></tr>

  </table>

  <mat-paginator #paginator [length]="dataSource?.count" [pageIndex]="0" [pageSize]="10"
    [pageSizeOptions]="[5, 10, 25, 50, 100]">
  </mat-paginator>
</div>