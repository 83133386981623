import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-inform-admin',
  templateUrl: './inform-admin.component.html',
  styleUrls: ['./inform-admin.component.scss']
})
export class InformAdminComponent implements OnInit {

  @Input() isLoading: boolean = false;
  @Input() inputUsed: boolean = false;
  @Input() filterForm: FormGroup = null;
  @Input() validationMessages: {} = null;

  @Output() scrChanger :EventEmitter<any> =  new EventEmitter<any>();
  @Output() sendForm :EventEmitter<any> =  new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }



  changeScreen(){
    this.scrChanger.emit();
  }

  sendAdminForm(){
    this.sendForm.emit();
  }


  //Arregla el numberPlate y lo devuelve para su uso
  //**************
  normalize(numberPlate){
    let toClear = /[*]|[\ ]/g;
   return numberPlate.toUpperCase().replace(toClear, '');
  }
  //Para conocer los errores en el validator
  //**************
    getErrorMessage(group){
      //Se devuelve el primer Error que se consigue
      const control = this.getControlName(group);
      let errorMessage = "Error";
      if (this.validationMessages[control]){

        this.validationMessages[control].some((errorType) =>{

          if (group.hasError(errorType.type)) {
            errorMessage = errorType.message;
          }
          return group.hasError(errorType.type);
        });
      }

      return errorMessage;
    }
    getControlName(c: AbstractControl): string | null {
      const formGroup = c.parent.controls;
      return Object.keys(formGroup).find(name => c === formGroup[name]) || null;
    }
  //**************
}
