import {Input, Output, EventEmitter } from '@angular/core';
import { DataSource } from '@angular/cdk/collections';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { switchMap } from 'rxjs/operators';
import { Observable, of as observableOf, merge, BehaviorSubject } from 'rxjs';
import { A2filter } from '@a2system/angular/common'; // } from '@a2system/angular/common';;
import { UserModel } from 'src/app/models/user.model';
import { UserService } from 'src/app/core/user.service';
import { LogModel } from 'src/app/models/log.model';
import { PlateService } from 'src/app/plates/plate.service';

/**
 * Data source for the UsersTable view. This class should
 * encapsulate all logic for fetching and manipulating the displayed data
 * (including sorting, pagination, and filtering).
 */
export class LogsTableDataSource extends DataSource<LogModel> {

  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();
  public paginator: MatPaginator;
  public sort: MatSort;
  public count = 0;
  public a2filter: A2filter;

  constructor(
    public dataService: PlateService,
  ) {
    super();
  }

  connect(): Observable <LogModel[]> {

    const dataMutations = [
      this.paginator.page,
      this.sort.sortChange,
      this.a2filter.filterChange,
    ];
    console.log("conectado");
    //Al recibir un evento de cambio, solicitamos datos
    return merge(...dataMutations).pipe(switchMap((qry) => {
      console.log("pide Datos");
      this.loadingSubject.next(true);

      if(qry['filterName'])this.paginator.firstPage();

      return this.dataService.query(this.paginator, this.sort, this.a2filter.filters)
      .then(response=>{
        this.count = response._meta.count;
        this.loadingSubject.next(false);
        return response.data;
      })
      .catch(err =>{
        this.loadingSubject.next(false);
        console.error("Order data Source Fail", err)
      })
    }));
  }

  /**
   *  Called when the table is being destroyed. Use this function, to clean up
   * any open connections or free any held resources that were set up during connect.
   */
  disconnect() {
    this.loadingSubject.complete();
  }

}
