import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-plate-search',
  templateUrl: './plate-search.component.html',
  styleUrls: ['./plate-search.component.scss']
})
export class PlateSearchComponent implements OnInit {

  @Input() filterForm: FormGroup = null;
  @Input() validationMessages: {} = null;

  @Output() submitter: EventEmitter<any> = new EventEmitter;
  // @Output() errMsg: EventEmitter<any> = new EventEmitter;

  // validationMessages = {
  //   numberPlate : [ {type: 'required', message: 'Matrícula vacía'}, {type: 'minLength', message: 'Matrícula muy pequeña'}, {type: 'maxLength', message: 'Matrícula muy Larga'} ],
  //   message : [ {type: 'required', message: 'Mensaje vacío'}, {type: 'minLength', message: 'Mensaje corto'}, {type: 'maxLength', message: 'Mensaje Largo'} ]
  // };

  constructor() { }

  ngOnInit(): void {
  }


  onSubmit(){
    this.submitter.emit();
  }

  // getErrorMessage(control){
  //   // this.errMsg.emit(control);
  // }






  //Arregla el numberPlate y lo devuelve para su uso
  //**************
  normalize(numberPlate){
    let toClear = /[*]|[\ ]/g;
   return numberPlate.toUpperCase().replace(toClear, '');
  }
  //Para conocer los errores en el validator
  //**************
    getErrorMessage(group){
      //Se devuelve el primer Error que se consigue
      const control = this.getControlName(group);
      let errorMessage = "Error";
      if (this.validationMessages[control]){

        this.validationMessages[control].some((errorType) =>{

          if (group.hasError(errorType.type)) {
            errorMessage = errorType.message;
          }
          return group.hasError(errorType.type);
        });
      }

      return errorMessage;
    }
    getControlName(c: AbstractControl): string | null {
      const formGroup = c.parent.controls;
      return Object.keys(formGroup).find(name => c === formGroup[name]) || null;
    }
  //**************
}