export class UserModel {
  id: string;
  displayName: string;
  firstName: string;
  lastName: string;
  email: string;
  emailVerified: boolean;
  phoneNumber?: string;
  phoneNumberVerified: false;
  createdAt: Date;
  address?: string;
  location:Location;
  photoURL: string;
  uid: string;
  role?: string;
  regStatus: UserModel.RegStatus;
  disabled: boolean;
  provider:{
    // facebook.com:1278172
    // password:email
  }
}

export namespace UserModel{
  export enum RegStatus {
    Completed,
    Location,
    Name
  }
}


// user.disabled