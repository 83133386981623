import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AngularFirestoreCollection, AngularFirestore } from '@angular/fire/firestore';
import { Incidence } from '../models/incidence.model';
import { map } from 'rxjs/operators';
import { firestore, database } from 'firebase';
import { UserService } from '../core/user.service';
import { AngularFireStorage } from '@angular/fire/storage';
import { ElasticSearchService } from '@a2system/angular/common'; // } from '@a2system/angular/common';
import { GeocodingService } from '../shared/geocoding.service';
import { ValueTransformer } from '@angular/compiler/src/util';
import { VehicleModel } from '../models/vehicle.model';
import { UserModel } from '../models/user.model';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class IncidentsService {
  private collection: AngularFirestoreCollection<Incidence>;

  public incidentTypes: Array<any>;


  constructor(
    private db: AngularFirestore,
    private http: HttpClient,
    private userService: UserService,
    private storage: AngularFireStorage,
    private es: ElasticSearchService,
    private geocodingService: GeocodingService


  ) { 
    this.collection = this.db.collection<Incidence>('incidences', ref=> ref.orderBy('createdAt', 'desc'));
  }


  async loadData(){
    this.incidentTypes = await this.http.get<any>('./assets/data/incidents-type.json').toPromise();
  }
  getAll() {
    return this.collection.snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as Incidence;
        const id = a.payload.doc.id;
        return {id,  ...data };
      }))
    )
  }



  //_____________
  /**
   * @param persona el usuario a buscar
   * @param incidenceId el incidente
   * @returns el inbox del incidente de la persona 
   */
  getInboxByIncidence(persona, incidenceId){
    // this.db.collectionGroup('inbox') te llegarian desordenados
    console.log(persona, incidenceId);
    return this.db.collection('users').doc<UserModel>(persona.id)
    .collection('inbox', ref=> ref.where('idTypeRef','==', incidenceId))
    .get().pipe(
      map( doc => {
        return doc.docs[0].data();
      })
    )
  }


  //_____________
  delete(id: string){
    return this.collection.doc<Incidence>(id).delete();
  }

  upsert(data): Promise<any>{
    const id = data.id;
    delete data.id;
    
    if(!id){
      data.idSender = this.userService.userG.id,
      data.sender = this.userService.userG.displayName;
      data.createdAt = firestore.FieldValue.serverTimestamp();
    }
    data.updatedAt = firestore.FieldValue.serverTimestamp();

    return (id)
      ? this.collection.doc(id).update(data)
      : this.collection.add(data);
  }  

  create(data, image?, type?): Promise<any>{
    data.idSender = this.userService.userG.id,
    data.sender = this.userService.userG.displayName;
    data.createdAt = firestore.FieldValue.serverTimestamp();

    const id = this.db.createId();

    return Promise.resolve()
      .then(_=>{
        if(image){
          const filePath = `incidences/${id}/${data.idSender}.${type}`;
          return this.storage.ref(filePath).putString(image, 'data_url', {contentType: `image/${type}`})
          .then(res => res.ref.getDownloadURL());          
        } else return null;
      })
      .then(path=>{
        if(path) data.picture=path;
        return this.collection.doc(id).set(data, { merge: true })
      })
  }



  getIncidences(id) {
    console.log('id', id);
    const qry = this.db.collection<Incidence>('incidences', ref=> 
      ref.where('idSender','==', id)
      .orderBy('createdAt', 'desc'));
    return qry.snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as Incidence;
        const id = a.payload.doc.id;
        return {id,  ...data };
      }))
    )
  }  

  async query(paginator, sort, filter, options?){
    console.log('llego al query')
    let extras = {    
      
      createdAt:{
        type:"dateRange",
      },

      //Nested vehicles

      vehicles_idType:{
        nested:'vehicles'
      },
      vehicles_year:{
        nested:'vehicles'
      },
      vehicles_color:{
        nested:'vehicles',
      },
      vehicles_idBrand:{
        nested:'vehicles',
      },
      vehicles_numberPlate:{
        nested:'vehicles',
        type:'wildcard'
      },

      location:{
        nested:'location.address_components'
      },
      postal_code:{
        nested:'location.address_components'
      },
      locality:{
        nested:'location.address_components'
      },
      administrative_area_level_2:{
        nested:'location.address_components'
      },
      administrative_area_level_1:{
        nested:'location.address_components'
      },
      country:{
        nested:'location.address_components'
      },
    }

    filter = await this.genLocation(filter);
    filter = await this.normalize(filter);

    let _options:any=null;
    if (options?.body){
      _options ={
        body:true
      }

    }
    let body = this.es.mtToBody(paginator, sort, filter, extras) //convertimos los parámetros al formato de ElasticSearch
    
    console.log('query: ', body);
    return this.es.query('incidences',body, (item)=>{
      // //Transformamos todos los dates a Timestamp que es como lo utilizamos
      // item.orderDate = new firestore.Timestamp(item.orderDate._seconds, item.orderDate._nanoseconds);
      // //item.project.startDate = new firestore.Timestamp(item.project.startDate._seconds, item.project.startDate._nanoseconds);
      // item.items.deliveryDate = new firestore.Timestamp(item.items.deliveryDate._seconds, item.items.deliveryDate._nanoseconds);
      item.createdAt = new firestore.Timestamp(item.createdAt._seconds, item.createdAt._nanoseconds);

      return item;
    } )
  }

  async normalize(_filter){
    console.log('_filter',_filter);
    _filter.vehicles_numberPlate.map(np=>{
      np.value =  '*' + np.value.toUpperCase().replace(/[\ ]/g, '') + '*';
      // np.value = np.value.toUpperCase().replace(/[\ ]/g, '');
      console.log(np);
      return np;
    })
    console.log(_filter);
    return _filter;

  }
  async genLocation(_filter){
    let {location, ...filter} = _filter;
    let  types = ['postal_code', 'locality', 'administrative_area_level_2', 'administrative_area_level_1', 'country' ];

    for ( const _l of location ) {
      if(_l.value.id){
        const place:any = await this.geocodingService.getPlaceDetail(_l.value.id);
        // console.log('place', place);
        // console.error('3:', place.address_components);
        types.forEach(type=>{
          place.address_components.forEach(component=>{
            if (component.types.includes(type)){
              filter[type]=[{
                key: 'location.address_components.types',
                op: '===',
                value:type
              },{
                key: 'location.address_components.short_name',
                op: '===',
                value:component.short_name
              }
            ]
              //console.log('buscar:',{type, short_name: component.short_name })
            }
          })
        })

        // place.address_components
        // {short_name
        //  types:['postal_code', 'locality', 'administrative_area_level_2', 'administrative_area_level_1', 'country' ]
        // }
      }
    }

    return filter;
  }
}
