<div class="main-container" fxLayout="row" fxLayoutAlign="center center" >



  <div class="login-card mat-elevation-z8" fxLayout="row" >
    <div class="image-card" fxLayout="column" fxFlex="50">

    </div>
    <div  class="form-card" fxLayout="column" fxFlex="50" fxLayoutAlign="space-between stretch" >
      <div fxLayout="column" fxFlex="50" fxLayoutAlign="space-between center" >
        <img src="/assets/favicon/apple-touch-icon-152x152.png" alt="Athlas Logo " width="80">
        <h1>¿Olvidó su contraseña?</h1>
        <p>
          Escriba su correo electrónico y le enviaremos un enlace para que recupere su contraseña.
        </p>
      </div>

      <form [formGroup]="forgotForm" (ngSubmit)="submit()" fxFlex="40" fxLayout="column" fxLayoutAlign="space-evenly stretch">
        <mat-form-field>
          <input appAutoFocus matInput placeholder="Correo Electrónico"  name="email" type="email" required  formControlName="email" >
          <mat-error *ngIf="forgotForm.get('email').invalid "><mat-icon>info</mat-icon> {{getErrorMessage(forgotForm.get('email'))}}</mat-error>
        </mat-form-field>


        <span fxFlex="1 1 auto"></span>
        <button mat-raised-button color="primary" type="submit" >Enviar</button>
      </form>
    </div>

  </div>
</div>
