import { Injectable } from '@angular/core';
import * as moment from 'moment';
import 'moment-timezone';
import { ElasticSearchService } from '@a2system/angular/common'; // } from '../../shared/elastic-search.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(
    private es: ElasticSearchService,
  ) { }

  // *xa = cosas del pasado a comentar
  getData(from, to): Promise<any>{
    const range = this.buildDateRangeArguments(from, to)
    console.log("get Data range", range)
    console.log(to)
    // const min = moment(from).format("YYYY-MM-DD") + '||/M';
    // const max = moment(to).format("YYYY-MM-DD") + '||/M';
    range.histogram.field =  "createdAt._seconds";

    const filter = [ ];
    if ( range.range ) {
      filter.push({ "range": { "createdAt._seconds": range.range } });
    }
    // users, vehicles, incidences
    //user y incidence son iguales, vehicles tiene añadidos
    //HISTOGRAMAS
    let body = {
      "query": { "bool": { "filter": filter } },
      "aggs": {
        "by_date": {
          "date_histogram": range.histogram,
        }
      },
      "size": 0
    }

    let promiseArr = [
      this.getUsersData(body),
      this.getVehiclesData(body),
      this.getIncidencesData(body),
      this.getNoticesData(body),
      this.getLogsData(body),
      {rangeHistogram: range.histogram}
    ];
    return Promise.all(promiseArr).then( (arrAll: []) => {
      return arrAll.reduce((prev, val) => Object.assign(prev, val),{})
    });
  }

  getNoticesData(body): Promise<any>{
    return this.es.a2f({index:'notices', body})
    .then((result:any)=>{
      const totalNotices = result.hits.total.value 
      return {totalNotices}
    })
  }
  getLogsData(body): Promise<any>{
    return this.es.a2f({index:'search_logs', body})
    .then((result:any)=>{
      const totalLogs = result.hits.total.value 
      return {totalLogs}
    })
  }
  getUsersData(body): Promise<any>{

    return this.es.a2f({index:'users', body})
    .then((result:any)=>{
      //USER
      /*
        A) - Contador:   total usuarios registrados
        F) - Histograma: usuarios registrados
      */
      const totalUsers = result.hits.total.value // (A)
      const usersByDate = result.aggregations.by_date.buckets.map(bucket=>{
        return [bucket.key_as_string, bucket.doc_count];
      });// (F)
      return {totalUsers, usersByDate}
    })
  }

  getVehiclesData(body): Promise<any>{

    body.aggs['by_brand'] = {
      "terms": {
        "field": "idBrand",
        "size": 10
      },
      "aggs": {
        "id_name": {
          "top_hits": {
            "_source": "brand",
            "size" : 1
          }
        }
      }
    }
    body.aggs['by_type'] = {
        "terms": {
          "field": "idType",
          "size": 10
        },
        "aggs": {
          "id_name": {
            "top_hits": {
              "_source": "type",
              "size" : 1
            }
          }
        }
    }
    return this.es.a2f({index:'vehicles', body})
    .then((result:any)=>{
      //VEHICLES
      /*
        B) - Contador:   total vehículos registrados
        G) - Histograma: vehículos registrados
        I) - Pie Chart:  Tipos de vehículos
        J) - Pie Chart:  Marca vehículos
        K) - Pie Chart: inicdencias
      */
      const vehiclesTotal = result.hits.total.value // (B)
      const vehiclesByDate =  result.aggregations.by_date.buckets.map(bucket=>{
        return [bucket.key_as_string, bucket.doc_count]
      })  // (G)
      const vehiclesType = result.aggregations.by_type.buckets.map(bucket=>{
        return [bucket.id_name.hits.hits[0]._source.type, bucket.doc_count];
      });// (I)
      const vehiclesBrand = result.aggregations.by_brand.buckets.map(bucket=>{
        return [bucket.id_name.hits.hits[0]._source.brand, bucket.doc_count];
      })// (J)

      

      return {vehiclesTotal, vehiclesByDate, vehiclesType, vehiclesBrand }
    })
  }


  getIncidencesData(body): Promise<any>{

    return this.es.a2f({index:'incidences', body})
    .then((result:any)=>{
      //INCIDENCES
      /*
        C) - Contador:   total incidencias reportadas
        H) - Histograma: incidencias reportadas
      */
      const incidencesTotal = result.hits.total.value // (C)
      const incidencesByDate = result.aggregations.by_date.buckets.map(bucket=>{
        return [bucket.key_as_string, bucket.doc_count];
      });// (H)

      const incidenceType = result.aggregations.by_type.buckets.map(bucket=>{
        return [bucket.id_name.hits.hits[0]._source.type, bucket.doc_count];
      })// (K)


      return {incidencesTotal, incidencesByDate ,incidenceType}
    })
  }






  bkgetData(from, to): Promise<any>{
    const range = this.buildDateRangeArguments(from, to)
    console.log("range", range)
    console.log(to)
    const min = moment(from).format("YYYY-MM-DD") + '||/M';
    const max = moment(to).format("YYYY-MM-DD") + '||/M';
    range.histogram.field =  "checkIn._seconds";

    const filter = [
      { "range":
      {
        "checkIn._seconds": range.range
        //  {  // <<-- sustituir por el campo de fecha a filtrar (normalmente checkIn._seconds o createdAt._seconds)
        //   "format": "yyyy-MM-dd",   // <<-- literal (varias opciones, ver comentario abajo)
        //   "time_zone": "Europe/Madrid",   // <<-- literal, debería salir de los settings de cliente?
        //   "gte": min,   // <<-- fecha inicio, redondeado abajo a:  /d (día), /w (semana), /M (mes), /y (year)
        //   "lte": max   // <<-- fecha fin, redondeado arriba a:  /d (día), /w (semana), /M (mes), /y (year)
        // }
      } }
    ]

    const body = {
      "query": { "bool": { "filter": filter } },
      "aggs": {
        "visits_by_headquarter": {  // <<-- H) - Pie Chart:  visits by headquarter
          "terms": {
            "field": "headquarter.id"
          },
          "aggs": {
            "visitors_by_headquarters": {  // <<-- D) - Contadores: visitors by headquarter
              "cardinality": {
                "field": "visitor.id"
              }
            }
          }
        },
        "total_visitors": {  // <<-- A) - Contadores: total visitors
          "cardinality": {
            "field": "visitor.id"
          }
        },
        "visits_by_date": {  // <<-- G) - Histograma: visits by date
          "date_histogram": range.histogram
          // {
          //   "field": "checkIn._seconds",
          //   "calendar_interval": "1M",
          //   "format": "yyyy-MM-dd",
          //   "time_zone": "Europe/Madrid",
          //   "extended_bounds": {
          //     "min": min,
          //     "max": "now/M"
          //   }
          // }
        },
        "no_checkout_visits": {
          "missing": { "field": "checkOut._seconds" },
          "aggs": {
            "visits_by_date": {  // <<-- I) - Histograma: visits without checkout by date
              "date_histogram": range.histogram
              // {
              //   "field": "checkIn._seconds",
              //   "calendar_interval": "1M",
              //   "format": "yyyy-MM-dd",
              //   "time_zone": "Europe/Madrid",
              //   "extended_bounds": {
              //     "min": min,
              //     "max": "now/M"
              //   }
              // }
            },
            "visits_by_headquarter": {  // <<-- J) - Pie Chart:  visits without checkout by headquarter
              "terms": {
                "field": "headquarter.id"
              }
            }
          }
        }
      },
      "size": 0
    }

    const index = 'visits';
    return this.es.a2f({index, body})
    .then((result:any)=>{
      console.log("Result", result);
      console.log(range.histogram);
      const visitsByDate = result.aggregations.visits_by_date.buckets.map(bucket=>{
        return [bucket.key_as_string, bucket.doc_count];
      });
      const visitsByHeadquarter = result.aggregations.visits_by_headquarter.buckets.map(bucket=>{
        return [bucket.key, bucket.doc_count];
      });
      const noCheckout = result.aggregations.no_checkout_visits.visits_by_date.buckets.map(bucket=>{
        return [bucket.key_as_string, bucket.doc_count];
      });
      const noCheckoutByHeadquarter = result.aggregations.no_checkout_visits.visits_by_headquarter.buckets.map(bucket=>{
        return [bucket.key, bucket.doc_count];
      });

      const rangeHistogram = range.histogram;
      const totalVisitors = result.aggregations.total_visitors.value;
      const totalVisits = result.hits.total.value;
      const totalVisitsWoCheckout = result.aggregations.no_checkout_visits.doc_count;
      const visitorsByHeadquarter = result.aggregations.visits_by_headquarter.buckets.map(bucket=>{
        return [bucket.key, bucket.visitors_by_headquarters.value];
      });
      return {visitsByDate, visitsByHeadquarter, noCheckout, noCheckoutByHeadquarter, totalVisitors, totalVisits, totalVisitsWoCheckout, visitorsByHeadquarter, rangeHistogram}
    })
  }

  // _start?: Date || Moment  (default: start of time)
  // _end?: Date || Moment (default: now)
  // timezone: string (default: 'Europe/Madrid')
  // returns: { range?, histogram }, to use in ES queries
  buildDateRangeArguments(_start = undefined, _end = undefined, timezone = 'Europe/Madrid') {
    const start = _start ? moment(_start).tz(timezone) : moment().tz(timezone).subtract(12, 'months');
    const end = moment(_end || undefined).tz(timezone);
  ​
    let range:any;
    let histogram = { } as any;
    let group = 'M';  // grouping: /h, /d, /w, /M, /y  (defalut: by months)

    const validDates = start.isValid( ) && end.isValid( );
    
    if ( validDates ) {
      // take to start and end of rage to calculate diff correctly
      start.startOf('day');
      end.endOf('day');

      range = { };
      // fill in range
      range.format = 'yyyy-MM-dd'; // ES format
      range.time_zone = timezone;
      range.gte = start.format('YYYY-MM-DD[||/d]'); // always start of date
      range.lte =   end.format('YYYY-MM-DD[||/d]'); // always end of date

      // find grouping
      const days = end.diff(start, 'days');
      if ( days < 3 * 1 )        group = 'h';  // group by hours (3 days)
      else if ( days < 5 * 7 )   group = 'd';  // group by days (5 weeks)
      else if ( days < 4 * 30 )  group = 'w';  // group by weeks (4 months)
      else if ( days < 3 * 365 ) group = 'M';  // group by months (3 years)
      else                       group = 'y';  // group by year
    }
  ​
    // fill in histogram
    histogram.time_zone = timezone;
    histogram.calendar_interval = '1' + group;
    histogram.extended_bounds = validDates ? { } : undefined;
  ​
    switch ( group ) {
      case 'h': {
        histogram.format = 'yyyy-MM-dd HH\'h\''; // ES format
        if ( histogram.extended_bounds ) {
          histogram.extended_bounds.min = start.format('YYYY-MM-DD[ 00h]');
          histogram.extended_bounds.max =   end.format('YYYY-MM-DD[ 23h]');
        }
      } break;
      case 'd': case 'w': { // keep whole day format
        histogram.format = 'yyyy-MM-dd';
        if ( histogram.extended_bounds ) {
          histogram.extended_bounds.min = start.format('YYYY-MM-DD');
          histogram.extended_bounds.max =   end.format('YYYY-MM-DD');
        }
      } break;
      case 'M': {
        histogram.format = 'yyyy-MM';
        if ( histogram.extended_bounds ) {
          histogram.extended_bounds.min = start.format('YYYY-MM');
          histogram.extended_bounds.max =   end.format('YYYY-MM');
        }
      } break;
      case 'y': default: {
        histogram.format = 'yyyy';
        if ( histogram.extended_bounds ) {
          histogram.extended_bounds.min = start.format('YYYY');
          histogram.extended_bounds.max =   end.format('YYYY');
        }
      } break;
    }
  ​
    return { range, histogram };
  }
}
