import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { A2filter } from '@a2system/angular/common'; // } from '../shared/a2filter';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-logs',
  templateUrl: './logs.component.html',
  styleUrls: ['./logs.component.scss']
})
export class LogsComponent implements OnInit {

  filterForm = new FormGroup ({
    email: new FormControl(''),
    numberPlate: new FormControl(''),
    // found: new FormControl(''),
    createdAt: new FormControl(''),
  });

  a2filter =  new A2filter(this.filterForm);

  constructor(
    public translate: TranslateService,
  ) { }
  ngOnInit(): void {}
}