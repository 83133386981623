import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { first, map, distinctUntilChanged, switchMap, take } from 'rxjs/operators';
import { AuthService } from './auth/auth.service';
import { FirebaseError, firestore, database } from 'firebase/app';
import { AngularFirestoreCollectionGroup, AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { AngularFireFunctions } from '@angular/fire/functions';
import { UserModel } from '../models/user.model';
import { ElasticSearchService } from '@a2system/angular/common'; // } from '@a2system/angular/common';
import { GeocodingService } from '../shared/geocoding.service';
import { exists } from 'fs';

@Injectable({
  providedIn: 'root'
})
export class UserService {





  public userG;
  constructor(
    private authService: AuthService,
    private db: AngularFirestore,
    private storage: AngularFireStorage,
    private es: ElasticSearchService,
    private functions: AngularFireFunctions,
    private geocodingService: GeocodingService,
  ) {
    this.user.subscribe(user => this.userG=user);
  }
  get user(): Observable<any> {
    // TODO ESTO SE DEBE VERIFICARSE
    console.log("user.service...")
    return this.authService.authState$.pipe(
      switchMap(user => {
        // console.log("--->Atencion User....>")
        // console.log(user);
        // console.log("------");
        return !user
               ? [{
                  id: null,
                  photoURL: './assets/images/blank-profile.png',
                  displayName: 'Unknow User'
                 }]
               : this.db
                  .collection('users', ref => ref.where('uid', '==', user.uid))
                  .valueChanges({ idField: 'id' }) //usar get() en vez de valueChange, así corta el stream, y doc(id) por que buscamos solo 1  Coloca los valores con el id
                  .pipe(map(arr => arr[0])) // Sacamos el primer valor que es el que nos interesa
      })
    );
  }

  setUser(data, image?){
    return Promise.resolve()
      .then(() => {
        if (image) {
          return this.uploadImage('users/picture/', image, data.id)
            .then(path => data.photoURL = path);
        }
      })
      .then(() => this.db.collection('users').doc(data.id).update(data));
  }


  createUser(data) {
    return this.db.collection('users').add(data);
  }

  uploadImage(path, image, name){
    return this.storage.ref(`${path}${name}`)
    .putString(image, 'data_url', {contentType: 'image/*'})
    .then(res =>  res.ref.getDownloadURL());
  }

  /** upsertUserLogin
   * @summary Verifica si ya existe por el uid.
   * Si existe actualiza ciertos valores.
   * Si no existe agrega el usuario a Users
   */
  upsertUserLogin(data) {
    console.log(data.uid);
    return this.db.collection('users', ref => ref.where('uid', '==', data.uid).limit(1))
      .valueChanges({ idField: 'id' })
      .pipe(first(), map(arr => arr[0]))
      .toPromise()
      .then(user => {
        if (!user) {
          this.db.collection('users').add({...data});
        } else {
          const uData: any = {};
          if (data.phoneNumber) { uData.phoneNumber = data.phoneNumber; }
          uData.emailVerified = data.emailVerified;

          this.db.collection('users').doc(user.id).update(uData);
        }
      });
  }
  // Lugar scripts Xavier
  //____________________________________________________________//

    getUser(id): Observable<UserModel>{
      return this.db.collection<UserModel[]>('users').doc(id).get()
        .pipe(
          map(doc => {
            const id= doc.id;
            const data = doc.data() as UserModel
            return {id, ...data};
          })
        )
    }
    onChange(): Observable<any> {
      return this.db
        .collection<any>('refresh')
        .doc('users')
        .valueChanges();
    }
    /**
     * @summary permita activar o desactivar usuarios para la app
     * @param id
     * @param uid
     * @param disable
     */
    disableUser(id, uid, disable){

      let statusUser = {
        id,
        uid: uid,
        disable: disable
      }
      return  this.functions.httpsCallable('disableUser')(statusUser).toPromise();
    }

  //____________________________________________________________//

  doSignup(value){
    const {email, password, ...data} = value;
    return this.authService.doRegister({email, password})
      .then(user => {
        return this.createUser({...user, ...data});
      })
      .catch();
  }

  doSignupGoogle() {
    //return this.authService.goPopupLogin('google.com')
    return this.authService.doLogin('google.com')
      .then(user => {
        return this.upsertUserLogin( user );
      })
      .catch();
  }

  doSignupFacebook() {
    //return this.authService.goPopupLogin('facebook.com')
    return this.authService.doLogin('facebook.com')
      .then(user => {
        return this.upsertUserLogin( user );
      })
      .catch();
  }


  getAdminUsers(){
    return this.db.collection(
      'users', ref =>
      ref.where('role', '>', '')
    )
    .snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as UserModel;
        const id = a.payload.doc.id;
        return {id,  ...data };
      }))
    )
  }

  updatePassword(password){
    console.log("@@Update Psw", this.userG);
    let data = {
      uid: this.userG.uid,
      role: this.userG.role,
      password,
    }
    return  this.functions.httpsCallable('updateUser')(data).toPromise();
  }
  updateAdminUser(user){
    delete user.id
    console.log("update user", user)
    return  this.functions.httpsCallable('updateUser')(user).toPromise();
  }
  createAdminUser(user){
    console.log("create user", user)
    return  this.functions.httpsCallable('createUser')(user).toPromise();
  }
  deleteAdminUser(user){
    user.role = null;
    return  this.functions.httpsCallable('updateUser')(user).toPromise();
  }
  deleteUserUser(user){
    user.role = null;
    return  this.functions.httpsCallable('deleteUser')(user).toPromise();
  }


////Elastic Search Section
  async query(paginator, sort, filter, options?){
  
    let extras = {

      createdAt:{
        type:"dateRange",
      },
      vehicles_idModel:{
        nested:"vehicles"
      },
      vehicles_idType:{
        nested:"vehicles"
      },
      vehicles_year:{
        nested:"vehicles"
      },
      vehicles_color:{
        nested:"vehicles"
      },
      vehicles_idBrand:{
        nested:"vehicles"
      },
      vehicles_numberPlate:{
        nested:"vehicles",
        type:"wildcard"
      },
      location:{
        nested:"location.address_components"
      },
      postal_code:{
        nested:"location.address_components"
      },
      locality:{
        nested:"location.address_components"
      },
      administrative_area_level_2:{
        nested:"location.address_components"
      },
      administrative_area_level_1:{
        nested:"location.address_components"
      },
      country:{
        nested:"location.address_components"
      },
    }
    // console.log("Filters antes:", filter);
    filter = await this.genLocation(filter);
    filter = await this.normalize(filter);
    // console.log("Filters:", filter);
    let _options:any=null;
    if (options?.body){
      _options ={
        body:true
      }

    }
    let body = this.es.mtToBody(paginator, sort, filter, extras) //convertimos los parámetros al formato de ElasticSearch
    // console.log("Body: ", body);
    return this.es.query("users",body, (item)=>{
      // //Transformamos todos los dates a Timestamp que es como lo utilizamos
      // item.orderDate = new firestore.Timestamp(item.orderDate._seconds, item.orderDate._nanoseconds);
      item.createdAt = new firestore.Timestamp(item.createdAt._seconds, item.createdAt._nanoseconds);
      // item.items.deliveryDate = new firestore.Timestamp(item.items.deliveryDate._seconds, item.items.deliveryDate._nanoseconds);
      return item;
      }, _options)
    // })
  }
  async normalize(_filter){
    console.log("_filter",_filter);
    // _filter.vehicles_numberPlate.map(np=>{
    _filter.vehicles_numberPlate.map(np=>{
      console.log(np);
      np.value =  '*' + np.value.toUpperCase().replace(/[\ ]/g, '') + '*';
      // np.value =  '*' + np.value.toUpperCase().replace(/[\ ]/g, '') + '*';
      console.log(np.value);
      return np;
    })
    return _filter;

  }

  async genLocation(_filter){
    let {location, ...filter} = _filter;
    let  types = ["postal_code", "locality", "administrative_area_level_2", "administrative_area_level_1", "country" ];

    for ( const _l of location ) {
      if(_l.value.id){
        const place:any = await this.geocodingService.getPlaceDetail(_l.value.id);
        // console.log("place", place);
        // console.error("3:", place.address_components);
        types.forEach(type=>{
          place.address_components.forEach(component=>{
            if (component.types.includes(type)){
              filter[type]=[{
                key: "location.address_components.types",
                op: "===",
                value:type
              },{
                key: "location.address_components.short_name",
                op: "===",
                value:component.short_name
              }
            ]
              //console.log("buscar:",{type, short_name: component.short_name })
            }
          })
        })

        // place.address_components
        // {short_name
        //  types:["postal_code", "locality", "administrative_area_level_2", "administrative_area_level_1", "country" ]
        // }
      }
    }

    return filter;
  }

}
