

<ng-container  [ngTemplateOutlet]="this[this.type] || test" [ngTemplateOutletContext]="{$implicit: filter}"></ng-container>





<ng-template #lowerCase let-filter>
  <form *ngIf="form" [formGroup]="form">
    <mat-form-field  appearance="outline" [ngClass]="type">
      <mat-label>{{label}}</mat-label>
      <input matInput [placeholder]="placeholder" (keyup.enter)="this.a2filter.add(filter)" [formControlName]="filter">
      <ng-container [ngTemplateOutlet]="testTemplate" [ngTemplateOutletContext]="{$implicit: filter}"></ng-container>
    </mat-form-field>
  </form>
</ng-template>



<!-- solo se queja por que requiere un tempalte inicial, interesante... HACK (?-->
<ng-template #test let-filter></ng-template>




<!-- //_____ EXTRA TEMPLATES -->

<!-- A2 Chip filter Template -->
<ng-template #testTemplate let-filter>
  <app-chip-filter [filterName]="filter" [filters]="a2filter.filters[filter]" (chipRemoved)="a2filter.remove($event)">
  </app-chip-filter>
</ng-template>
