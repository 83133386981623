import { Injectable } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of, from } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';
import { UserService } from '../user.service';
import { UserModel } from '../../models/user.model';

@Injectable({
  providedIn: 'root'
})
export class GuardService implements CanActivate {


  constructor(private auth: AuthService, private userService: UserService, private router: Router) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | boolean {
      console.log('canActivate', state);
      return this.auth.authState$.pipe(
        switchMap((user): Observable<boolean>  =>{
          if(!user) {
            this.router.navigate(['login']);
            return of(false);
          }
          return from(user.getIdTokenResult()).pipe(
            map( (datos:any) => {
              console.log("Guard Services claims: ", datos)
              if(datos.claims && datos.claims.role){
                return this.checkAccess( datos.claims.role, state.url)
                // console.log("que si puede entrar.....");
                //return true;
              }else{
                this.router.navigate(['login']);
                return false;
              }
            })
          )
        })
      )
      // return this.userService.user
      //   .pipe(
      //     first(),
      //     map(user => {
      //       console.log("user", user);
      //       if (user.id) {
      //         return true;
      //       }
      //       else{
      //         console.log("vamos a login");

      //         this.router.navigate(['login']);
      //         return false
      //       }

      //     })

      //   )
      // return this.auth.currentUser$
      //     .pipe(
      //      first(),
      //      map(user => {
      //        if (user) {
      //          console.log('User canActivate', user);
      //          return true;
      //        } else {
      //          this.router.navigate(['auth/login']);
      //          return false;
      //        }
      //      }),
      //     // map(user => !!user),
      //     //  do(loggedIn => {
      //     //   if (!loggedIn) {
      //     //     console.log("access denied")
      //     //     this.router.navigate(['/login']);
      //     //   }else{
      //     //     console.log("esta logueado");
      //     //   }
      //     //   })

      //     )
  }

  checkAccess(role, url){
    let canAccess = true;
    switch (url) {
      case '/notices':
      case '/incidences':
      case '/home':
        canAccess = ['admin','p2'].includes(role);
        if(!canAccess){
          this.router.navigate(['/plates']);
        }
        break;
      case '/users-admin':
      case '/vehicles':
      case '/logs':
        canAccess = ['admin'].includes(role);
        if(!canAccess){
          this.router.navigate(['/plates']);
        }
        break;


      default:
        break;
    }
    console.log("@role", role);
    console.log("@url", url);
    console.log("canAccess", canAccess);

    return canAccess;
  }
}
