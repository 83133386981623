<div class="mat-elevation-z8 card-table-container">

  <mat-toolbar fxLayoutAlign="space-between center">
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
      <ng-container *ngIf="dataSource">
        <mat-spinner diameter="20" *ngIf="dataSource.loading$ | async"></mat-spinner>
      </ng-container>

      <span>Usuarios</span>
      <span class="mat-small">{{dataSource?.count}} Total</span>
    </div>
    <div fxLayout="row" fxLayoutAlign="none center" fxLayoutGap="10px" class="mat-small">
      <ng-container *ngIf="!isLoading; else Loading">
        <!-- No hay url -->
        <button *ngIf="url == null" mat-stroked-button color="primary" (click)="report()">
          Descargar Reporte
        </button>
        <!-- si hay url -->
        <button *ngIf="url != null" mat-icon-button (click)="report()">
          <mat-icon>get_app</mat-icon>
        </button>
      </ng-container>
      <ng-template #Loading>
        <mat-spinner diameter="20"></mat-spinner>
      </ng-template>
    </div>
  </mat-toolbar>
  <!-- <button mat-button (click)="refresh()">Refresh</button> -->
  <table mat-table class="full-width-table" matSort
    matSortStart="desc"
    matSortDisableClear="true"
    matSortActive="createdAt._seconds"
    matSortDirection="desc"
    aria-label="Elements">


    <!-- Id Column -->
    <!-- <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef>Id</th>
      <td mat-cell *matCellDef="let row">{{row.id}}</td>
    </ng-container> -->

    <!-- Photo Column -->
    <ng-container matColumnDef="photoURL">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let row">
        <img class="avatar-cover" src="{{row.photoURL
        ? row.photoURL
        : '/assets/images/blank-profile.png' }}">
      </td>
    </ng-container>

    <!-- FirstName Column -->
    <ng-container matColumnDef="firstName">
      <th mat-header-cell *matHeaderCellDef>Nombre</th>
      <td mat-cell *matCellDef="let row">{{row.firstName}}</td>
    </ng-container>

    <!-- LastName Column -->
    <ng-container matColumnDef="lastName">
      <th mat-header-cell *matHeaderCellDef>Apellido</th>
      <td mat-cell *matCellDef="let row">{{row.lastName}}</td>
    </ng-container>

    <!-- Email Column -->
    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef>Correo</th>
      <td mat-cell *matCellDef="let row">{{row.email}}</td>
    </ng-container>

    <!-- Vehicles Column -->
    <ng-container matColumnDef="vehicles">
      <th mat-header-cell *matHeaderCellDef>Vehículos</th>
      <td mat-cell *matCellDef="let row">
        <span *ngFor="let vehicle of row.vehicles">
        <!-- <span><b>{{vehicle.type  | translate}}</b></span> -->
        <span><b>{{vehicle?.idType == '-1'  ? 'Otro: (' + ("VEHICLE_TYPE."+vehicle.typeOther | translate) + ')' : ('type' | otherChkr: vehicle) }} </b></span>
        

          {{'brand' | otherChkr: vehicle}}
          {{'model' | otherChkr: vehicle}}
          {{'color' | otherChkr: vehicle}}
          {{'year' | otherChkr: vehicle}},
          <!-- {{vehicle.brand}} {{vehicle.model}},  -->
          <!-- {{vehicle.year}},  -->
          <!-- {{"COLOR." + vehicle.color | translate }},  -->
          ({{vehicle.numberPlate}})
          <br>
        </span>
        <span *ngIf="!row.vehicles || row.vehicles.length == 0"> - </span>
      </td>
    </ng-container>

    <!-- address Column -->
    <ng-container matColumnDef="address">
      <th mat-header-cell *matHeaderCellDef>Ubicación</th>
      <td mat-cell *matCellDef="let row">

        {{row.location
          ? (
            (getAddressComponent(row.location.address_components, 'administrative_area_level_1'))
            + " " + (getAddressComponent(row.location.address_components, 'locality'))
            + " " +(getAddressComponent(row.location.address_components, 'postal_code'))
            )
          : '-'
        }}

      </td>
    </ng-container>

    <!-- Banned Column -->
    <ng-container matColumnDef="disabled">
      <th mat-header-cell *matHeaderCellDef>Desactivado</th>
      <td mat-cell *matCellDef="let row">
          {{
            row.disabled
            ? 'Sí'
            :'No'}}
        <!-- truthy -->
        <!-- {{row.disabled == true ? 'Si' : 'No'}} -->
      </td>
    </ng-container>

    <!-- Phone Column -->
    <ng-container matColumnDef="phoneNumber">
      <th mat-header-cell *matHeaderCellDef>Teléfono</th>
      <td mat-cell *matCellDef="let row">
        {{row.phoneNumber ? row.phoneNumber : '-'}}
      </td>
    </ng-container>

    <!-- CreatedAt Column ULTIMA COLUMNA-->
    <ng-container matColumnDef="createdAt">
      <th mat-sort-header="createdAt._seconds" mat-header-cell *matHeaderCellDef>Fecha</th>
      <td mat-cell *matCellDef="let row">
        <div>
          {{row.createdAt.toDate() | date:'d/M/yy, H:mm' }} h
        </div>
      </td>
    </ng-container>

    <!-- actions  -->
    <ng-container matColumnDef="actions" stickyEnd>
      <th mat-header-cell *matHeaderCellDef>  </th>
      <td mat-cell *matCellDef="let row" (click)="$event.stopPropagation()">
        <mat-menu #appMenu="matMenu">
          <button mat-menu-item (click)="disableUser(row);">
            <!-- si no esta el campo, significa false, si esta, si es true es que esta disabled -->
          {{
            row.disabled
            ? 'Activar Usuario'
            :'Desactivar Usuario'}}
          </button>
          <button mat-menu-item color="warn" (click)="deleteUser(row);">
            Eliminar Usuario
          </button>
        </mat-menu>
        <button mat-icon-button [matMenuTriggerFor]="appMenu">
          <mat-icon>more_vert</mat-icon>
        </button>
        <!-- <button mat-icon-button >
          <mat-icon>more_vert</mat-icon>
        </button> -->
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

  </table>

  <mat-paginator #paginator [length]="dataSource?.count" [pageIndex]="0" [pageSize]="15"
    [pageSizeOptions]="[15, 25, 50, 100, 250]">
  </mat-paginator>
</div>
