<div  class="body-container">

<!-- <div *ngIf="afAuth.user | async as user; else showLogin"> -->
<mat-toolbar *ngIf="authService.role ;" color="primary" >
  <!-- <span>avisacar</span> -->
  <!--  a los 1000px de width ya no encoge más -->
  <span style="margin-right:100px;"  >avisacar</span>

  <div fxLayout="row">
    <button *ngxPermissionsOnly="['admin', 'p2']"  routerLinkActive="mat-warn" mat-button routerLink="home">Panel</button>
    <button *ngxPermissionsOnly="['admin']"        routerLinkActive="mat-warn" mat-button routerLink="users">Usuarios</button>
    <button *ngxPermissionsOnly="['admin']"        routerLinkActive="mat-warn" mat-button routerLink="vehicles">Vehículos</button>
    <button *ngxPermissionsOnly="['admin', 'p2']"        routerLinkActive="mat-warn" mat-button routerLink="incidences">Incidencias</button>
    <button *ngxPermissionsOnly="['admin', 'p2']"        routerLinkActive="mat-warn" mat-button routerLink="notices">Avisos</button>
    <button *ngxPermissionsOnly="['admin', 'p2', 'p3', 'p4']"  routerLinkActive="mat-warn" mat-button routerLink="plates">Matrícula</button>
    <button *ngxPermissionsOnly="['admin']"        routerLinkActive="mat-warn" mat-button routerLink="logs">Búsquedas</button>
  </div>

  <span class="fill-remaining-space"></span>

  <div fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="space-between center"  >
    <div fxLayout="column" fxLayoutAlign="center end"  fxLayoutGap="0px">
      <div fxLayout="row" fxLayoutAlign="center center" >
        <mat-icon>account_circle</mat-icon> <span class="limit-text"> {{user?.firstName}} {{user?.lastName}}</span>
      </div>
      <div class="mat-small">v {{version}}</div>
    </div>
    <mat-menu #appMenu="matMenu">
      <button *ngxPermissionsOnly="['admin']" mat-menu-item routerLink="users-admin">Perfiles</button>
      <button mat-menu-item (click)="chgPassword()">Cambiar contraseña</button>
      <button mat-menu-item (click)="logout()">Salir</button>
    </mat-menu>
    <button mat-icon-button [matMenuTriggerFor]="appMenu">
      <mat-icon>more_vert</mat-icon>
    </button>
  </div>

</mat-toolbar>
  <router-outlet></router-outlet>
</div>
<!-- *ngIf="userService.userG && userService.userG.role == 'admin'" -->

<!-- <ng-template #showLogin>
  <app-login></app-login>
</ng-template> -->
