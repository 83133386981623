import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AngularFirestoreCollection, AngularFirestore } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';
import { firestore, database } from 'firebase';
import { AngularFireStorage } from '@angular/fire/storage';
import { ElasticSearchService } from '@a2system/angular/common'; // } from '@a2system/angular/common';

import { UserService } from '../core/user.service';
import { NoticeModel } from '../models/notice.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NoticesService {
  private collection: AngularFirestoreCollection<NoticeModel>;

  constructor(
    private db: AngularFirestore,
    private http: HttpClient,
    private userService: UserService,
    private storage: AngularFireStorage,
    private es: ElasticSearchService

  ) {
    this.collection = this.db.collection<NoticeModel>('notices', ref=> ref.orderBy('createdAt', 'desc'));
  }


  // async loadData(){
  //   this.incidentTypes = await this.http.get<any>('./assets/data/incidents-type.json').toPromise();
  // }

  getAll() {
    return this.collection.snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as NoticeModel;
        const id = a.payload.doc.id;
        return {id,  ...data };
      }))
    )
  }

  delete(id: string){
    return this.collection.doc<NoticeModel>(id).delete();
  }

  upsert(data): Promise<any>{
    const id = data.id;
    delete data.id;

    if(!id){
      data.idSender = this.userService.userG.id,
      data.sender = this.userService.userG.displayName;
      data.createdAt = firestore.FieldValue.serverTimestamp();
    }
    data.updatedAt = firestore.FieldValue.serverTimestamp();

    return (id)
      ? this.collection.doc(id).update(data)
      : this.collection.add(data)
    ;
  }

  onChange(): Observable<any> {
    return this.db
      .collection<any>('refresh')
      .doc('notices')
      .valueChanges();
  }

  getNotices(id) {
    console.log('@@@___id', id);
    const qry = this.db.collection<NoticeModel>('notices', ref=>
      ref.where('idSender','==', id)
      .orderBy('createdAt', 'desc'));
    return qry.snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as NoticeModel;
        const id = a.payload.doc.id;
        return {id,  ...data };
      }))
    )
  }

  query(paginator, sort, filter){
    let extras = {

      createdAt:{
        type:"dateRange",
      },
      
      // vehicles_idModel:{
      //   nested:"vehicles"
      // },
      // vehicles_idType:{
      //   nested:"vehicles"
      // },
      // vehicles_year:{
      //   nested:"vehicles"
      // },
      // vehicles_color:{
      //   nested:"vehicles"
      // },
      // vehicles_idBrand:{
      //   nested:"vehicles"
      // },
      // vehicles_numberPlate:{
      //   nested:"vehicles",
      //   type:"wildcard"
      // },
    }

    let body = this.es.mtToBody(paginator, sort, filter, extras)

    console.log("query: ", body);
    return this.es.query("notices",body, (item)=>{
      // //Transformamos todos los dates a Timestamp que es como lo utilizamos
      item.createdAt = new firestore.Timestamp(item.createdAt._seconds, item.createdAt._nanoseconds);
      // item.orderDate = new firestore.Timestamp(item.orderDate._seconds, item.orderDate._nanoseconds);
      // //item.project.startDate = new firestore.Timestamp(item.project.startDate._seconds, item.project.startDate._nanoseconds);
      // item.items.deliveryDate = new firestore.Timestamp(item.items.deliveryDate._seconds, item.items.deliveryDate._nanoseconds);
      return item;
    })
  }
}
