<mat-sidenav-container class="sidenav-container">
  <mat-sidenav attr.role='navigation'class="sidenav"mode="side" opened disableClose>
    <mat-toolbar>Filtros</mat-toolbar>
    <div fxLayout="column" fxLayoutAlign="start center" class="search-form">
      <!-- Filtro de notices -->
      <app-filters [filterForm]="filterForm" [a2filter]="a2filter" ></app-filters>
    </div>
  </mat-sidenav>
  <mat-sidenav-content class="sidenav-container">
    <div class="container-boxed">
      <!-- Tabla de notices -->
      <app-notice-table [a2filter]="a2filter"></app-notice-table>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>