<div fxFill fxLayout="column">
  <h1 mat-dialog-title>Crear Aviso</h1>

  <div fxLayout="row" fxLayoutAlign="start none">

    <div class="search-form" fxFlex="30">
      <div fxLayout="column" fxLayoutAlign="center " ><!--</div>class="v-scroll">-->
        <!-- Añadir el isLoading del disabling -->
        <app-filters [filterForm]="filterForm" [a2filter]="a2filter" ></app-filters>
      </div>
    </div>

    <!-- LINEA DIVISORA-->
    <div fxLayout="row" fxLayoutAlign="center none" fxFlex="10">
      <mat-divider vertical></mat-divider>
    </div>


    <!-- Form Mensajes -->
    <div fxFlex="60">

      <form id="notice" [formGroup]="noticeForm" (ngSubmit)="onSubmit()">

        <div fxLayout="column">

          <!-- COLOCAR AQUI EL CIRCULO -->
          <div fxLayout="row" fxLayoutAlign="start center">
            <ng-container *ngIf="isFinding">
              <mat-spinner diameter="20"></mat-spinner>
            </ng-container>
            &nbsp;
            <h3>Total Usuarios: {{count}}</h3>
          </div>

          <mat-form-field>
            <mat-label>Título del aviso</mat-label>
            <input  [disabled]="isLoading" matInput formControlName="titleControl" required>

            <mat-error *ngIf="noticeForm.get('titleControl').invalid ">
              <mat-icon>info</mat-icon> {{getErrorMessage(noticeForm.get('titleControl'))}}
            </mat-error>
          </mat-form-field>

          <br>
          <!-- <div>{{noticeForm.controls.messageControl.value}}</div> -->
          <br>
          
          <div [ngClass]="noticeForm.get('messageControl').invalid  && noticeForm.get('messageControl').touched ? 'quill-invalid' : ''">

              <quill-editor
              formControlName="messageControl" 
              placeholder="Ingresa un mensaje"
              [required]="true"
               >
                <div quill-editor-toolbar>

                  <span class="ql-formats">
                    <select class="ql-font">
                      <option selected></option>
                      <option value="serif"></option>
                      <option value="monospace"></option>
                    </select>
                    <select class="ql-size">
                      <option value="small"></option>
                      <option selected></option>
                      <option value="large"></option>
                      <option value="huge"></option>
                    </select>
                  </span>
                  <span class="ql-formats">
                    <button class="ql-bold"></button>
                    <button class="ql-italic"></button>
                    <button class="ql-underline"></button>
                    <button class="ql-strike"></button>
                  </span>
                  <span class="ql-formats">
                    <select class="ql-color"></select>
                    <select class="ql-background"></select>
                  </span>
                  <span class="ql-formats">
                    <button class="ql-list" value="ordered"></button>
                    <button class="ql-list" value="bullet"></button>
                    <select class="ql-align">
                      <option selected></option>
                      <option value="center"></option>
                      <option value="right"></option>
                      <option value="justify"></option>
                    </select>
                  </span>
                  <span class="ql-formats">
                    <button class="ql-link"></button>
                  </span>
                </div>
              </quill-editor>
              <mat-error *ngIf="noticeForm.get('messageControl').invalid  && noticeForm.get('messageControl').touched">
                <mat-icon >info</mat-icon> 
                {{getErrorMessage(noticeForm.get('messageControl'))}}
              </mat-error>
          </div>
        </div>
      </form>
    </div>
  </div>

  <!-- Botones -->
  <div mat-dialog-actions  fxLayoutAlign="end center">

    <button [disabled]="isLoading" mat-raised-button mat-dialog-close color="warn">Cancelar</button>
    &nbsp;
    <button [disabled]="noticeForm.invalid || isLoading || count == 0  || isLoading"  form="notice" mat-raised-button type="submit" color="primary">Enviar</button>
    <mat-spinner diameter="20" *ngIf="isLoading"></mat-spinner>
  </div>

  <!-- 
     <div fxFlex="1 1 auto"></div>
      <div class="footer" fxLayoutAlign="end center" fxLayoutGap="10px">
        <button type="button" [disabled]="isLoading" mat-button mat-raised-button color="warn" [mat-dialog-close]="true">
          Cancelar
        </button>

        <button mat-button [disabled]="isLoading" mat-raised-button color="primary">
          Guardar
        </button>

        <mat-spinner diameter="20" *ngIf="isLoading"></mat-spinner>
      </div>
   -->
</div>