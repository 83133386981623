import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from 'src/app/core/user.service';
import { VehiclesService } from 'src/app/vehicles/vehicles.service';

@Component({
  selector: 'app-plates-result',
  templateUrl: './plates-result.component.html',
  styleUrls: ['./plates-result.component.scss']
})
export class PlatesResultComponent implements OnInit {

  @Input() owners: [] = [] ;
  @Input() searched: "" = '';


  @Output() back: EventEmitter<any> = new EventEmitter;
  @Output() changeScr: EventEmitter<any> = new EventEmitter;

  canEditResident = false;
  constructor(
    private translateService: TranslateService,
    public userService : UserService,
    public dataService: VehiclesService,

  ) { }

  ngOnInit(): void {
    this.canEditResident = ['admin', 'p4'].includes(this.userService.userG.role);
    // console.log("this.userService.userG.role", this.userService.userG.role)
    // console.log("this.canEditResident", this.canEditResident)
  }

  clear(){
    this.back.emit();
  }

  changeScreen(){
    this.changeScr.emit();
  }


  //Puesto esto para manejar el translate de tipo de vehiculo, se podría ver una mejor resolución...
  ownerTypeOther(owner){
    const translate = this.translateService.instant("VEHICLE_TYPE."+owner.vehicle.typeOther)
    let p =  'Otro: (' + (translate) + ')' ;
    // console.log(p);// cosa a mano
    return p;
  }

  onChangeRes(properties){
    const {owner, property} = properties;
    const vehicle = owner.vehicle;
    vehicle[property]= !vehicle[property];
    if(property=='resident' && ! vehicle[property]){
      vehicle['aRes'] = false;
      vehicle['bRes'] = false;
      vehicle['cRes'] = false;
    }
    const {id, resident=false, aRes=false, bRes=false, cRes=false} = vehicle;
    console.log("Cambia", owner.vehicle, property)
    const res = this.dataService.updateVehicle(id, {
      resident,
      aRes,
      bRes,
      cRes
    })
  }
}

