import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, concatMap, first, filter, take } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { AngularFirestoreCollection, AngularFirestore } from '@angular/fire/firestore';
import { VehicleModel } from '../models/vehicle.model';
import { UserService } from '../core/user.service';
import { firestore } from 'firebase';
import { ElasticSearchService } from '@a2system/angular/common'; // } from '@a2system/angular/common';
import { GeocodingService } from '../shared/geocoding.service';
import { BrandModel } from '../models/brand.model';
import { ModelModel } from '../models/model.model';

@Injectable({
  providedIn: 'root'
})
export class VehiclesService {

  public vehicleBrands: Array<BrandModel>;
  public vehicleTypes: Array<any>;
  public vehicleColors: Array<any>;
  public myVehicles;
  public user: any;

  constructor(
    private http: HttpClient,
    private db: AngularFirestore,
    private userService: UserService,
    private es: ElasticSearchService,
    private geocodingService: GeocodingService


    ) {
    this.myVehicles = this.http.get<any>('./assets/data/my-vehicles.json');

    this.userService.user.subscribe(user => this.user=user);
  }

  async loadData(){
    this.vehicleTypes = await this.http.get<any>('./assets/data/vehicles-type.json').toPromise();

    // this.vehicleBrands = await this.http.get<any>('./assets/data/vehicles-brand.json').toPromise();
    this.db.collection<BrandModel>('brands').snapshotChanges()
      .pipe(map( actions => actions.map(a => {
        const data = a.payload.doc.data() as BrandModel;
        const id = a.payload.doc.id;
        return {id,  ...data};    
        }) ))
        .subscribe( brands => { 
          this.vehicleBrands = brands as BrandModel[]
          // console.log("Marcas : ",this.vehicleBrands);
    });


    // this.vehicleModels = await this.http.get<any>('./assets/data/vehicles-model.json').toPromise();
    this.vehicleColors = await this.http.get<any>('./assets/data/vehicle-colors.json').toPromise();
  }

  

  getVehicleBrand(brandId){
    return this.vehicleBrands.find(({id}) => id == brandId);
  }
  getVehicleType(typeId){
    return this.vehicleTypes.find(({id}) => id == typeId);
  }

  getMyVehicles(id): Observable<VehicleModel[]>{
    // let userId = this.user ? this.user.id : "UrTW6fIPpbzCksBEKv91";
    // console.log("UserG:", this.userService.userG);
    return this.db.collection<VehicleModel>(
      'vehicles',
      ref => ref.where('idUser', '==', id)
    )
    .snapshotChanges()
    .pipe(
      map(docs => docs.map(a => {
        const data = a.payload.doc.data() as VehicleModel;
        const id = a.payload.doc.id;
        return {id,  ...data };
      }))
    );
  }

  // Lugar scripts Xavier
  //____________________________________________________________//
  /** 1ª
    * By Xav :
    * @summary  pregunta si la matrícula está en la colección
    * @return  Observable de los vehiculos (con id)
  */
  getVehicleByPlate(numberPlate){
     return  this.db.collection<VehicleModel>(
      'vehicles',
      ref => ref.where('numberPlate', '==', numberPlate)
    )
    .get()
    .pipe(
      map(qs =>  {
        let res = []
        qs.forEach(doc=>{
          const id = doc.id;
          const data =  doc.data();
          res.push({id, ...data})
        })
        return res;
      })
    )

  }

  /**
   * Permite saber los modelos a filtrar 
   * @param brandIdFilters un arreglo de los filtros puestos en los chips de marca
   * @returns Observable con lo que serán filtrados
   */
  getFilteredModels(brandIdFilters: string[]){
    console.log("@@@@@@@@@____filtraje : ", typeof brandIdFilters, brandIdFilters);
    return this.db.collection<ModelModel>('models', ref => ref.where('brandId', 'in', brandIdFilters)) 
    .snapshotChanges()
    .pipe(map( actions => actions.map(a => {
      const data = a.payload.doc.data() as ModelModel;
      const id = a.payload.doc.id;
      return {id,  ...data};    
    }) ))
  }

  //____________________________________________________________//


  //Devuelve un bool, @summary responde si encuentra o no la matricula en la coleccion
  checkPlate(numberPlate){
   return this.db.collection<VehicleModel>(
      'vehicles',
      ref => ref.where('numberPlate', '==', numberPlate)
    )
    .get()
    .toPromise()
  }

  deleteVehicle(id){
    return this.db.collection('vehicles').doc(id).delete();
  }

  addVehicle(vehicle){
    vehicle.createdAt = firestore.FieldValue.serverTimestamp();
    vehicle.updatedAt = firestore.FieldValue.serverTimestamp();
    this.db.collection('vehicles').add({...vehicle});
  }

  updateVehicle(id, vehicle){
    vehicle.updatedAt = firestore.FieldValue.serverTimestamp();
    this.db.collection('vehicles').doc(id).update({...vehicle});
  }

  async query(paginator, sort, filter, options?){
    console.log("llego al query")
    let extras = {

      createdAt:{
        type:"dateRange",
      },


      numberPlate:{
        type:"wildcard"
      },
      // color:{
      //   type:"wildcard"
      // },
      
      // idModel:{
      //   type:"wildcard"
      // },
      // createdAt:{
      //   type:"wildcard"
      // },
      location:{
        nested:"location.address_components"
      },
      postal_code:{
        nested:"location.address_components"
      },
      locality:{
        nested:"location.address_components"
      },
      administrative_area_level_2:{
        nested:"location.address_components"
      },
      administrative_area_level_1:{
        nested:"location.address_components"
      },
      country:{
        nested:"location.address_components"
      },
    }
    filter = await this.genLocation(filter);
    filter = await this.normalize(filter);

    let _options:any=null;
    if (options?.body){
      _options ={
        body:true
      }
    }
    let body = this.es.mtToBody(paginator, sort, filter, extras) //convertimos los parámetros al formato de ElasticSearch
    //Se cambió el query 'user' a 'vehicles'
    return this.es.query("vehicles",body, (item)=>{
      
      // //Transformamos todos los dates a Timestamp que es como lo utilizamos
      // item.orderDate = new firestore.Timestamp(item.orderDate._seconds, item.orderDate._nanoseconds);
      // //item.project.startDate = new firestore.Timestamp(item.project.startDate._seconds, item.project.startDate._nanoseconds);
      // item.items.deliveryDate = new firestore.Timestamp(item.items.deliveryDate._seconds, item.items.deliveryDate._nanoseconds);
      item.createdAt = new firestore.Timestamp(item.createdAt._seconds, item.createdAt._nanoseconds);

      return item;
    } )
  }
  async normalize(_filter){
    console.log("_filter",_filter);
    // _filter.vehicles_numberPlate.map(np=>{
      _filter.numberPlate.map(np=>{
        np.value =   '*' +np.value.toUpperCase().replace(/[\ ]/g, '')  + '*';
        return np;
      })
      return _filter;      
  }
  async genLocation(_filter){
    let {location, ...filter} = _filter;
    let  types = ["postal_code", "locality", "administrative_area_level_2", "administrative_area_level_1", "country" ];

    for ( const _l of location ) {
      if(_l.value.id){
        const place:any = await this.geocodingService.getPlaceDetail(_l.value.id);
        // console.log("place", place);
        // console.error("3:", place.address_components);
        types.forEach(type=>{
          place.address_components.forEach(component=>{
            if (component.types.includes(type)){
              filter[type]=[{
                key: "location.address_components.types",
                op: "===",
                value:type
              },{
                key: "location.address_components.short_name",
                op: "===",
                value:component.short_name
              }
            ]
              //console.log("buscar:",{type, short_name: component.short_name })
            }
          })
        })

        // place.address_components
        // {short_name
        //  types:["postal_code", "locality", "administrative_area_level_2", "administrative_area_level_1", "country" ]
        // }
      }
    }

    return filter;
  }

}
