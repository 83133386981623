import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Validators, FormGroup, FormControl, FormArray, FormBuilder, AbstractControl  } from '@angular/forms';

import { NoticesComponent } from '../../notices.component';
import { NoticeModel } from '../../../models/notice.model';

import { NoticesService } from '../../notices.service';
import { VehiclesService } from 'src/app/vehicles/vehicles.service';
import { TranslateService } from '@ngx-translate/core';
import { A2filter } from '@a2system/angular/common'; // } from '@a2system/angular/common';;
import { Observable, from, of, merge } from 'rxjs';
import { startWith, map, switchMap } from 'rxjs/operators';
import { UserService } from 'src/app/core/user.service';
import { GeocodingService } from 'src/app/shared/geocoding.service';
import { cloneDeep } from 'lodash';
import { BrandModel } from 'src/app/models/brand.model';


@Component({
  selector: 'app-notice-form',
  templateUrl: './notice-form.component.html',
  styleUrls: ['./notice-form.component.scss']
})


export class NoticeFormComponent implements OnInit {
  isFinding= false;
  isLoading= false;
  public count = 0;
  private filterDisplay: any;
  private filterIndex: string =  "users";
  private filterQuery: any;

  noticeForm = this.fb.group({
    titleControl     :['', Validators.required],
    messageControl   :['', Validators.required],
  });

  filterForm = this.fb.group({
    location              :[''],//
    vehicles_idType       :[''],//
    vehicles_numberPlate  :[''],//
    vehicles_idBrand      :[''],//
    vehicles_idModel        :[''],//
    vehicles_year         :[''],//
    vehicles_color        :[''],//
  });

  a2filter =  new A2filter(this.filterForm);

  validationMessages = {
    titleControl : [{type: 'required', message: 'Título requerido'}],
    messageControl : [{type: 'required', message: 'Mensaje requerido'}],
  };

  constructor(
    public dialogRef: MatDialogRef<NoticeFormComponent>,
    public noticeService : NoticesService,
    private fb: FormBuilder,
    private vehiclesService: VehiclesService ,
    private userService: UserService,
    public translate: TranslateService ,
    private geocodingService: GeocodingService

  ) {
  }

  ngOnInit(): void {
    this.translate.use('es').subscribe(() => {
    
      this.a2filter.filterChange
      .subscribe(event=>{
        this.isFinding=true;
        console.log("@@@@-> Buscamos Datos Yeah !")
        const paginator={
          pageIndex:0,
          pageSize:0
        }
        const sort={}
        return this.userService.query(paginator, sort, this.a2filter.filters, {body: true})
        .then(response=>{
          this.count = response._meta.count;
          this.isFinding = false;
          console.log(response)
          this.filterQuery = response._meta.body.query;
          this.filterDisplay = this.a2filter.filters
          console.log("Filter Display", this.filterDisplay);
          return response.data;
        })
        .catch(err =>{
          console.error("###################Order data Source Fail", err)
          this.isFinding = false;
        })
      })

      setTimeout(() => {
        this.a2filter.refresh();
      }, 0);
    })
  }

  getErrorMessage(group){
    //Se devuelve el primer Error que se consigue
    const control = this.getControlName(group);
    let errorMessage = "Error";
    if (this.validationMessages[control]){
      this.validationMessages[control].some(function(errorType) {
        errorMessage = errorType.message;
        return group.hasError(errorType.type)
      });
    }

    return errorMessage;
  }
    /**
   * get Control Name
   * @param c Form Control
   */
  getControlName(c: AbstractControl): string | null {
    const formGroup = c.parent.controls;
    return Object.keys(formGroup).find(name => c === formGroup[name]) || null;
  }

  onSubmit(){
    console.log("Filter Display", this.filterDisplay);
    this.isLoading = true;
    this.noticeForm.controls.messageControl.disable({onlySelf:true, emitEvent:false})
    if (this.noticeForm.valid && this.count != 0) {
      console.log("VALID")

      let notice = <NoticeModel>{
        title: this.noticeForm.value.titleControl,
        message: this.noticeForm.value.messageControl,
        filterDisplay: cloneDeep(this.filterDisplay),
        filterIndex: cloneDeep(this.filterIndex),
        filterQuery: cloneDeep(this.filterQuery)
      }
      // console.log("UPSERT DESACTIVADO TEMPORALMENTE",notice);
      this.noticeService.upsert(notice).then( () =>{
        console.log("Guardados !")        
        this.dialogRef.close();
        this.isLoading = false;
      }).catch( err =>{
        console.log("UY, ERROR", err)
        this.noticeForm.controls.messageControl.enable({onlySelf:true, emitEvent:false})
        this.isLoading = false;
      })
    }else{
      console.log("INVALID")
    }
  }

}
