import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { from, merge, Observable, of } from 'rxjs';
import { map, startWith, switchMap } from 'rxjs/operators';
import { IncidentsService } from 'src/app/incidences/incidents.service';
import { A2filter } from '@a2system/angular/common'; // } from '@a2system/angular/common';;
import { GeocodingService } from 'src/app/shared/geocoding.service';
import { VehiclesService } from 'src/app/vehicles/vehicles.service';
import { UserService } from 'src/app/core/user.service';

import * as moment from 'moment';
import { DaterangepickerDirective } from 'ngx-daterangepicker-material';
import { Moment } from 'moment';

export interface rangeSelected{
  startDate: Moment ;//fecha inicial
  endDate: Moment;// fecha end
}

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss']
})
export class FiltersComponent implements OnInit {
  // test
  // @ViewChild(DaterangepickerDirective) picker: DaterangepickerDirective;

  @Input() a2filter: A2filter;
  @Input() filterForm: FormGroup;
  @Input() incidence: string = null;
  
  //buscar desde aqui
  filters: Array < string > = []

  //Arr________________________
  recipes = {};

  //filtered_____________________
  filtereds = {};

  //Exceptions.
  ourType: string;
  ourBrand: string;

  //- para brands
  other = {
    id: "-1",
    typeId: -1,
    name: "Otro",
    typeName: "VEHICLE_TYPE.OTHER",
    _name: "Other"
  }

  // test disabled selector
  lastDisabled = 'any';// starts in any
  disableds = [
    {id: 'any', name: 'Cualquiera'}, //como saber de este? si me lo topo lo cambio a Any?
    {id:'false', name: 'No Desactivados'},
    {id:'true', name: 'Desactivados'},
  ]

  //- Para fechas
  ranges = {
    'Hoy': [moment(), moment()],
    'Ayer': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Últimos 7 días': [moment().subtract(6, 'days'), moment()],
    'Últimos 30 días': [moment().subtract(29, 'days'), moment()],
    'Mes actual': [moment().startOf('month'), moment().endOf('month')],
    'Mes anterior': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    'Últimos 3 meses': [moment().subtract(3, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    // 'Desde el inicio': [null, null]
  };
  datePickerOptions = {
    applyLabel: 'Aceptar',
    cancelLabel: 'Cancelar',
    clearLabel: 'Borrar',
    customRangeLabel: 'Personalizado',
    format: 'DD/MM/YYYY',
    direction: 'ltr',
    monthNames: moment.localeData('es').monthsShort(), //moment.monthsShort(),
    daysOfWeek: moment.localeData('es').weekdaysMin(),
  }

  //___TEST
  testfirstDate: boolean = false;

  constructor(
    private userService: UserService,
    private vehiclesService: VehiclesService,
    private incidentsService: IncidentsService,
    public translate: TranslateService,
    private geocodingService: GeocodingService
  ) {}

  ngOnInit(): void {
    this.translate.use('es').subscribe(() => {
      this.filters = Object.keys(this.filterForm.controls)

      this.ourType = this.filters
        .find(filter => filter == 'vehicles_idType' || filter == 'idType');

      this.ourBrand = this.filters
        .find(filter => filter == 'vehicles_idBrand' || filter == 'idBrand');

      console.log("Filtros", this.filters)

      this.filters.forEach(filter => {
        //Recipes
        this.recipes[filter] = [];

        //Aqui se inicia las designadas, tambien se aprovecha de colocar lo del array
        switch (filter) {
          case 'vehicles_location':
          case 'location':
            // Localidad
            this.filtereds[filter] = this.filterForm.controls[filter].valueChanges
              .pipe(
                startWith(''),
                switchMap((value) => {
                  if (value == '') return of([]);
                  return from(this.geocodingService.getPlacePredictions(value, ['geocode'], 'ES')
                    .then((res: Array < any > ) => {
                      return res.map(pos => {
                        return {
                          id: pos.place_id,
                          name: pos.description
                        }
                      })
                    })

                  ) as Observable < any[] > ;
                })
              );
            break;
          case 'vehicles_idType':
          case 'idType':
            if (filter == this.incidence) {
              //incidente
              this.recipes[filter] = this.incidentsService.incidentTypes.map(type => {
                return {
                  id: type.id,
                  name: this.translate.instant(type.name)
                }
              });
              this.FilterChanges(filter);
            } else {
              // Tipo de Vehículo
              this.recipes[filter] = this.vehiclesService.vehicleTypes.map(type => {
                return {
                  id: type.id,
                  name: this.translate.instant(type.name)
                }
              });
              this.FilterChanges(filter);
            }
            break;
          case 'vehicles_idBrand':
          case 'idBrand':
            // Marca
            //recipes contiene un array, tendríamos que hacer push antes
            console.log(this.vehiclesService.vehicleBrands);
            this.recipes[filter].push(this.vehiclesService.vehicleBrands) // 0 = origen

            //XA: Entender por qué se añade push infinitos  sin esta condicional de abajo...
            let length = this.recipes[filter][0].length;
            if (this.recipes[filter][0][length - 1].id != -1) {
              this.recipes[filter][0].push(this.other); // Solo añade una vez,
            }

            this.recipes[filter].push(this.recipes[filter][0]) // 1 = a mover constante

            this.filtereds[filter] = merge(
                this.filterForm.controls[filter].valueChanges,
                this.a2filter.filterChange
              ).pipe(
                map((qry) => {
                  if (qry['filterName']) {
                    if (qry.filterName === this.ourType) {
                      const filterTypes = this.a2filter.filters[this.ourType];
                      //Si no hay filtros trae todo, sino filtrarlo por los tipos
                      this.recipes[filter][1] = filterTypes.length === 0 ?
                        this.recipes[filter][0] //this.origVehicleBrands
                        :
                        this.recipes[filter][0].filter(brand =>
                          filterTypes.find(type => type.value.id == brand.typeId) ||
                          brand.id == -1
                        );
                    }
                  }
                  return this.filterSelector(this.filterForm.controls[filter].value, filter);
                }))
            break;
          case 'idModel': //..
          case 'vehicles_idModel': //..
          case 'vehicles_model': //..
          case 'model':
            // Modelo
            this.filterForm.controls[filter].disable();
            this.recipes[filter] = [] //vehiclesModels

            this.filtereds[filter] = merge(
                this.filterForm.controls[filter].valueChanges,
                this.a2filter.filterChange
              ).pipe(
                map(qry => {

                  if (qry['filterName']) { // si hablamos del filtro

                    if (qry.filterName === this.ourBrand) {

                      const filterBrands = this.a2filter.filters[this.ourBrand];

                      if (filterBrands.length !== 0) {
                        //Se crea un array con los id en numerico para buscar
                        let brandIdFilters = filterBrands.map(brand => brand.value.id);

                        this.vehiclesService.getFilteredModels(brandIdFilters)
                          .subscribe(models => {

                            console.log(models);
                            this.recipes[filter] = models; //Asignamos al recipiente

                            const otherTimes = filterBrands.filter(brand => brand.value.id == -1);
                            if (otherTimes.length == filterBrands.length) {
                              this.filterForm.controls[filter].disable()
                            } else {
                              this.filterForm.controls[filter].enable()
                            }
                          });
                      } else {
                        this.a2filter.filters[filter] = []
                        this.filterForm.controls[filter].disable()
                      }
                    }
                  }

                  return this.filterSelector(this.filterForm.controls[filter].value, filter);
                })
              )
            break;
          case 'vehicles_year':
          case 'year':
            // Año
            this.filterForm.controls[filter].valueChanges.pipe(
              map(value => {
                const onlyNumber = /[0-9]/g;
                let year = (value.match(onlyNumber) || []).join('');
                //check if isn't a infinite loop
                this.filterForm.controls[filter].setValue(year, {
                  onlySelf: true,
                  emitEvent: false
                });
              })
            ).subscribe();
            break;
          case 'vehicles_color':
          case 'color':
            // Color
            this.recipes[filter] = this.vehiclesService.vehicleColors
              .map(ele => {
                // return { id:ele.id, name: ele.name ,color: ele.color }
                const name = this.translate.instant("COLOR." + ele.name);
                return {
                  id: ele.name,
                  name: name,
                }
              });

            console.log(this.filtereds, {
              [filter]: this.filterForm.controls[filter].value
            })

            this.FilterChanges(filter);
            break;
          case 'vehicles_idSender':
          case 'idSender':
            // Sender (el que lo mandó)
            /**
             * Tener los admins
             * ponerlos en el selector con sus nombres
             * poner en id el id de los admins
            */
           this.userService.getAdminUsers().subscribe( admins => {
             //Aqui seteamos los admins actuales
             let newAdmins = admins.map( admin =>{
              return {
                id: admin.id, //para buscar en ES
                name: admin.displayName // para mostrar al usuario
              }})
              this.recipes[filter] = newAdmins;
              this.FilterChanges(filter);
           })
            break;
          case 'createdAt':
          case 'vehicles_createdAt':
            // que hacer con filtereds?
            // filtereds son las cosas que fueron filtradas no?
            break;
          case 'disabled':
          case 'vehicles_disabled':
            this.recipes[filter] = this.disableds;// se le otorga el filtraje
            this.FilterChanges(filter);
          
            break;

          default:
            break;
        }
      });
      //______end of ngInit
    });
  }






  /**************************************** */
  /**************************************** */

  private filterSelector(value: any, filter: string) {

    let filterValue = '';
    if (typeof value === 'string') {
      filterValue = value.toLowerCase();
    } else {
      filterValue = value.name.toLowerCase();
    }
    console.log("Filtro : " + filter, filterValue);

    let filtereds = [];
    switch (filter) { // los casos son las excepciones a el filtrado normal
      case 'vehicles_idBrand':
      case 'idBrand':
        filtereds = this.recipes[filter][1].filter(
          element => {
            if (element.id == -1) {
              console.log("Others");
            }
            return element.name.toLowerCase().includes(filterValue) ||
              element.id == -1;
          }
        )
        break;
      case 'vehicles_color':
      case 'color':
        filtereds = this.recipes[filter].filter(
          color => {
            // const toFetch = this.translate.instant("COLOR." + color.name)
            return color.name.toLowerCase().includes(filterValue) ||
              color == this.recipes[filter][this.recipes[filter].length - 1]
          }
        )
        break;
      case 'vehicles_idType':
      case 'idType':
        filtereds = this.recipes[filter].filter(
          type => {
            // const toFetch = this.translate.instant(type.name)
            // console.log(type.name, toFetch);
            return type.name.toLowerCase().includes(filterValue) ||
              type.id == -1;
          }
        )
        break;
      default:
        filtereds = this.recipes[filter].filter(
          element => element.name.toLowerCase().includes(filterValue)
        )
        break;
    }

    return filtereds;
  }






  FilterChanges(filter) {
    switch (filter) {
      case 'value'://TODO por poner...
        break;

      default:
        this.filtereds[filter] = this.filterForm.controls[filter].valueChanges
          .pipe(
            startWith(''),
            map(value => this.filterSelector(value, filter))
          );
        break;
    }
  }















  rangeClicked(range, filter): void {
    const valueRange: rangeSelected = {
      startDate: range.dates[0],
      endDate: range.dates[1],
    }
    // this.picker.hide();
    this.datesUpdated(valueRange , filter); // actualizamos los datos
    this.patchDate(valueRange, filter);
  }


  //pone el texto (tiene que ser de tipo moment)
  private patchDate(value: rangeSelected, filter  ){
    // console.log("**** Setting Info ****");
    this.filterForm.patchValue({
      [filter]: value
    });
  }

  datesUpdated(range: rangeSelected, filter  ): void {
    //si es null nanai
    if (range.startDate == null || range.endDate == null ) {
      console.log("NULL");
      return;
    }
    //_____________
    //value se envia a filtrado
    const value = {
      fi: range.startDate.toDate(),
      fe: range.endDate.toDate()
    }
    this.filterForm.controls[filter].setValue(value);
    console.log('[datesUpdated] range is : ', range, value);
    this.a2filter.set(filter);
    this.patchDate(range, filter )
  }




  // test, can manage the any opt
  disabledUpdate(filter){

    const data = {...this.filterForm.controls[filter].value};

    console.log('[Disable Selected]: ', data, this.lastDisabled);// mostramos lo seleccionado

    if (data.id == this.lastDisabled) {
      console.log("retornando");
      this.filterForm.controls[filter].setValue(data.name);
      return
    }

    if (data.id == 'any' && this.lastDisabled != 'any') { // si el filtro es tipo any y se ha puesto uno distinto

      this.a2filter.unset(filter);//removemos el filtro (ANY)
      this.lastDisabled = 'any'; // remueve el ultimo

    } else{
      this.a2filter.set(filter);  //colocamos el filtro
      this.lastDisabled = data.id; // guardo el ultimo
    }

    this.filterForm.controls[filter].setValue(data.name);
  }
}