import { Component, OnInit, Output, Input, ViewChild, TemplateRef, ElementRef, AfterViewInit } from '@angular/core';
import { EventEmitter } from 'events';
import { FormGroup } from '@angular/forms';
// import { A2filter } from '@a2system/angular/common'; // } from '@a2system/angular/common';
import { A2filter } from '@a2system/angular/common'; // } from '@a2system/angular/common';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit{

  @ViewChild('lowerCase') lowerCase: TemplateRef<any>;

  // estos 3 inputs se podrían poner en un objeto...
  @Input() label: string; //Donde pone el titulo, tambien el placeholder
  @Input() placeholder: string;

  @Input() type: string; // Dependiendo del tipo tendrá cosas distintas.
  @Input() extras: any; // en caso de haber, aqui se ponen

  // _____________________
  @Input() filter: any;
  @Input() a2filter: any;
  @Input() form:any;//nescesario? puedo tomar cualquiera no?



  //Tipos:
  /**
   * selector: opcion que te permite ahcer busqueda escribiendo y filtrando los resultados
   * numeric: Solo numeros (extra, los rangos min max) ?
   * upperCase: todo en mayuscula
   * lowerCase: todo en minuscula
   * dateRange: Busca entre 2 rangos de fecha
   */


  constructor() { }
  ngOnInit(): void {}
}
