import { LayoutModule } from '@angular/cdk/layout';
import { registerLocaleData } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import localeEs from '@angular/common/locales/es';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule, FirestoreSettingsToken } from '@angular/fire/firestore';
import { AngularFireFunctions, AngularFireFunctionsModule, FunctionsRegionToken } from '@angular/fire/functions';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
//
// import { MatCheckboxModule } from '@angular/material/checkbox'
//
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxPermissionsModule, NgxPermissionsService } from 'ngx-permissions';
import { QuillModule } from 'ngx-quill';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ChipFilterComponent } from './components/chip-filter/chip-filter.component';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { FiltersComponent } from './components/filters/filters.component';
import { DashboardComponent } from './home/dashboard/dashboard.component';
import { HomeComponent } from './home/home.component';
import { IncidenceDetailComponent } from './incidences/incidences-table/incidence-detail/incidence-detail.component';
import { IncidencesTableComponent } from './incidences/incidences-table/incidences-table.component';
import { IncidencesComponent } from './incidences/incidences.component';
import { IncidentsService } from './incidences/incidents.service';
import { LoginComponent } from './login/login.component';
import { LogsTableComponent } from './logs/logs-table/logs-table.component';
import { LogsComponent } from './logs/logs.component';
import { NoticeFormComponent } from './notices/notice-table/notice-form/notice-form.component';
import { NoticeTableComponent } from './notices/notice-table/notice-table.component';
import { NoticesComponent } from './notices/notices.component';
import { PlatesComponent } from './plates/plates.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';

// import { MAT_DATE_LOCALE } from '@angular/material';//revisar, por que es core?
import { MatPaginatorIntlCustom } from './shared/custom-paginator';
import { UserFormComponent } from './users-admin/user-form/user-form.component';
import { UsersAdminComponent } from './users-admin/users-admin.component';
import { UsersTableComponent } from './users/users-table/users-table.component';
import { UsersComponent } from './users/users.component';
import { VehiclesTableComponent } from './vehicles/vehicles-table/vehicles-table.component';
import { VehiclesComponent } from './vehicles/vehicles.component';
import { VehiclesService } from './vehicles/vehicles.service';
import { PipeModule } from './shared/pipe/pipe.module';
import { AngularFireAuth } from '@angular/fire/auth';
registerLocaleData(localeEs, 'es-ES');


import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { GoogleChartsModule } from 'angular-google-charts';
import { KpiCardComponent } from './home/dashboard/kpi-card/kpi-card.component';
import { ElasticSearchService } from '@a2system/angular/common'; // } from '@a2system/angular/common';
import { FilterComponent } from './components/filters/filter/filter.component';
import { PlatesResultComponent } from './plates/plates-result/plates-result.component';
import { InformAdminComponent } from './plates/inform-admin/inform-admin.component';
import { PlateSearchComponent } from './plates/plate-search/plate-search.component';
import { first } from 'rxjs/operators';



export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
export function appInit(vehiclesService: VehiclesService,incidentService: IncidentsService) {
  incidentService.loadData();
  return () => vehiclesService.loadData();
}

export function userInit(permissionsService: NgxPermissionsService){
  // let user = await afAuth.authState.pipe(first()).toPromise();
  // console.log("User", user);
  // let perm = ['admin'];
  // const datos = await user.getIdTokenResult();
  // let role = null;
  // if(datos && datos.claims.role){
  //   role= datos.claims.role;
  //   perm = [role];
  //   permissionsService.loadPermissions(perm);
  // }else{
  //   role=null;
  //   perm = [role];
  //   permissionsService.loadPermissions(perm);
  // }
  return true;
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    UsersComponent,
    VehiclesComponent,
    IncidencesComponent,
    PlatesComponent,
    PlateSearchComponent,
    PlatesResultComponent,
    InformAdminComponent,
    DashboardComponent,
    UsersTableComponent,
    VehiclesTableComponent,
    IncidencesTableComponent,
    ChipFilterComponent,
    ConfirmationDialogComponent,
    LoginComponent,
    NoticesComponent,
    NoticeTableComponent,
    NoticeFormComponent,
    UsersAdminComponent,
    UserFormComponent,
    IncidenceDetailComponent,
    LogsComponent,
    LogsTableComponent,
    FiltersComponent,
    FilterComponent,
    KpiCardComponent,
    ChangePasswordComponent,
    ForgotPasswordComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      },
    }),
    QuillModule.forRoot(),
    NgxDaterangepickerMd.forRoot(),
    GoogleChartsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireStorageModule,
    AngularFireFunctionsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    LayoutModule,
    FormsModule,
    ReactiveFormsModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatMenuModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    FlexLayoutModule,
    MatGridListModule,
    MatCardModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatChipsModule,
    MatProgressSpinnerModule,
    MatAutocompleteModule,
    NgxPermissionsModule.forRoot(),
    MatDialogModule,
    MatSnackBarModule,
    MatSelectModule,
    PipeModule
  ],
  providers: [
    //____
    { provide: MatPaginatorIntl, useClass: MatPaginatorIntlCustom},
    // { provide: MAT_DATE_LOCALE , useValue: 'es-ES' },
    // { provide: LOCALE_ID , useValue: "es-ES"},
    //____

    { provide: FirestoreSettingsToken, useValue: {} },
    // { provide: ORIGIN, useValue: 'http://124c45f0.ngrok.io' },
    { provide: FunctionsRegionToken, useValue: 'europe-west1' },
    AngularFireFunctions,
    VehiclesService,
    {
      provide: APP_INITIALIZER,
      useFactory:
        appInit,
      multi: true,
      deps: [
        VehiclesService,
        IncidentsService
      ]
    },
    // AngularFireAuth,
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: (permissionsService: NgxPermissionsService, afAuth: AngularFireAuth ) => async () => {

    //   },
    //   multi: true,
    //   deps: [
    //     AngularFireAuth,
    //     NgxPermissionsService
    //   ]
    // },
    IncidentsService,
    ElasticSearchService,
    {
      provide: APP_INITIALIZER,
      useFactory: (es: ElasticSearchService) => () => {
        es.setEsConfig(environment)
      },
      multi: true,
      deps: [ElasticSearchService]
    }
  ],
  // exports: [
  //   PipeModule
  // ],
  // exports:[
  //   // TranslateModule// en teoria
  //   //conectar con component modules no?
  // ],
  bootstrap: [AppComponent]
})
export class AppModule { }
