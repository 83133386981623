import { Injectable} from '@angular/core';
//cdk
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { MatSpinner } from '@angular/material/progress-spinner';
import { Observable, Subject} from 'rxjs'
import { mapTo, scan, map, distinctUntilChanged, take } from 'rxjs/operators';
//import { MatSnackBar } from '@angular/material/snack-bar';
import { AbstractControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
// import { ConfirmDialogComponent } from 'app/components/confirm-dialog/confirm-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class UiService {
  private spinnerTopRef: OverlayRef;
  private spin$: Subject < number > = new Subject();
  public spinner;

  constructor(
    private overlay: Overlay,
    private snackBar: MatSnackBar,
    // private dialog: MatDialog,
  ){
    this.spinnerTopRef = this.overlay.create({
      hasBackdrop: true,
      positionStrategy: this.overlay.position()
        .global()
        .centerHorizontally()
        .centerVertically()
    });

    this.spin$
      .asObservable()
      .pipe(
        scan((acc, next) => {
          if (!next) return 0;
          return (acc + next) >= 0 ? acc + next : 0;
        }, 0),
        map(val => val > 0),
        distinctUntilChanged()
      )
      .subscribe(
        (res) => {
          if (res) {
            this.spinnerTopRef.attach(new ComponentPortal(MatSpinner))
          } else if (this.spinnerTopRef.hasAttached()) {
            this.spinnerTopRef.detach();
          }
        }
      )
    this.spinner={
      service:this,
      show() {this.service.spin$.next(1);},
      reset() {this.service.spin$.next(0);},
      hide() {this.service.spin$.next(-1);}
    }
  }

  toast(message, duration=1000){
    this.snackBar.open(message, null, {
      duration
    });
  }


  getErrorMessage(group, validationMessages){
    // Se devuelve el primer Error que se consigue
    const control = this.getControlName(group);
    let errorMessage = 'Error';
    if (validationMessages[control]){
      validationMessages[control].some((errorType) =>{
        errorMessage = errorType.message;
        return group.hasError(errorType.type)
      });
    }

    return errorMessage;
  }
    /**
   * get Control Name
   * @param c Form Control
   */
  getControlName(c: AbstractControl): string | null {
    const formGroup = c.parent.controls;
    return Object.keys(formGroup).find(name => c === formGroup[name]) || null;
  }

  // confirmDialog(data?): Observable <any>{
  //   const dialogRef = this.dialog.open(ConfirmDialogComponent, {
  //     minWidth: "300px",
  //     maxWidth: "400px",
  //     maxHeight: '80%',
  //     data: data,
  //     disableClose: true
  //   });

  //   return dialogRef.afterClosed().pipe(take(1), map(res => {
  //     return res;
  //   }));
  // }

}
