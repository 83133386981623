import { Component, OnInit, Input, ViewChild, OnDestroy, AfterViewInit } from '@angular/core';
import { A2filter } from '@a2system/angular/common'; // } from '@a2system/angular/common';;
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { LogsTableDataSource } from './logs-table-datasource';
import { LogModel } from 'src/app/models/log.model';
import { UsersTableDataSource } from 'src/app/users/users-table/users-table-datasource';
import { UserService } from 'src/app/core/user.service';
import { UserModel } from 'src/app/models/user.model';
import { PlateService } from 'src/app/plates/plate.service';
import { takeWhile } from 'rxjs/operators';
import { ReportModel } from 'src/app/models/report.model';
import { ReportService } from 'src/app/shared/report.service';
import { merge } from 'rxjs';

@Component({
  selector: 'app-logs-table',
  templateUrl: './logs-table.component.html',
  styleUrls: ['./logs-table.component.scss']
})
export class LogsTableComponent implements AfterViewInit, OnInit, OnDestroy {
  @Input() a2filter: A2filter;
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  @ViewChild(MatTable, {static: false}) table: MatTable<LogModel>;
  dataSource: LogsTableDataSource;
  displayedColumns: string[] = ['email','displayName','names','numberPlate','found','createdAt'];

  isLoading: boolean = false;
  url: string = null;
  private filterIndex: string =  'searchLogs';

  constructor(
    private  dataService: PlateService,
    private reportService: ReportService,

  ) 
  {

  }

  ngOnInit(){
    this.dataSource = new LogsTableDataSource(this.dataService);

  
  }

  ngAfterViewInit() {
    console.log(this.sort.sortables);
    this.dataSource.a2filter = this.a2filter;
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.table.dataSource = this.dataSource;
    

    merge( 
      this.a2filter.filterChange,
      this.sort.sortChange  
    ).subscribe( () => {
      console.log( 'reset');
      this.url = null;
    })
    setTimeout(() => {
      this.refresh();
    }, 0);
  }
  ngOnDestroy(){
    // this.agSubscription.unsubscribe();
  }

  refresh(){
    console.log("Refresh pez");
    this.a2filter.refresh();
  }

  //Trae la información de la dirección pero con los datos que queremos
  getAddressComponent(components = [], type, long = true) {
    let resp = ' '; // may be undefined

    //Buscaremos si el tipo esta presente en el componente
    // let c = components.find( e => e.types.includes( type ) );
    let c = components.find( e => e.types.includes( type ) );

    if ( c ) {
      resp = long ? c.long_name : c.short_name;

    }
    return resp;
  } 

  report(){
    //si ya esta lo descarga
    if (this.url != null) {
      window.open(this.url)
      return;
    }
    this.isLoading = true
    const paginator={
      pageIndex:0,
      pageSize:this.dataSource.count
    }
    const query = {
      paginator: paginator,
      sort: this.sort,
      filter: this.a2filter.filters,
    }
    this.reportService.startReport(this.filterIndex, query)
    .then(obs => {
      obs.pipe( takeWhile( ()=> this.isLoading))
        .subscribe( report => {
          console.log('Reporting......');
          if (report.status == ReportModel.Status.SUCCESS) {
            console.log(report);
            this.url = report.downloadUrl;
            this.isLoading = false
          }
          if (report.status == ReportModel.Status.ERROR) {
            console.log("Error: ",report);
            this.url = null;
            this.isLoading = false
          }
      })
    }).catch( error => {
      console.log(error)
      console.error(error)
      this.url = null;
      this.isLoading = false
    })
    
  }
}
