<mat-sidenav-container>
  <mat-sidenav class="sidenav" attr.role='navigation' mode='side' opened disableClose>
    <mat-toolbar>Filtros</mat-toolbar>
    <div fxLayout="column" fxLayoutAlign="start center" class="search-form">
      <!-- Filtros de Logs -->
      <app-filters [filterForm]="filterForm" [a2filter]="a2filter"></app-filters>
    </div>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-card>
      <!-- Tabla de Logs -->
      <app-logs-table [a2filter]="a2filter"></app-logs-table>
    </mat-card>
  </mat-sidenav-content>
</mat-sidenav-container>
