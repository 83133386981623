import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Inject } from '@angular/core';
import {  FormGroup, FormControl, Validators } from '@angular/forms';
import { UserService } from 'src/app/core/user.service';
import { MatSnackBar } from '@angular/material/snack-bar';


@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss']
})
export class UserFormComponent implements OnInit {
  userForm = new FormGroup({
    id: new FormControl(''),
    firstName: new FormControl(''),
    lastName : new FormControl(''),
    email    : new FormControl({value:'', disabled: this.data.user.id} , [
      Validators.required,
      Validators.email,
    ]),
    displayName : new FormControl(''),
    role  : new FormControl(''),
    password : new FormControl({value:'', disabled: this.data.user.id}),
    uid:new FormControl('')
  })

  isLoading: boolean = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private userService: UserService,
    public dialogRef: MatDialogRef<UserFormComponent>,
    private snackBar: MatSnackBar,

  ) {
    // dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    console.log("This user has come:  ");
    console.log(this.data.user);
    if (!this.data.user.id){
      this.data.user.password = '##4v1s4c4r##';
      this.data.user.email = '';
      this.data.user.role = 'p3';
    } else {
      this.data.user.password = '';
    }
    this.userForm.patchValue(this.data.user);
  }

  onSubmit() {

    if (this.userForm.valid) {

      this.userForm.disable();
      this.isLoading = true;
      let _user = this.userForm.value;
      let user = {
        uid:_user.uid,
        // password:_user.password,
        role:_user.role,
        user:{
          email:_user.email,
          firstName:_user.firstName,
          lastName:_user.lastName,
          displayName:_user.displayName,
        },
        password : _user.password

      }
      let upsertPromise:Promise<any>
      if (this.data.user.id){
        delete user.user.email;
        delete user.password;
        upsertPromise = this.userService.updateAdminUser(user);
      }else{
        delete user.uid;
        upsertPromise = this.userService.createAdminUser(user);
      }


      upsertPromise
      .then(_ =>{
        const snackBarRef = this.snackBar.open('Usuario actualizado correctamente', null, {
          duration: 1000
        });
        this.dialogRef.close();
      })
      .catch(err =>{
        alert(err);
      })


      // let upsertPromise:Promise<any> = this.data.user.id
      //   ? this.userService.setUser(user)
      //   : this.userService.doSignup(user);
      // console.log("id", this.data.user.id);
      // console.log("user: ", user);
      // console.log("-----------");
      // upsertPromise
      // .then(_ =>{
      //   const snackBarRef = this.snackBar.open('User saved correctly', null, {
      //     duration: 1000
      //   });
      //   this.dialogRef.close();
      // })
      // .catch(err =>{
      //   alert(err);
      // })
    }
  }
}
