<h1 mat-dialog-title>Cambiar Contraseña</h1>
<div mat-dialog-content class="mat-typography" style="width:300px;">
  <form [formGroup]="matching_passwords_group" id="chgPassword" (ngSubmit)="chgPassword()" fxLayout="column">
    <mat-form-field>
      <mat-label>Nueva contra&#8203;seña</mat-label>
      <input appAutoFocus matInput placeholder="Nueva contraseña" type="password"  formControlName="password" >
      <mat-error *ngIf="matching_passwords_group.get('password').invalid "><mat-icon>info</mat-icon> {{getErrorMessage(matching_passwords_group.get('password'))}}</mat-error>
    </mat-form-field>
    <br>
    <mat-form-field >
      <mat-label>Confirme contra&#8203;seña</mat-label>
      <input matInput type="password" placeholder="Confirme su contraseña" formControlName="confirm_password" >
      <mat-error *ngIf="matching_passwords_group.get('confirm_password').invalid"><mat-icon>info</mat-icon>{{getErrorMessage(matching_passwords_group.get('confirm_password'))}}</mat-error>
      <!-- <mat-error *ngIf="matching_passwords_group.get('confirm_password').invalid || matching_passwords_group.invalid">
        <mat-icon>info</mat-icon>
        {{ matching_passwords_group.get('confirm_password').invalid ? getErrorMessage(matching_passwords_group.get('confirm_password')):"Password mismatch"}}
      </mat-error> -->


      <!-- validationMessages['matching_passwords'] -->
    </mat-form-field>
  </form>
  <span *ngIf="matching_passwords_group.get('password').valid && matching_passwords_group.get('confirm_password').valid && matching_passwords_group.hasError('areNotEqual')" class="error">
    <mat-icon>info</mat-icon> Las contraseñas no coinciden
  </span>
</div>
<div mat-dialog-actions align="end">
  <button mat-stroked-button color="warn" mat-dialog-close>Cancelar</button>
  <button mat-stroked-button color="primary" [disabled]="!this.matching_passwords_group.valid" type="submit" form="chgPassword">Cambiar</button>
</div>