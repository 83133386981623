import {Input, Output, EventEmitter } from '@angular/core';
import { DataSource } from '@angular/cdk/collections';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { switchMap } from 'rxjs/operators';
import { Observable, of as observableOf, merge, BehaviorSubject } from 'rxjs';
import { A2filter } from '@a2system/angular/common'; // } from '@a2system/angular/common';;

import { IncidentsService } from '../incidents.service';
import { Incidence } from 'src/app/models/incidence.model';

/**
 * Data source for the UsersTable view. This class should
 * encapsulate all logic for fetching and manipulating the displayed data
 * (including sorting, pagination, and filtering).
 */
export class IncidencesTableDataSource extends DataSource<Incidence> {

  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();

  public paginator: MatPaginator;
  public sort: MatSort;

  public count = 0;

  public a2filter: A2filter;


  constructor(
    public dataService: IncidentsService,
  ) {
    super();
  }

  connect(): Observable <Incidence[]> {

    const dataMutations = [
      this.paginator.page,
      this.sort.sortChange,
      this.a2filter.filterChange,
    ];
    console.log(dataMutations,this.paginator.page,this.sort.sortChange,this.a2filter.filterChange);
    console.log("conectado");

    return merge(...dataMutations).pipe(switchMap((qry) =>{
      this.loadingSubject.next(true);
      console.log(qry);

      if(qry['filterName'])this.paginator.firstPage();
        // console.log("entró");
        
        console.log("pide Datos");
        return this.dataService.query(this.paginator, this.sort, this.a2filter.filters)
        .then(response=>{
          this.count = response._meta.count;
          this.loadingSubject.next(false);
          return response.data;
        })
        .catch(err =>{
          this.loadingSubject.next(false);
          console.error("Order data Source Fail", err)
        })
    }));
   
  }

  /**
   *  Called when the table is being destroyed. Use this function, to clean up
   * any open connections or free any held resources that were set up during connect.
   */
  disconnect() {
    this.loadingSubject.complete();
  }

}
